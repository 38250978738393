import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {useStyles} from "./styles";
import {checkValueNotEmptyString} from "utils/helperFunctions/helpers";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import PropTypes from "prop-types";
import ChipComponent from "commonComponent/ChipComponent";
import ActionButton from "commonComponent/Apptable/ActionButton";

const actionButtonArray = ["Edit", "Delete"];
const AddressCard = (props) => {
    const {data, handleActionBtn} = props;
    const styles = useStyles();
    const getActionArray = (isDefault) => {
        if (isDefault) {
            return actionButtonArray.filter((item) => item !== "Set as default");
        } else {
            return actionButtonArray;
        }
    };
    return (
        <Grid item xs={12} md={5.8} lg={3.8} sx={[styles?.wrapper, styles?.flexer, styles?.columnView, styles?.flexGap]}>
            <Grid item xs={12} sx={[styles?.flexer, styles.flexBetween, styles?.nameContainer]}>
                <Typography sx={[styles?.addressName]}>
                    {ellipsizeText(data?.location_name === "" ? data?.address_type : data?.location_name, 23)}
                </Typography>
                <Box sx={[styles?.flexer, styles?.flexGap]}>
                    {data?.is_default && <ChipComponent title="Default" />}
                    <ActionButton row={data} ActionArray={getActionArray(data?.is_default)} handleActionBtn={handleActionBtn} />
                </Box>
            </Grid>
            <Grid item xs={12} sx={[styles?.titleContainer]}>
                <Typography sx={[styles?.detailTitle]}>ADDRESS</Typography>
                <Typography sx={[styles?.detail]}>{data?.delivery_address && ellipsizeText(data?.delivery_address, 55)}</Typography>
            </Grid>
            <Grid item xs={12} sx={[styles?.flexer, styles.flexBetween, styles.aptContainer]}>
                <Box sx={[styles?.lineBreaker]}>
                    <Typography sx={[styles?.detailTitle]}>APT/SUITE</Typography>
                    <Typography sx={[styles?.detail]}>{checkValueNotEmptyString(data?.apartment_name)}</Typography>
                </Box>
                <Box sx={[styles?.lineBreaker]}>
                    <Typography sx={[styles?.detailTitle]}>CONTACT</Typography>
                    <Typography sx={[styles?.detail]}>{checkValueNotEmptyString(data?.contact_name)}</Typography>
                </Box>
                <Box sx={[styles?.lineBreaker]}>
                    <Typography sx={[styles?.detailTitle, styles?.paddingAdjust]}>CONTACT PHONE</Typography>
                    <Typography sx={[styles?.detail]}>{checkValueNotEmptyString(data?.contact)}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sx={[styles?.titleContainer, styles?.lineBreaker, styles?.instuctionPadding]}>
                <Typography sx={[styles?.detailTitle]}>DELIVERY INSTRUCTIONS</Typography>
                <Typography sx={[styles?.detail]}>{checkValueNotEmptyString(data?.instruction)}</Typography>
            </Grid>
        </Grid>
    );
};

export default AddressCard;

AddressCard.propTypes = {
    data: PropTypes.object,
    handleActionBtn: PropTypes.func
};
