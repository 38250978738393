import React, {useEffect} from "react";
import SendQuoteForm from "./SendQuoteForm";

const SendQuote = (props) => {
    const {
        handleIsEdited,
        setSelectedCheckbox,
        selectedRow,
        formik,
        selectedCheckbox = null,
        handleSelectedRow = () => {},
        customerId,
        userId,
        orderId,
        buttonTitle,
        handleClose = {},
        brandName,
        brandId,
        showMultipleQuote = false,
        handleCloseMultipleQuote = () => {},
        setExpiryDateTime,
        expiryDateTime,
        expiryQuote,
        handleEditExpirationTime,
        singleQuoteData,
        setSingleQuoteData,
        initialValues = {},
        handleReset = () => {},
        title
    } = props;

    useEffect(() => {
        if (formik?.values) setSingleQuoteData([formik.values]);
    }, [formik?.values]);

    return (
        <SendQuoteForm
            singleQuoteData={singleQuoteData}
            selectedCheckbox={selectedCheckbox}
            handleCloseMultipleQuote={handleCloseMultipleQuote}
            setSelectedCheckbox={setSelectedCheckbox}
            showMultipleQuote={showMultipleQuote}
            handleClose={handleClose}
            formik={formik}
            brandName={brandName}
            userId={userId}
            brandId={brandId}
            buttonTitle={buttonTitle}
            customerId={customerId}
            orderId={orderId}
            handleIsEdited={handleIsEdited}
            selectedRow={selectedRow}
            handleSelectedRow={handleSelectedRow}
            setExpiryDateTime={setExpiryDateTime}
            expiryDateTime={expiryDateTime}
            expiryQuote={expiryQuote}
            handleEditExpirationTime={handleEditExpirationTime}
            initialValues={initialValues}
            handleReset={handleReset}
            title={title}
        />
    );
};

export default SendQuote;
