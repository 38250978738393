import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    currentPage: 1,
    order: "",
    orderBy: "",
    rowsPerPage: 25,
    filterChips: {},
    showFilter: false,
    childCurrentPage: 1,
    childOrder: "",
    childOrderBy: "",
    childRowsPerPage: 25,
    childFilterChips: {},
    childShowFilter: false,
    nestCurrentPage: 1,
    nestRowsPerPage: 25,
    nestFilterChips: {},
    nestShowFilter: false,
    serviceTypeFilter : "Catering"
};

// ==============================|| SLICE - PAGINATION ||============================== //

const pagination = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setPageNumber(state, action) {
            state.currentPage = action.payload;
        },
        setOrder(state, action) {
            state.order = action.payload;
        },
        setOrderBy(state, action) {
            state.orderBy = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        setFilterChips(state, action) {
            state.filterChips = action.payload;
        },
        setShowFilter(state, action) {
            state.showFilter = action.payload;
        },
        setChildPageNumber(state, action) {
            state.childCurrentPage = action.payload;
        },
        setChildOrder(state, action) {
            state.childOrder = action.payload;
        },
        setChildOrderBy(state, action) {
            state.childOrderBy = action.payload;
        },
        setChildRowsPerPage(state, action) {
            state.childRowsPerPage = action.payload;
        },
        setChildFilterChips(state, action) {
            state.childFilterChips = action.payload;
        },
        setChildShowFilter(state, action) {
            state.childShowFilter = action.payload;
        },
        setNestPageNumber(state, action) {
            state.nestCurrentPage = action.payload;
        },
        setNestRowsPerPage(state, action) {
            state.nestRowsPerPage = action.payload;
        },
        setNestFilterChips(state, action) {
            state.nestFilterChips = action.payload;
        },
        setNestShowFilter(state, action) {
            state.nestShowFilter = action.payload;
        },
        setServiceTypeFilter(state, action) {
            state.serviceTypeFilter = action.payload;
        },
        resetState(state) {
            state.currentPage = 1;
            state.rowsPerPage = 25;
            state.order = "";
            state.orderBy = "";
            state.filterChips = {};
            state.showFilter = false;
            state.childCurrentPage = 1;
            state.childOrder = "";
            state.childOrderBy = "";
            state.childRowsPerPage = 25;
            state.childFilterChips = {};
            state.childShowFilter = false;
            state.nestCurrentPage = 1;
            state.nestRowsPerPage = 25;
            state.nestFilterChips = {};
            state.nestShowFilter = false;
            state.serviceTypeFilter = "Catering"
        },
        resetChildState(state) {
            state.childCurrentPage = 1;
            state.childOrder = "";
            state.childOrderBy = "";
            state.childRowsPerPage = 25;
            state.childFilterChips = {};
            state.childShowFilter = false;
        },
        resetNestState(state) {
            state.nestCurrentPage = 1;
            state.nestRowsPerPage = 25;
            state.nestFilterChips = {};
            state.nestShowFilter = false;
        }
    }
});

export default pagination.reducer;

export const {
    setPageNumber,
    setOrder,
    setOrderBy,
    setRowsPerPage,
    setFilterChips,
    setShowFilter,
    resetState,
    setChildPageNumber,
    setChildOrder,
    setChildOrderBy,
    setChildRowsPerPage,
    setChildFilterChips,
    setChildShowFilter,
    resetChildState,
    setNestPageNumber,
    setNestRowsPerPage,
    setNestFilterChips,
    setNestShowFilter,
    resetNestState,
    setServiceTypeFilter
} = pagination.actions;
