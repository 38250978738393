import {useTheme} from "@mui/material";

export const useStyles = () => {
    const theme = useTheme();
    return {
        chip: {
            borderRadius: "40px",
            width: "61px",
            height: "29px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        defaultBg: {
            background: theme.palette.orange.faded
        },
        defaultText: {
            color: theme.palette.orange.default
        },
        textMain: {
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16.8px"
        }
    };
};
