import React, {useEffect, useState, useContext, useCallback} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
    CircularProgress,
    Paper,
    TableContainer,
    LinearProgress,
    Checkbox,
    useMediaQuery,
    useTheme,
    styled,
    Alert,
    Button,
    Dialog,
    DialogActions,
    IconButton,
    DialogContent,
    Modal,
    ImageListItem,
    ImageList,
    InputAdornment,
    Typography,
    Grid
} from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import {useStyles} from "./AppTableStyles";
import {Box, height} from "@mui/system";
import {capitalizeFirstLetter} from "./textUtils";
import ConfrimDialog from "./Confirm";
import {useAppTableController} from "./apptable.controller";
import {getFields} from "./getFields";
import getOrderFields from "./getFieldsForDownload";
import ActionButton from "./ActionButton";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import CancelIcon from "assets/images/icons/cancel1.svg";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import ReviewModal from "commonComponent/DilogBox/ReviewModal";
import {useLocation} from "react-router-dom";
import ApptableCards from "./AppTableCards/ApptableCards";
import {FilterContext} from "contexts/FilterContext";
import Cancel from "../../assets/images/icons/cancel.svg";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {validateInputForPercentage} from "utils/helperFunctions/helpers";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";

const StyledTableRow = styled(TableRow)(({theme, multipleQuote, singleQuote}) => ({
    ...(multipleQuote && {border: "2px solid #000 !important"}),
    ...(singleQuote && {border: "2px solid #000 !important"}),

    borderBottom: "2px solid #000 !important",
    marginBottom: "2px !important"
    // "&:nth-of-type(odd)": {
    //     backgroundColor: "green"
    // },
}));
function AppTables(props) {
    const {
        headers,
        ActionArray,
        handleActionBtn,
        id,
        searchParam,
        path,
        sortingLabel = [],
        loading = false,
        withCheckbox = false,
        notCaptilize,
        handleHeaderActions,
        multipleQuote,
        headerShow,
        leftSpace,
        currentTab,
        tableCellWidth,
        tab,
        handleDateChange = () => {},
        isReport = false,
        singleQuote,
        handleMutiQuoteTogglePercentage = () => {},
        handleMultiQuoteToggleState = () => {}
    } = props;
    const theme = useTheme();
    const atc = useAppTableController(props);
    const [reviewOptionModal, setReviewOptionModal] = useState(false);
    const [activeCard, setActiveCard] = useState(null);
    const {openFilter} = useContext(FilterContext);
    const dispatch = useDispatch();
    const styles = useStyles();
    const location = useLocation();
    const currentPath = location.pathname;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const params = useLocation();

    const isQuotes = params?.search === "?tab=quotes";
    const isSchedule = params?.search === "?tab=scheduled";
    const handlePrintDownload = (row) => {
        navigator.clipboard.writeText(`Rating: ${row?.rating}\n\nReview: ${row?.review}\n\nAuthor: ${row?.Customer}`);
        dispatch(
            openSnackbar({
                open: true,
                message: "Review copied to clipboard",
                variant: "alert",
                alert: {
                    color: "success"
                },
                close: false
            })
        );
    };

    const reviewStatus = (type) => {
        switch (type) {
            case "NEW": {
                return [
                    WithPermission({module: "Reviews", permission: ["update_permission"]}) && "Publish",

                    WithPermission({module: "Reviews", permission: ["update_permission"]}) && "Unpublish"
                ];
            }
            case "UNPUBLISHED": {
                return [WithPermission({module: "Reviews", permission: ["update_permission"]}) && "Publish"];
            }
            case "PUBLISHED": {
                return [WithPermission({module: "Reviews", permission: ["update_permission"]}) && "Unpublish"];
            }
            default: {
                return [];
            }
        }
    };

    const reportStatus = (type) => {
        switch (type) {
            default: {
                return ["View orders", "Download"];
            }
        }
    };

    const statusChange = (row) => {
        if (row?.kitchen_location_id) {
            return WithPermission({module: "Locations", permission: ["update_permission"]})
                ? row.is_active
                    ? "Deactivate"
                    : "Activate"
                : null;
        }
        if (row?.market_id) {
            return WithPermission({module: "Markets", permission: ["update_permission"]})
                ? row.is_active
                    ? "Deactivate"
                    : "Activate"
                : null;
        }
        if (row?.menu_id) {
            return WithPermission({module: "Menu", permission: ["update_permission"]}) ? (row.is_active ? "Deactivate" : "Activate") : null;
        }
        if (row?.brand_id) {
            return WithPermission({module: "Brands", permission: ["update_permission"]})
                ? row.is_active
                    ? "Deactivate"
                    : "Activate"
                : null;
        }
        if (row?.role_id) {
            return WithPermission({module: "Roles", permission: ["update_permission"]})
                ? row.is_active
                    ? "Deactivate"
                    : "Activate"
                : null;
        }
        if (row?.discount_id) {
            return WithPermission({module: "Promotions", permission: ["update_permission"]})
                ? row.is_active
                    ? "Deactivate"
                    : "Activate"
                : null;
        }

        return row.is_active ? "Deactivate" : "Activate";
    };
    const customerStatus = (type) => {
        let userStatus = "";
        if (type) {
            userStatus = "active";
        } else {
            userStatus = "Pending";
        }
        switch (userStatus) {
            case "Pending": {
                return [
                    WithPermission({module: "Customers", permission: ["update_permission"]}) && "Edit",
                    WithPermission({module: "Customers", permission: ["update_permission"]}) && "Resend invite",
                    WithPermission({module: "Customers", permission: ["delete_permission"]}) && "Delete"
                ];
            }
            case "active": {
                return [
                    WithPermission({module: "Customers", permission: ["update_permission"]}) && "Edit",
                    WithPermission({module: "Customers", permission: ["delete_permission"]}) && "Delete"
                ];
            }
            default: {
                return [];
            }
        }
    };
    const orderStatus = (type, row) => {
        switch (type) {
            case "Draft": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Edit",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["delete_permission"]}) && "Delete"
                ];
            }
            case "Quote sent": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Edit",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Resend quote",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Reject quote",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancel order",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Edit quote expiration time"
                ];
            }
            case "Quote Expired": {
                return ["Print", "Download", WithPermission({module: "Orders", permission: ["delete_permission"]}) && "Delete"];
            }
            case "QUOTE_REJECTED": {
                return ["Print", "Download", WithPermission({module: "Orders", permission: ["delete_permission"]}) && "Delete"];
            }
            case "New": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Accept",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Reject",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    row.charged_by !== "CREDIT_CARD" &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        (row.remaining_amount_to_be_paid === 0 ? "Payment detail" : "Mark as paid")
                ];
            }
            case "Accepted": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        new Date(row.delivery_date) > new Date() &&
                        "Edit",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Assign driver",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancel order",
                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    row.charged_by !== "CREDIT_CARD" &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        (row.remaining_amount_to_be_paid === 0 ? "Payment detail" : "Mark as paid")
                ];
            }
            case "Active": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        new Date(row.delivery_date) > new Date() &&
                        "Edit",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Assign driver",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancel order",
                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    row.charged_by !== "CREDIT_CARD" &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        (row.remaining_amount_to_be_paid === 0 ? "Payment detail" : "Mark as paid")
                ];
            }
            case "ON_THE_WAY": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        new Date(row.delivery_date) > new Date() &&
                        "Edit",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Assign driver",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancel order",
                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    row.charged_by !== "CREDIT_CARD" &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        (row.remaining_amount_to_be_paid === 0 ? "Payment detail" : "Mark as paid")
                ];
            }
            case "DELIVERED": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Edit",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    // WithPermission({module: "Orders", permission: ["update_permission"]}) && "Assign driver",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancel order",
                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    row.charged_by !== "CREDIT_CARD" &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        (row.remaining_amount_to_be_paid === 0 ? "Payment detail" : "Mark as paid")
                ];
            }
            case "Driver assigned": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        new Date(row.delivery_date) > new Date() &&
                        "Edit",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Assign driver",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancel order",
                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    row.charged_by !== "CREDIT_CARD" &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        (row.remaining_amount_to_be_paid === 0 ? "Payment detail" : "Mark as paid")
                ];
            }

            case "Needs driver": {
                return [
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Assign driver",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        new Date(row.delivery_date) > new Date() &&
                        "Edit",
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancel order",
                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    row.charged_by !== "CREDIT_CARD" &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        (row.remaining_amount_to_be_paid === 0 ? "Payment detail" : "Mark as paid")
                ];
            }
            case "Complete": {
                return [
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Send receipt",
                    !row.is_partial_refunded &&
                        !row.is_full_refunded &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        "Issue refund",
                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    row.charged_by === "HOUSE_ACCOUNT" &&
                        WithPermission({module: "Orders", permission: ["update_permission"]}) &&
                        (row.remaining_amount_to_be_paid === 0 ? "Payment detail" : "Mark as paid")
                ];
            }
            case "Cancelled": {
                return [
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Send receipt",

                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    !row.charge_fee_amount && WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancelation fee"
                ];
            }

            case "Rejected": {
                return [
                    "Print",
                    "Download",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Add note",
                    WithPermission({module: "Orders", permission: ["update_permission"]}) && "Send receipt",
                    // WithPermission({module: "Orders", permission: ["update_permission"]}) && "Issue refund",
                    WithPermission({module: "Orders", permission: ["create_permission"]}) && "Re-order",
                    !row.charge_fee_amount && WithPermission({module: "Orders", permission: ["update_permission"]}) && "Cancelation fee"
                ];
            }
            default: {
                return [];
            }
        }
    };

    const userStatus = (type) => {
        switch (type) {
            case "INVITED": {
                return [
                    WithPermission({module: "Users", permission: ["update_permission"]}) && "Edit",
                    WithPermission({module: "Users", permission: ["update_permission"]}) && "Re-send invite",
                    WithPermission({module: "Users", permission: ["update_permission"]}) && "Deactivate",
                    WithPermission({module: "Users", permission: ["delete_permission"]}) && "Delete"
                ];
            }
            case "ACTIVATE": {
                return [
                    WithPermission({module: "Users", permission: ["update_permission"]}) && "Edit",
                    WithPermission({module: "Users", permission: ["update_permission"]}) && "Deactivate"
                    // WithPermission({module: "Users", permission: ["delete_permission"]}) && "Delete"
                ];
            }
            case "DEACTIVATE": {
                return [
                    WithPermission({module: "Users", permission: ["update_permission"]}) && "Edit",
                    WithPermission({module: "Users", permission: ["update_permission"]}) && "Activate",
                    WithPermission({module: "Users", permission: ["delete_permission"]}) && "Delete"
                ];
            }
            default: {
                return [];
            }
        }
    };

    const handleReviewModal = (row) => {
        setActiveCard(row);
        setReviewOptionModal((pre) => !pre);
    };

    const isAnyInfoEmptyOrNull = (data) => {
        /* eslint-disable no-console */
        /* eslint-disable no-restricted-syntax */
        const keysToCheck = [
            "order_number",
            "service_type",
            "order_type",
            "delivery_address",
            "delivery_time",
            "delivery_date",
            "no_of_guest",
            "total_price",
            "driver",
            "status",
            "order_id",
            "created_at",
            "order_by",
            "drive_time",
            "setup_time",
            "leave_by",
            "customer",
            "brand",
            "kitchen_location"
        ];

        for (const key of keysToCheck) {
            if (data[key] === null || data[key] === "") {
                return true; // Return true if any property is null or empty
            }
            if (key === "total_price" && data[key] <= data?.preferred_price + data?.sales_tax + data?.service_fee_value) {
                return true; // Return true if total_price is not greater than 0
            }
        }
        return false; // If no empty or null values found
    };

    const [enableImageViewer, setEnableImageViewer] = useState(false);
    const [imagesData, setImagesData] = useState([]);

    const handleClickOpen = (row) => {
        setImagesData(row);
        setEnableImageViewer(true);
    };

    const handleClose = () => {
        setEnableImageViewer(false);
    };
    const downloadAllImages = (images) => {
        images.forEach((src, index) => {
            setTimeout(() => {
                const link = document.createElement("a");
                link.href = src;
                link.download = `image-${index + 1}.jpg`; // Adjust extension as needed
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }, index * 500); // Delay each download by 500ms, adjust as necessary
        });
    };

    // const handlePrev = (row) => {
    //     setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? row.images.length - 1 : prevIndex - 1));
    // };

    // const handleNext = (row) => {
    //     setCurrentImageIndex((prevIndex) => (prevIndex === row.images.length - 1 ? 0 : prevIndex + 1));
    // };

    return (
        <Box sx={styles.PaperWrapper}>
            {atc.loading ? (
                <CircularProgress />
            ) : (
                <Paper>
                    <ReviewModal
                        open={reviewOptionModal}
                        buttonTitle="Copy review"
                        title={activeCard?.OrderID}
                        rating={activeCard?.rating}
                        description={activeCard?.review}
                        author={activeCard?.Customer}
                        handleClose={handleReviewModal}
                        handleSubmit={() => handlePrintDownload(activeCard)}
                        noOnClose
                    />
                    {isMobile && (
                        <ApptableCards
                            {...props}
                            handleReviewModal={handleReviewModal}
                            reviewStatus={reviewStatus}
                            reportStatus={reportStatus}
                            statusChange={statusChange}
                            customerStatus={customerStatus}
                            orderStatus={orderStatus}
                            userStatus={userStatus}
                            isMobile={isMobile}
                        />
                    )}
                    {!isMobile && (
                        <TableContainer
                            sx={{
                                boxSizing: "border-box",
                                ...(!openFilter
                                    ? {maxHeight: currentTab === "scheduled" ? "calc(100vh - 424px)" : "calc(100vh - 176px)"}
                                    : {
                                          maxHeight:
                                              currentTab === "scheduled"
                                                  ? "calc(100vh - 550px)"
                                                  : currentTab === "brands" || currentTab === "settings"
                                                  ? "calc(100vh - 218px)"
                                                  : "calc(100vh - 302px)"
                                      }),

                                ...(!multipleQuote && !singleQuote && {border: "1px solid #F0F0F0"}),
                                borderRadius: "4px",
                                marginTop: "4px"
                            }}
                            id="table"
                        >
                            <Table stickyHeader>
                                <TableHead sx={styles.tableBackground}>
                                    {headerShow && (
                                        <TableRow sx={{position: "relative", zIndex: 10}}>
                                            {withCheckbox ? (
                                                <TableCell
                                                    padding="checkbox"
                                                    sx={{
                                                        backgroundColor: "#f7f7f7",
                                                        height: "20px",
                                                        width: "38px",
                                                        minWidth: "38px",
                                                        padding: "12px 4px !important",

                                                        [theme.breakpoints.down("1980")]: {
                                                            paddingTop: tab === "promotion" ? "14px !important" : "" // did this for promotion
                                                        },
                                                        [theme.breakpoints.down("1860")]: {
                                                            paddingTop: tab === "promotion" ? "17px !important" : "" // did this for promotion
                                                        },

                                                        [theme.breakpoints.down("1664")]: {
                                                            paddingTop: tab === "promotion" ? "19px !important" : "0" // did this for promotion
                                                        },
                                                        [theme.breakpoints.down("1265")]: {
                                                            paddingTop: tab === "promotion" ? "25px !important" : "0" // did this for promotion
                                                        }
                                                    }}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        sx={{pb: 0, width: "22px", height: 18}}
                                                        indeterminate={false}
                                                        checked={atc.isAllChecked}
                                                        onChange={atc.handleSelectAllClick}
                                                        inputProps={{
                                                            "aria-label": "select all desserts"
                                                        }}
                                                        disableRipple
                                                        icon={
                                                            <img
                                                                src={Unchecked}
                                                                style={{width: "18px", height: "18px", marginLeft: "14px"}}
                                                                alt="unchecked"
                                                            />
                                                        }
                                                        checkedIcon={
                                                            <img
                                                                src={Checked}
                                                                alt="checkbox"
                                                                style={{width: "18px", height: "18px", marginLeft: "14px"}}
                                                            />
                                                        }
                                                    />
                                                </TableCell>
                                            ) : leftSpace ? (
                                                <TableCell
                                                    padding="checkbox"
                                                    sx={{backgroundColor: "#f7f7f7", height: "20px", width: "8px"}}
                                                />
                                            ) : null}

                                            {headers?.map((obj, index) => (
                                                <TableCell
                                                    key={index}
                                                    // {...(obj.key?.toLowerCase() === "action" && {align: "right"})}
                                                    sx={{
                                                        color: "#8f9198 !important",
                                                        pb: 1,
                                                        fontWeight: 600,
                                                        padding: "12.5px 4px !important",
                                                        maxWidth: 160,
                                                        height: "50px !important",
                                                        backgroundColor: "#f7f7f7",
                                                        fontSize: "13px !important",
                                                        verticalAlign: "middle",
                                                        paddingTop: index === 0 ? "14px !important" : "12.5px !important"
                                                        // textAlign: withCheckbox && obj.key?.toLowerCase() === "action" ? "left" : "right"
                                                        // " @media (max-width: 1600px)": {
                                                        //      minWidth: "80px"
                                                        // }
                                                    }}
                                                >
                                                    {sortingLabel?.includes(obj.label) ? (
                                                        <TableSortLabel
                                                            active={!!obj.key}
                                                            sx={{
                                                                color: "#8f9198 !important",
                                                                fontWeight: 600,
                                                                fontSize: "13px",
                                                                backgroundColor: "#f7f7f7",
                                                                pb: 0.15,
                                                                "& .MuiTableSortLabel-icon": {
                                                                    color: "#8f9198 !important",
                                                                    width: "14px",
                                                                    height: "14px",
                                                                    opacity: "0.9 !important",
                                                                    fontSize: "13px !important"
                                                                }
                                                            }}
                                                            direction={atc.orderBy === obj.key ? atc.order : "asc"}
                                                            onClick={() => atc.handleSorted(obj.key)}
                                                        >
                                                            {notCaptilize ? obj?.label : capitalizeFirstLetter(obj.label)}
                                                        </TableSortLabel>
                                                    ) : notCaptilize ? (
                                                        obj?.label
                                                    ) : (
                                                        capitalizeFirstLetter(obj.label)
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    )}
                                    {loading ? (
                                        <TableRow>
                                            <TableCell colSpan={headers?.length + 1} sx={{p: 0}}>
                                                <LinearProgress color="primary" />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={headers?.length + 1} sx={{p: 0, height: "6px", borderBottom: "none"}} />
                                        </TableRow>
                                    )}
                                    {atc.updateData?.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={headers?.length + 1}>
                                                {loading ? "" : <Box sx={{marginLeft: "2rem"}}>No data found</Box>}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableHead>

                                <TableBody sx={{maxHeight: "100%", margin: "6px !important"}}>
                                    {atc.updateData?.map((row, index) => {
                                        const isItemSelected = atc.isSelected(JSON.stringify(row));
                                        let showMultiQuoteData = false;
                                        let showSingleQuoteData = false;
                                        if (multipleQuote) {
                                            showMultiQuoteData = isAnyInfoEmptyOrNull(row);
                                        }
                                        if (singleQuote) {
                                            showSingleQuoteData = isAnyInfoEmptyOrNull(row);
                                        }
                                        return (
                                            <React.Fragment key={index}>
                                                <Dialog
                                                    PaperProps={{
                                                        sx: {
                                                            boxShadow: "0px 0px 1px rgba(0,0,0,0.2)",
                                                            padding: "4rem 2rem 2rem 2rem"
                                                        }
                                                    }}
                                                    backgroundColor="transparent"
                                                    open={enableImageViewer}
                                                    onClose={handleClose}
                                                    maxWidth="md"
                                                    hideBackdrop
                                                >
                                                    <Box
                                                        sx={{position: "absolute", right: "16px", top: "16px", cursor: "pointer"}}
                                                        onClick={handleClose}
                                                    >
                                                        <img src={Cancel} alt="plus-icon" width="12px" height="12px" />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            position: "absolute",
                                                            left: "16px",
                                                            top: "16px",
                                                            cursor: "pointer",
                                                            backgroundColor: "white",
                                                            padding: "8px",
                                                            borderRadius: "4px"
                                                        }}
                                                        onClick={() => downloadAllImages(imagesData)}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                cursor: "pointer",
                                                                fontWeight: "bold"
                                                            }}
                                                        >
                                                            Download all
                                                        </Button>
                                                    </Box>
                                                    <ImageList cols={imagesData?.length === 1 ? 1 : 2} rowHeight={300}>
                                                        {[...imagesData]?.map((src, index) => {
                                                            return (
                                                                <ImageListItem key={index}>
                                                                    <img
                                                                        key={index}
                                                                        src={src}
                                                                        alt=""
                                                                        style={{width: "100%", height: "300px", backgroundSize: "cover"}}
                                                                    />
                                                                </ImageListItem>
                                                            );
                                                        })}
                                                    </ImageList>
                                                </Dialog>
                                                <StyledTableRow
                                                    multipleQuote
                                                    singleQuote
                                                    key={index}
                                                    className={tableCellWidth ? "multi-quote" : ""}
                                                    sx={{borderBottom: "1px solid red"}}
                                                    // sx={{
                                                    //     backgroundColor: "yellow",
                                                    //     borderBottom: "2px solid black !important",
                                                    //     "& th": {
                                                    //         fontSize: "1.25rem",
                                                    //         color: "rgba(96, 96, 96)"
                                                    //     }
                                                    // }}
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    selected={isItemSelected}
                                                >
                                                    {withCheckbox ? (
                                                        <TableCell sx={{width: "38px", minWidth: "38px"}}>
                                                            <Box height="100%" display="flex" alignItems="center">
                                                                <Checkbox
                                                                    sx={{
                                                                        width: 22,
                                                                        height: 30,
                                                                        marginTop: currentPath === "/Reports" ? "-2px" : "0px"
                                                                    }}
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        "aria-labelledby": `enhanced-table-checkbox-${index}`
                                                                    }}
                                                                    disableRipple
                                                                    icon={
                                                                        <img
                                                                            src={Unchecked}
                                                                            style={{width: "18px", height: "18px", marginLeft: "14px"}}
                                                                            alt="checked"
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <img
                                                                            src={Checked}
                                                                            alt="checkbox"
                                                                            style={{width: "18px", height: "18px", marginLeft: "14px"}}
                                                                        />
                                                                    }
                                                                    onClick={(event) => atc.handleClick(event, JSON.stringify(row))}
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                    ) : leftSpace ? (
                                                        <TableCell padding="checkbox" sx={{height: "20px", width: "8px"}} />
                                                    ) : null}
                                                    {headers?.map(({key}, index) => (
                                                        <React.Fragment key={index}>
                                                            {key !== "action" && key !== "images" ? (
                                                                <TableCell
                                                                    key={index}
                                                                    sx={{
                                                                        minWidth: 20,
                                                                        maxWidth: 220,
                                                                        ...(tableCellWidth && {width: "80px !important"}),
                                                                        ...(multipleQuote || singleQuote ? {border: "none"} : {}),
                                                                        fontSize: "11px",
                                                                        pt: 1.5,
                                                                        pb: 0.5,
                                                                        marginLeft: "25px !important",
                                                                        ...(showMultiQuoteData ||
                                                                            (multipleQuote && {border: "none !important"})),
                                                                        ...(showSingleQuoteData ||
                                                                            (singleQuote && {border: "none !important"}))
                                                                    }}
                                                                >
                                                                    {row[key]?.type !== "button" && typeof row[key] !== "object" ? (
                                                                        <Box
                                                                            height="100%"
                                                                            width="100%"
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            pb={0.7}
                                                                        >
                                                                            {getFields(
                                                                                key,
                                                                                row,
                                                                                id,
                                                                                path,
                                                                                searchParam,
                                                                                handleReviewModal,
                                                                                handleHeaderActions,
                                                                                handleActionBtn,
                                                                                isSchedule
                                                                            )}
                                                                        </Box>
                                                                    ) : (
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                color: "#212121",
                                                                                alignItems: "center",

                                                                                height: "100%",
                                                                                pb: 0.7
                                                                            }}
                                                                        >
                                                                            {getFields(
                                                                                key,
                                                                                row,
                                                                                id,
                                                                                path,
                                                                                searchParam,
                                                                                handleReviewModal,
                                                                                handleHeaderActions,
                                                                                handleActionBtn,
                                                                                isSchedule
                                                                            )}
                                                                        </Box>
                                                                    )}
                                                                </TableCell>
                                                            ) : key === "images" ? (
                                                                <TableCell
                                                                    key={index}
                                                                    // align="right"
                                                                    // align={!withCheckbox && "right"}
                                                                    sx={{
                                                                        pt: 1.5,
                                                                        pb: 0.5,
                                                                        minWidth: 20,
                                                                        width: 70,
                                                                        fontSize: "12px",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    {row?.images?.length ? (
                                                                        <PhotoCameraBackIcon
                                                                            onClick={() => handleClickOpen(row?.images)}
                                                                            fontSize="small"
                                                                            sx={{cursor: "pointer", marginTop: "-3px"}}
                                                                        />
                                                                    ) : (
                                                                        "N/A"
                                                                    )}

                                                                    {/* {enableImageViewer && row?.images && (
                                                                        <ImageViewer
                                                                            style={{zIndex: 1000}}
                                                                            src={row?.images}
                                                                            currentIndex={currentImage}
                                                                            disableScroll={false}
                                                                            closeOnClickOutside={true}
                                                                            onClose={() => {}}
                                                                        />
                                                                    )} */}
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell
                                                                    key={index}
                                                                    // align="right"
                                                                    // align={!withCheckbox && "right"}
                                                                    sx={{minWidth: 20, width: 70, fontSize: "11px"}}
                                                                >
                                                                    <ActionButton
                                                                        row={row}
                                                                        ActionArray={
                                                                            !isReport
                                                                                ? row?.order_number
                                                                                    ? orderStatus(row?.status.trim(), row)
                                                                                    : row?.sub_role_detail
                                                                                    ? userStatus(row?.status.trim())
                                                                                    : row.is_active === true || row.is_active === false
                                                                                    ? [...ActionArray, statusChange(row)]
                                                                                    : row?.customer_review_id
                                                                                    ? reviewStatus(row.status)
                                                                                    : row?.reportUniqueID
                                                                                    ? reportStatus(row.status)
                                                                                    : row?.customerUniqueID
                                                                                    ? customerStatus(row.isActivate)
                                                                                    : [...ActionArray]
                                                                                : [...orderStatus(row?.status.trim(), row), ...ActionArray]
                                                                        }
                                                                        handleActionBtn={handleActionBtn}
                                                                    />
                                                                </TableCell>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                    {multipleQuote && singleQuote && (
                                                        <TableCell
                                                            key={index}
                                                            align="right"
                                                            onClick={() => handleActionBtn("Delete", row)()}
                                                            sx={{
                                                                minWidth: 30,
                                                                cursor: "pointer",
                                                                width: 30,
                                                                fontSize: "11px",
                                                                pt: 1.5,
                                                                pb: 0.5,
                                                                pr: 0.6,
                                                                ...(showMultiQuoteData && {borderBottom: "none !important"}),
                                                                ...(showSingleQuoteData && {borderBottom: "none !important"})
                                                            }}
                                                        >
                                                            <img src={CancelIcon} alt="cancel" width={10} height={11} />
                                                        </TableCell>
                                                    )}
                                                </StyledTableRow>
                                                {multipleQuote && showMultiQuoteData && (
                                                    <TableRow>
                                                        <TableCell sx={{border: "none !important"}} colSpan={4} singleQuote multipleQuote>
                                                            <Alert
                                                                severity="error"
                                                                sx={{
                                                                    height: 28,
                                                                    padding: "4px 8px",
                                                                    fontSize: "12px",
                                                                    width: "180px",
                                                                    marginTop: "-10px",
                                                                    "& .MuiSvgIcon-root": {
                                                                        width: "16px",
                                                                        height: "16px"
                                                                    }
                                                                }}
                                                            >
                                                                Information is missing
                                                            </Alert>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                {multipleQuote && (
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{borderBottom: "none !important"}} colSpan={4} multipleQuote>
                                                                <CustomNewDateTimePicker
                                                                    label="Quote expiration"
                                                                    value={
                                                                        row?.quote_expiry_time > new Date().toISOString()
                                                                            ? row?.quote_expiry_time
                                                                            : ""
                                                                    }
                                                                    disablePast
                                                                    onChange={(val) => handleDateChange(val, "multiple", row?.order_id)}
                                                                    maxDate={row?.delivery_date}
                                                                />
                                                            </TableCell>
                                                        </TableRow>

                                                        {!isQuotes && (
                                                            <TableRow>
                                                                <TableCell
                                                                    sx={{borderBottom: "1px solid #F0F0F0 !important"}}
                                                                    colSpan={4}
                                                                    multipleQuote
                                                                >
                                                                    <Grid container>
                                                                        <Grid item xs={12} mb={2}>
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    alignItems: "center"
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    fontSize="0.9375rem"
                                                                                    color="#26272A"
                                                                                    fontWeight={700}
                                                                                >
                                                                                    Deposit
                                                                                </Typography>
                                                                                <CustomSwitch
                                                                                    checked={row?.deposit_required}
                                                                                    onChange={(e) => {
                                                                                        handleMultiQuoteToggleState(
                                                                                            e.target.checked,
                                                                                            row?.order_id
                                                                                        );
                                                                                        if (!e.target.checked) {
                                                                                            handleMutiQuoteTogglePercentage(
                                                                                                "",
                                                                                                row?.order_id
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            {row?.deposit_required && (
                                                                                <CustomTextField
                                                                                    disabled={!row?.deposit_required}
                                                                                    placeholder="Deposit"
                                                                                    label="Deposit"
                                                                                    fullWidth
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                %
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    value={row?.deposit_percentage || ""}
                                                                                    onChange={(e) => {
                                                                                        const inputValue = e.target.value;
                                                                                        if (validateInputForPercentage(inputValue)) {
                                                                                            handleMutiQuoteTogglePercentage(
                                                                                                inputValue,
                                                                                                row?.order_id
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {atc.confirmModal && (
                        <ConfrimDialog
                            open={atc.confirmModal}
                            handleClose={() => atc.handleConfirmModal()}
                            title="Are you sure you want to perform this action?"
                            confirm={(value) => atc.handleActions(value)}
                            backdropDisable
                        />
                    )}
                </Paper>
            )}
        </Box>
    );
}

export default AppTables;
