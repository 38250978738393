import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, Box, Typography, Chip} from "@mui/material";
import {IconX} from "@tabler/icons";
import ActionButton from "commonComponent/Apptable/ActionButton";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {dayList, homeMealHeader} from "constants/helperConstants";
import {ErrorMessage, FieldArray} from "formik";
import React from "react";
import formComponentsStyles from "./formComponentsStyle";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";

const HomeMeal = (props) => {
    const {formik, readOnly} = props;
    const {values, getFieldProps} = formik;
    const {homeMealDelivery} = values;

    const handleActionBtn = (type, arrayHelpers, arrIndex, obj) => () => {
        switch (type) {
            case "Duplicate": {
                arrayHelpers.insert(arrIndex + 1, {...obj});
                break;
            }
            case "Delete": {
                arrayHelpers.remove(arrIndex);
                break;
            }
            default:
                break;
        }
    };

    const TableCellStyle = () => ({
        p: 0,
        pl: 1,
        pb: 2,
        border: "none",
        verticalAlign: "Top"
    });

    const handleAddZipCode = (index, value) => {
        if (value && !homeMealDelivery[index].zipCode.includes(value)) {
            formik.setFieldValue(`homeMealDelivery[${index}].zipCode`, [...homeMealDelivery[index].zipCode, value]);
        }
    };

    const handleRemoveZipCode = (index, zipCode) => {
        const newZipCodes = homeMealDelivery[index].zipCode.filter((zip) => zip !== zipCode);
        formik.setFieldValue(`homeMealDelivery[${index}].zipCode`, newZipCodes);
    };
    return (
        <>
            <Grid container spacing={2} sx={formComponentsStyles?.mealContainer}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {homeMealHeader.map((obj, i) => (
                                        <TableCell key={obj.key} sx={{...formComponentsStyles.tableHeadCell(i), width: obj?.width}}>
                                            {obj.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray
                                    name="homeMealDelivery"
                                    render={(arrayHelpers) => (
                                        <>
                                            {homeMealDelivery?.length &&
                                                homeMealDelivery?.map((obj, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={() => ({...TableCellStyle(), ...formComponentsStyles.areaCell})}>
                                                            <CustomTextField
                                                                disabled={readOnly}
                                                                fullWidth
                                                                placeholder="Area"
                                                                {...getFieldProps(`homeMealDelivery[${index}].area`)}
                                                            />
                                                            <ErrorMessage
                                                                name={`homeMealDelivery[${index}].area`}
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={() => ({...TableCellStyle(), ...formComponentsStyles.zipCodeCell})}>
                                                            <CustomTextField
                                                                fullWidth
                                                                disabled={readOnly}
                                                                placeholder="Add zip code"
                                                                onKeyDown={(e) => {
                                                                    // Allow specific keys like backspace, delete, arrows, and numbers
                                                                    const allowedKeys = [
                                                                        "Backspace",
                                                                        "Tab",
                                                                        "ArrowLeft",
                                                                        "ArrowRight",
                                                                        "Delete",
                                                                        "Enter"
                                                                    ];

                                                                    const isNumberKey = e.key >= "0" && e.key <= "9";

                                                                    if (!allowedKeys.includes(e.key) && !isNumberKey) {
                                                                        e.preventDefault();
                                                                    }

                                                                    if (e.key === "Enter") {
                                                                        const zipCode = e.target.value.trim();
                                                                        const usZipCodeRegex = /^\d{5}(-\d{4})?$/;

                                                                        if (!usZipCodeRegex.test(zipCode)) {
                                                                            e.preventDefault();
                                                                            dispatch(
                                                                                openSnackbar({
                                                                                    open: true,
                                                                                    message:
                                                                                        "Please enter a valid zip code (e.g : 12345).",
                                                                                    variant: "alert",
                                                                                    alert: {
                                                                                        color: "error"
                                                                                    },
                                                                                    transition: "Fade",
                                                                                    close: true
                                                                                })
                                                                            );
                                                                            return;
                                                                        }

                                                                        handleAddZipCode(index, zipCode);
                                                                        e.preventDefault();
                                                                        e.target.value = "";
                                                                    }
                                                                }}
                                                            />
                                                            {homeMealDelivery[index].zipCode &&
                                                                homeMealDelivery[index].zipCode.length > 0 && (
                                                                    <Box sx={formComponentsStyles.chipContainer}>
                                                                        {homeMealDelivery[index].zipCode.map((zip, zipIndex) => (
                                                                            <Chip
                                                                                key={zipIndex}
                                                                                label={zip}
                                                                                onDelete={() => handleRemoveZipCode(index, zip)}
                                                                                sx={formComponentsStyles.chip}
                                                                                deleteIcon={
                                                                                    <IconX
                                                                                        style={{color: "#000"}}
                                                                                        height="13px"
                                                                                        width="13px"
                                                                                    />
                                                                                }
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                            <ErrorMessage
                                                                name={`homeMealDelivery[${index}].zipCode`}
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={() => ({...TableCellStyle(), ...formComponentsStyles.dayCell})}>
                                                            <CustomSelectWithCheckbox
                                                                disabled={readOnly}
                                                                label="Select Days"
                                                                menuItems={dayList}
                                                                values={homeMealDelivery[index].days}
                                                                onChange={(val) =>
                                                                    formik.setFieldValue(`homeMealDelivery[${index}].days`, val)
                                                                }
                                                                sort={false}
                                                            />
                                                            <ErrorMessage
                                                                name={`homeMealDelivery[${index}].days`}
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={() => ({...TableCellStyle()})} />

                                                        <TableCell align="right" sx={() => ({...TableCellStyle(), paddingTop: "12px"})}>
                                                            {!readOnly && (
                                                                <ActionButton
                                                                    ActionArray={index === 0 ? ["Duplicate"] : ["Duplicate", "Delete"]}
                                                                    handleActionBtn={(type) => (e) =>
                                                                        handleActionBtn(type, arrayHelpers, index, obj)(e)}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            {!readOnly && (
                                                <TableRow>
                                                    <Box
                                                        sx={formComponentsStyles.addButton}
                                                        onClick={() =>
                                                            arrayHelpers.push({
                                                                area: "",
                                                                zipCode: [],
                                                                days: []
                                                            })
                                                        }
                                                    >
                                                        <SymbolButton title="Add another" />
                                                    </Box>
                                                </TableRow>
                                            )}
                                        </>
                                    )}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
};

export default HomeMeal;
