import React, {useState, useEffect} from "react";
import {Box, Grid, Typography, FormControlLabel, Radio, RadioGroup, InputAdornment, Switch, Button} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {enforceFormat, formatToPhone, enforceNumericAndMaxLength} from "utils/helperFunctions/helpers";
import {ErrorMessage, Form, Formik} from "formik";
import * as yup from "yup";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomRadioButton from "commonComponent/Form/Fields/CustomRadioButton";
import {API, NetworkManager} from "network/core";
import {openSnackbar} from "store/slices/snackbar";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import {addNewHouseCardValidation} from "constants/validationConstant";
import HouseAccountView from "./HouseAccountView/HouseAccountView";

const formSchema = {
    customer_name: "",
    company: "",
    payment: "",
    bank_routing_no: "",
    bank_account_no: "",
    paymentTerm: "Due on recepit",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: ""
};

function HouseAccount({props}) {
    const [initialValues, setInitialValues] = useState(formSchema);

    const prm = useParams();
    const [urlSearchParams] = useSearchParams();

    const editMode = urlSearchParams.get("edit");
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [houseData, setHouseData] = useState([]);
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [street, setStreet] = useState("");
    const [country, setCountry] = useState("");
    const customerId = prm?.type;
    const [existingCompany, setExistingCompany] = useState([]);
    const [companySearch, setCompanySearch] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [customerCardId, setCustomerCardId] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const customerHouseAccountActivate = async (customerCardId) => {
        const paylaod = {
            customer_card_id: customerCardId
        };
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTACTIVE);
        const response = await instance.request(paylaod);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
        } else {
            let errorMessage = response.message;

            if (errorMessage === '"customer_card_id" is required') {
                errorMessage = "Customer Card ID is required.";
            }

            dispatch(
                openSnackbar({
                    open: true,
                    message: errorMessage,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    const deactivateHouseAccount = async (customerCardId) => {
        const payload = {
            customer_card_id: customerCardId
        };
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTDEACTIVE);
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
        } else {
            let errorMessage = response.message;

            if (errorMessage === '"customer_card_id" is required') {
                errorMessage = "Customer Card ID is required.";
            }
            dispatch(
                openSnackbar({
                    open: true,
                    message: errorMessage,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    const toggleHouseAccount = async (isChecked) => {
        const action = isChecked ? customerHouseAccountActivate : deactivateHouseAccount;
        const response = await action(customerCardId);

        if (response.success) {
            setIsActive(isChecked);
        } else {
            setIsActive(!isChecked);
        }
    };

    const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setIsActive(isChecked);
        toggleHouseAccount(isChecked);
    };

    const deleteHouseAccount = async (customerCardId) => {
        const payload = {
            customer_card_id: customerCardId
        };
        const instance = NetworkManager(API.CARD.DELETEHOUSEACCOUNT);
        const response = await instance.request(payload);
        let errorMessage = response.message;

        if (errorMessage === '"customer_card_id" is required') {
            errorMessage = "Customer Card ID is required.";
        }
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: errorMessage,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            navigate(-1);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const customerProfileInstance = NetworkManager(API.CUSTOMER.GETCUSTOMERDATA(`${customerId}`));
            const houseAccountInstance = NetworkManager(API.CARD.HOUSEACCOUNTDATA(customerId));

            const [customerProfileResponse, houseAccountResponse] = await Promise.all([
                customerProfileInstance.request(),
                houseAccountInstance.request()
            ]);

            if (customerProfileResponse.success) {
                setCustomerData(customerProfileResponse.data);
            }

            if (houseAccountResponse.success) {
                const houseAccountData = houseAccountResponse.data;
                setCustomerCardId(houseAccountData?.customer_card_id);
                setHouseData(houseAccountData);
                if (houseAccountData?.customer_card_id) {
                    setIsUpdate(true);
                } else {
                    setIsUpdate(false);
                }
                const matchingCard = customerProfileResponse.data?.customerCard?.find((card) => {
                    return card.customer_card_id === houseAccountData?.customer_card_id;
                });

                const isMatchingCardActive = matchingCard?.is_active || false;

                setIsActive(Boolean(isMatchingCardActive));
            }

            setLoading(false);
        };

        fetchData();
    }, [customerId]);

    const getExistingCompany = async () => {
        const instance = NetworkManager(API.COMPANY.GET);
        const response = await instance.request({}, [`?company_name=${companySearch}`]);
        if (response.success) {
            const data = response.data.companyList;
            setExistingCompany(data);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Error fetching existing companies",
                    variant: "error",
                    close: true
                })
            );
        }
    };

    const createCompany = async (name) =>
        new Promise((resolve) => {
            const instance = NetworkManager(API.COMPANY.ADD);
            instance.request({company_name: name}).then((response) => {
                if (response.success) {
                    resolve(response.data?.company?.company_id);
                }
            });
        });

    useEffect(() => {
        if (companySearch) {
            getExistingCompany();
        } else {
            setExistingCompany([]);
        }
    }, [companySearch, dispatch]);

    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    const handleCompanyClick = () => {
        getExistingCompany();
    };

    const handleSaveDetails = async (detail, setSubmitting) => {
        setSubmitting(true);
        let paylaod = {};

        if (detail.cardType === "ACH") {
            paylaod = {
                card_type: "HOUSE_ACCOUNT",
                payment_term: detail.paymentTerm,
                billing_first_name: detail.first_name,
                echeck_type: "ACH",
                address_details: {
                    street_line: street,
                    city: city,
                    state: state,
                    pincode: pincode,
                    country: country
                }
            };
        } else {
            paylaod = {
                card_type: "HOUSE_ACCOUNT",
                payment_term: detail.paymentTerm,
                check_number: detail.checkNumber,
                echeck_type: "Check",
                address_details: {
                    street_line: street,
                    city: city,
                    state: state,
                    pincode: pincode,
                    country: country
                }
            };
        }

        const instance = isUpdate
            ? NetworkManager(API.CARD.HOUSEACCOUNTUPDATE(customerId))
            : NetworkManager(API.CARD.HOUSEACCOUNT(customerId));

        const res = await instance.request(paylaod);

        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            navigate(-1);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setSubmitting(false);
    };

    const userName = (row) => {
        return row?.full_name + (row?.last_name ? ` ${row?.last_name}` : "") || " ";
    };

    const onError = (status, clearSuggestions) => {
        clearSuggestions();
    };

    const parseAddressComponents = async (addressComponents) => {
        if (addressComponents) {
            let newPincode = "";
            let newCity = "";
            let newState = "";
            let newStreetLine = "";
            let newCountry = "";
            for (let i = 0; i < addressComponents.length; i += 1) {
                const component = addressComponents[i];
                if (component.types.includes("postal_code")) {
                    newPincode = component.long_name;
                } else if (component.types.includes("locality")) {
                    newCity = component.short_name;
                } else if (component.types.includes("administrative_area_level_2")) {
                    newState += component.short_name + " "; // eslint-disable-line prefer-template
                } else if (component.types.includes("administrative_area_level_1")) {
                    newState += component.short_name;
                } else if (component.types.includes("premise")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("subpremise")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("route")) {
                    newStreetLine += component.short_name;
                } else if (component.types.includes("street_number")) {
                    newStreetLine += component.short_name + " "; // eslint-disable-line prefer-template
                } else if (component.types.includes("point_of_interest")) {
                    newStreetLine += component.short_name + ", "; // eslint-disable-line prefer-template
                } else if (component.types.includes("country")) {
                    newCountry = component.short_name;
                }
            }
            setPincode(newPincode);
            setCity(newCity);
            setState(newState);
            setStreet(newStreetLine);
            setCountry(newCountry);
        }
    };

    return editMode ? (
        <>
            <Formik
                enableReinitialize
                validationSchema={addNewHouseCardValidation}
                initialValues={{
                    name: userName(customerData),
                    company: customerData?.company?.company_name,
                    cardType: houseData?.echeck_type || "Check",
                    paymentTerm: houseData?.payment_term || "DUE_ON_RECEIPT",
                    checkNumber: "1111111111"
                }}
                onSubmit={(val, {setSubmitting}) => handleSaveDetails(val, setSubmitting)}
            >
                {(formik) => {
                    return (
                        <Grid container spacing={2}>
                            <Grid item xs={12} mt={2}>
                                <Typography variant="body4" color="#000" fontSize="1.375rem" fontWeight="600">
                                    Customer
                                </Typography>
                            </Grid>
                            {houseData?.customer_card_id && (
                                <Grid item xs={12}>
                                    <Grid container sx={{alignItems: "center"}}>
                                        <Grid item>
                                            <Typography sx={{fontSize: "13px", fontWeight: "600", color: "#000"}}>Active</Typography>
                                        </Grid>
                                        <Grid item>
                                            {" "}
                                            <Switch
                                                color="primary"
                                                width="36px"
                                                checked={isActive}
                                                onChange={handleSwitchChange}
                                                name="sdm"
                                                // size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                <CustomTextField
                                    disabled
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    label="Customer name"
                                    fullWidth
                                    inputProps={{maxLength: 50}}
                                    {...formik.getFieldProps("name")}
                                />
                                <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} mt={1} xl={3.5} sx={{position: "relative"}}>
                                <CustomTextField
                                    disabled
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    {...props}
                                    fullWidth
                                    name="company"
                                    label="Company (optional)"
                                    onKeyDown={stopPropagationForTab}
                                    value={formik.values.company || ""}
                                    onClick={handleCompanyClick}
                                    onChange={(e) => {
                                        setCompanySearch(e.target.value);
                                        formik.setFieldValue("company", e.target.value);
                                    }}
                                    onBlur={async () => {
                                        if (companySearch) {
                                            const companyId = await createCompany(companySearch);
                                            formik.setFieldValue("companyId", companyId);
                                        }
                                    }}
                                />
                                {!!existingCompany?.length && (
                                    <Box
                                        sx={{
                                            p: 1,
                                            boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                            borderRadius: 0.5,
                                            position: "absolute",
                                            width: "100%",
                                            bgcolor: "#fff",
                                            maxHeight: "200px",
                                            overflow: "auto",
                                            zIndex: 1380
                                        }}
                                    >
                                        {existingCompany?.map((obj, i) => (
                                            <Box
                                                key={i}
                                                sx={{p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}}
                                                onClick={() => {
                                                    formik.setFieldValue("company", obj.company_name);
                                                    formik.setFieldValue("companyId", obj.company_id);
                                                    setExistingCompany([]);
                                                }}
                                            >
                                                <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                                    {obj?.company_name}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Typography variant="body4" color="#000" fontSize="1.375rem" fontWeight="600">
                                    Invoice settings
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={0.5}>
                                <Typography sx={{fontSize: "0.875rem", fontWeight: 700}}>Payment terms</Typography>
                                <Typography mt={0.4} sx={{fontSize: "0.813rem", fontWeight: 500}}>
                                    Days until payment is due, upon invoice generation
                                </Typography>
                                <Box mt={2}>
                                    <CustomRadioButton
                                        options={[
                                            {
                                                label: "Due on receipt",

                                                value: "DUE_ON_RECEIPT"
                                            },
                                            {label: "Net 10", value: "NET_10"},
                                            {label: "Net 15", value: "NET_15"},
                                            {label: "Net 30", value: "NET_30"},
                                            {label: "Net 60", value: "NET_60"}
                                        ]}
                                        value={formik.values.paymentTerm}
                                        onChange={(e) => formik.setFieldValue("paymentTerm", e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                <Box>
                                    <CustomSelect
                                        label="Payment type"
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: 200
                                                }
                                            }
                                        }}
                                        fullWidth
                                        noNone
                                        menuItems={[
                                            {
                                                label: "Check",
                                                id: "Check"
                                            },
                                            {
                                                label: "ACH",
                                                id: "ACH"
                                            }
                                        ]}
                                        value={formik.values.cardType}
                                        {...formik.getFieldProps("cardType")}
                                    />

                                    <ErrorMessage name="cardType" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Box>

                                {formik.values.cardType === "NOT REQUIRED" && (
                                    <Box mt={2.5}>
                                        <CustomTextField
                                            fullWidth
                                            label="Check number"
                                            type="number"
                                            placeholder="Check number"
                                            {...formik.getFieldProps("checkNumber")}
                                        />
                                        <ErrorMessage name="checkNumber" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Box>
                                )}
                            </Grid>

                            <Grid
                                container
                                style={{justifyContent: houseData?.customer_card_id ? "space-between" : "flex-end"}}
                                item
                                xs={12}
                                mt={4}
                            >
                                {houseData?.customer_card_id && (
                                    <Button
                                        sx={{
                                            background: "#EA3744",
                                            color: "#FFF",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            borderRadius: "4px",
                                            width: "187px",
                                            padding: "12px",
                                            "&:hover": {
                                                background: "#EA3744"
                                            }
                                        }}
                                        onClick={() => deleteHouseAccount(customerCardId)}
                                    >
                                        Delete house account
                                    </Button>
                                )}
                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: t.palette.error.dark}),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                navigate(-1);
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper}),
                                            onClick: (e) => {
                                                formik.handleSubmit();
                                            }
                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </>
    ) : (
        <HouseAccountView />
    );
}

export default HouseAccount;
