import {useTheme} from "@mui/material";

export const useStyles = () => {
    const theme = useTheme();
    return {
        addressesOuterWrapper: {
            padding: {xs: 0, sm: "24px 0px"}
        },
        addressesInnerWrapper: {
            display: "flex",
            gap: "24px"
        },
        paymetHeading: {
            fontSize: "12px",
            fontWeight: "500",
            color: "#A0A7B9"
        },
        paymentWrapper: {
            padding: {xs: 0, sm: "16px 0px"}
        },
        paymentCardWrapper: {
            position: "relative",
            gap: "28px"
        },
        cardType: {
            fontSize: "12px",
            fontWeight: "500",
            color: "#A0A7B9"
        },
        cardHolderName: {
            fontWeight: 600,
            fontSize: "20px"
        },
        flexProp: {
            display: "flex",
            alignItems: "center",
            gap: "14px"
        },
        flexClass: {
            display: "flex"
        },
        forteenText: {
            fontSize: "14px"
        },
        defaultWeight: {
            fontWeight: "600"
        },
        cardGap: {
            paddingTop: "32px",
            paddingBottom: "26px"
        },
        justifyEnd: {
            display: "flex",
            alignItems: "center",
            justifyContent: "end"
        },
        justifyBetween: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        totalOrders: {
            fontWeight: 400
        },
        subHeaderFont: {
            fontSize: "12px",
            fontWeight: "500",
            color: "#A0A7B9"
        },
        iconBox: {
            height: "24px",
            width: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        preferredPricing: {
            width: "100%",
            height: "52px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 24px",
            border: `1px solid #E0E2E6`,
            borderRadius: "4px",
            [theme.breakpoints.down("lg")]: {
                width: "321px"
            }
        },
        defaultTextStyle: {
            fontSize: "14px",
            fontWeight: 400
        },
        resetButton: {
            borderRadius: "4px",
            width: "187px",
            padding: "12px",
            height: "44px"
        },
        deletButton: {
            background: "#EA3744",
            color: "#FFF",
            fontSize: "14px",
            fontWeight: "500",
            borderRadius: "4px",
            width: "187px",
            padding: "12px",
            height: "44px",
            "&:hover": {
                background: "#EA3744"
            }
        },
        notificationText: {
            fontSize: "16px",
            fontWeight: "500",
            color: "#A0A7B9"
        },
        password: {
            color: theme.palette.text.textlight,
            fontSize: "1.4rem"
        }
    };
};
