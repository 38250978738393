import {Chip, Grid, Typography} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {formatDateDayjs} from "commonComponent/Apptable/dateUtils";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {Box} from "@mui/system";
import {useDispatch} from "react-redux";
import {setChildFilterChips, setFilterChips, setNestFilterChips} from "store/slices/paginationSlice";

const FilterChips = (props) => {
    const {filterChips, filterState, getLabel = [], isChild, isNest} = props;
    const dispatch = useDispatch();
    const lengthCheck = !!Object.keys(filterChips).length;
    const handleDelete = (ele, label, elementStateMap) => {
        const copy = {...filterChips};
        const requiredKey = copy[ele];
        if (typeof requiredKey === "object") {
            const labelMap = new Map();
            elementStateMap.forEach((item) => {
                if (!labelMap.has(item?.label)) labelMap.set(item?.label, item?.id);
            });
            const itemToDelete = labelMap.get(label);
            const newArray = requiredKey.filter((itx) => itx !== itemToDelete);
            copy[ele] = newArray;
            if (newArray?.length === 0) {
                delete copy[ele];
            }
        } else {
            delete copy[ele];
            if (ele === "guest_count") {
                delete copy.guestFilter;
            }
            if (ele === "total_amount") {
                delete copy.amountFilter;
            }
            if (ele === "ltv") {
                delete copy.ltvFilter;
            }
            if (ele === "aov") {
                delete copy.aovFilter;
            }
            if (ele === "order_count") {
                delete copy.orderCountFilter;
            }
            if (!copy?.end_date && !copy?.start_date && !copy?.delivery_end_date && !copy?.delivery_start_date) {
                delete copy?.isCreatedAt;
            }
        }
        if (isNest) {
            dispatch(setNestFilterChips(copy));
        } else if (isChild) {
            dispatch(setChildFilterChips(copy));
        } else {
            dispatch(setFilterChips(copy));
        }
        // setFilterChips(copy);
    };
    const clearAll = () => {
        if (isNest) {
            dispatch(setNestFilterChips({}));
        } else if (isChild) {
            dispatch(setChildFilterChips({}));
        } else {
            dispatch(setFilterChips({}));
        }
    };
    const getFilteredLabel = (value) => {
        switch (value) {
            case "_greater_than":
                return "greater than";
            case "_less_than":
                return "less than";
            case "equal":
                return "equals to";
            case "_not_equal":
                return "not equals to";
            default:
                return "";
        }
    };

    const getStatus = (type) => {
        switch (type) {
            case "QUOTE_REJECTED": {
                return "Quote rejected";
            }
            case "Quote Sent": {
                return "Quote sent";
            }
            case "QUOTE_EXPIRED": {
                return "Quote expired";
            }
            case "QUOTE_EXPIRY_SOON": {
                return "Expiry soon";
            }
            case "Needs Driver": {
                return "Needs driver";
            }
            case "DELIVERED": {
                return "Delivered";
            }
            case "Driver Assigned": {
                return "Active";
            }
            case "ON_THE_WAY": {
                return "On the way";
            }
            case "DEACTIVATE": {
                return "Inactive";
            }
            case "ACTIVATE": {
                return "Active";
            }
            case "Accepted,Active": {
                return "Accepted";
            }
            case "INVITED": {
                return "Invited";
            }
            case "Cancelled": {
                return "Canceled";
            }

            default: {
                return type;
            }
        }
    };
    const getValue = (type) => {
        const check = type === "start_date" || type === "end_date" || type === "delivery_start_date" || type === "delivery_end_date";
        if (check) {
            return formatDateDayjs(filterChips[type]);
        } else if (type === "roleIds" || type === "account_manager_id") {
            const idMap = new Map();
            getLabel.forEach((item) => {
                idMap.set(item?.id, item?.label);
            });
            return idMap.get(filterChips[type]);
        } else if (type === "status") {
            return getStatus(filterChips[type]);
        } else {
            return filterChips[type];
        }
    };
    const Checkforlabel = (type, isArray = false) => {
        switch (type) {
            case "city":
                return type;

            case "start_date":
                return filterChips.isCreatedAt ? `Created ` : `Start date:`;
            case "end_date": {
                return `End date:`;
            }
            case "delivery_start_date":
                return filterChips.isCreatedAt ? `Delivery from` : `Delivery start date:`;
            case "delivery_end_date": {
                return ` Delivery end date:`;
            }
            case "service_name": {
                return "Service";
            }
            case "service_type": {
                return "Service";
            }
            case "market_name": {
                return "Market";
            }
            case "roleIds": {
                return "Role";
            }
            case "market_id": {
                return "Market";
            }
            case "brand_name": {
                return "Brand";
            }
            case "brand_id": {
                return "Brand";
            }
            case "order_id": {
                return "Order";
            }
            case "market_ids": {
                return "Market:";
            }
            case "customer_id:": {
                return "Customer:";
            }
            case "customer_name": {
                return "Customer:";
            }
            case "company_name": {
                return "Company:";
            }

            case "menu_name": {
                return "Menu:";
            }

            case "delivery_address": {
                return "Address:";
            }
            case "guest_count": {
                const getFilter = getFilteredLabel(filterChips?.guestFilter);
                return `Guest ${getFilter}:`;
            }
            case "status": {
                return "Status:";
            }
            case "total_amount": {
                const getFilter = getFilteredLabel(filterChips?.amountFilter);
                return `Total ${getFilter}:`;
            }
            case "guestFilter": {
                return "";
            }
            case "amountFilter": {
                return "";
            }
            case "description": {
                return "";
            }
            case "modifier_name": {
                return "";
            }
            case "order_count": {
                const getFilter = getFilteredLabel(filterChips?.guestFilter);
                return `Order count ${getFilter}:`;
            }
            case "ltv": {
                const getFilter = getFilteredLabel(filterChips?.ltvFilter);
                return `LTV ${getFilter}:`;
            }
            case "aov": {
                const getFilter = getFilteredLabel(filterChips?.aovFilter);
                return `AOV ${getFilter}:`;
            }
            case "orderCountFilter": {
                return "";
            }
            case "ltvFilter": {
                return "";
            }
            case "aovFilter": {
                return "";
            }
            case "last_order_start_date":
                return "Last order from:";
            case "last_order_end_date":
                return "Last order to:";
            case "is_active":
                return "Status:";
            case "charged_by:":
                return "Payment type:";
            case "owner":
                return "";
            case "account_manager_id":
                return "Manager:";
            default:
                return isArray ? type : filterChips[type];
        }
    };

    const Chips = ({ele, label, elementStateMap}) => (
        <Chip
            label={ellipsizeText(label, 30)}
            sx={(t) => ({
                color: t.palette.secondary,
                textTransform: "normal",
                fontWeight: 400,
                fontSize: "12px",
                bgcolor: "RGBA(240,240,240,1)",
                height: "20px",
                borderRadius: "2px",
                padding: "1px 4px",
                gap: "3px",
                margin: "3px",
                lineHeight: "18px !important",
                letterSpacing: "-0.7px !important"
            })}
            deleteIcon={
                // <IconButton sx={{p: 0, mt: "3px"}}>
                <CloseIcon style={{color: "RGBA(0,0,0,0.45)", fontSize: "16px"}} variant="outlined" />
                // </IconButton>
            }
            onDelete={() => handleDelete(ele, label, elementStateMap)}
        />
    );
    return (
        lengthCheck && (
            <Grid container spacing={1 / 2} display="flex" alignItems="center">
                {lengthCheck &&
                    Object.keys(filterChips).map((ele, i) => {
                        if (filterChips.isCreatedAt && ele === "end_date") {
                            return null;
                        }
                        if (ele === "isCreatedAt") {
                            return null;
                        }
                        if (ele === "amountFilter") {
                            return null;
                        }
                        if (ele === "guestFilter") {
                            return null;
                        }
                        if (ele === "aovFilter") {
                            return null;
                        }
                        if (ele === "ltvFilter") {
                            return null;
                        }
                        if (ele === "orderCountFilter") {
                            return null;
                        }
                        if (Array.isArray(filterChips[ele]) && filterChips[ele].length) {
                            const elementState = filterState?.filter((item) => item?.key === ele);
                            if (elementState?.length > 0) {
                                const element = elementState[0];

                                const requiredArray = typeof element?.getData === "function" ? element.getData() : element.getData;
                                const elementStateMap = new Map();
                                requiredArray.forEach((item) => {
                                    if (!elementStateMap.has(item)) elementStateMap.set(item?.id, item);
                                });
                                const temp = [];
                                filterChips[ele].forEach((item) => {
                                    if (elementStateMap.has(item)) {
                                        temp.push(elementStateMap.get(item));
                                    }
                                });
                                return temp.map((item) => (
                                    <Grid item key={i}>
                                        <Chips ele={ele} label={item?.label} elementStateMap={requiredArray} />
                                    </Grid>
                                ));
                            }
                        }
                        if (!Array.isArray(filterChips[ele]) && filterChips[ele] !== "") {
                            const value = getValue(ele);
                            return (
                                <Grid item key={i}>
                                    {/* <Chips ele={ele} label={`${value}`} /> */}
                                    <Chips ele={ele} label={`${Checkforlabel(ele)} ${value}`} />
                                </Grid>
                            );
                        }
                        return null;
                    })}
                {lengthCheck && (
                    <Grid item>
                        <Box height={24} display="flex" alignItems="center" pl={1} onClick={clearAll} sx={{cursor: "pointer"}}>
                            <Typography color="primary" sx={{fontWeight: 400, fontSize: "14px"}}>
                                Clear all
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        )
    );
};

export default FilterChips;
