import React, {useState} from "react";
import {Stack, Typography, useMediaQuery, useTheme, Box, Grid} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, Formik, Form} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {QuoteRejectValidtaion} from "utils/validations/validations";

const formSchema = {
    reason: "",
    description: ""
};

const RejectQuote = ({selectedData, orderNumber, handleSelectedRow, setSelectedRow}) => {
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState(formSchema);
    const {toggleDrawer} = useTempDrawer();

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("sm"));

    const handleReject = async (values) => {
        const payload = {
            order_id: selectedData?.order_id,
            reason: values?.reason
        };
        if (values?.description) {
            payload.description = values?.description;
        }
        const instance = NetworkManager(API.ORDER.QUOTEREJECT);
        const res = await instance.request(payload);

        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            setSelectedRow(null);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setInitialValues(formSchema);
        setSelectedRow(null);
    };
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={QuoteRejectValidtaion}
                onSubmit={(val) => handleReject(val)}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer
                            sideDrawerState="rejectQuote"
                            handleSelectedRow={handleSelectedRow}
                            resetForm={formik.resetForm}
                            sx={{maxWidth: "430px"}}
                        >
                            <Stack
                                sx={{height: "100%", paddingBottom: "0px"}}
                                direction="column"
                                justifyContent="space-between"
                                padding={1}
                            >
                                <Box>
                                    <Box sx={{marginTop: "-8px"}}>
                                        <Typography fontSize="1.625rem" sx={{fontWeight: 700}}>
                                            Rejection of quote #{orderNumber}
                                        </Typography>
                                    </Box>
                                    <Box sx={{marginTop: "24px"}}>
                                        <Typography fontSize="1rem" sx={{fontWeight: 600}}>
                                            Reason
                                        </Typography>
                                    </Box>

                                    <Grid container sx={{marginTop: "8px"}}>
                                        <Grid item xs={12}>
                                            <CustomSelect
                                                noNone
                                                label="Select reason"
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            maxHeight: 200
                                                        }
                                                    }
                                                }}
                                                ellipSizeNumber={50}
                                                fullWidth
                                                numberValidation
                                                menuItems={[
                                                    {
                                                        label: "Too expensive",
                                                        id: "Too expensive"
                                                    },
                                                    {
                                                        label: "Event cancelled",
                                                        id: "Event cancelled"
                                                    },
                                                    {
                                                        label: "No longer need catering",
                                                        id: "No longer need catering"
                                                    },
                                                    {
                                                        label: "Chose different catering option",
                                                        id: "Chose different catering option"
                                                    },
                                                    {
                                                        label: "Want a different menu",
                                                        id: "Want a different menu"
                                                    },

                                                    {
                                                        label: "Other",
                                                        id: "Other"
                                                    }
                                                ]}
                                                {...formik.getFieldProps("reason")}
                                            />
                                            <ErrorMessage name="reason" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        {formik.values.reason === "Other" && (
                                            <Grid item xs={12} sx={{marginTop: "16px"}}>
                                                <CustomTextField
                                                    multiline
                                                    minRows={2.5}
                                                    sx={{"&": {height: "fit-content !important"}}}
                                                    fullWidth
                                                    placeholder="Specify reason for rejection"
                                                    inputProps={{maxLength: 1000}}
                                                    label="Specify reason for rejection"
                                                    {...formik.getFieldProps("description")}
                                                />
                                                <ErrorMessage
                                                    name="description"
                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                                <Box sx={{position: "sticky", bottom: 0, mt: 2, p: 0, bgcolor: "#fff", zIndex: 1}}>
                                    <Grid container justifyContent="flex-end" alignItems="center">
                                        <Grid
                                            item
                                            xs={12}
                                            spacing={1}
                                            sx={{textAlign: "left", display: "flex", justifyContent: "flex-end", flexDirection: "row"}}
                                            mt={0.8}
                                        >
                                            <ButtonGrouping
                                                btnprops={[
                                                    {
                                                        btnTitle: "Cancel",
                                                        sx: (t) => ({color: t.palette.error.dark}),
                                                        onClick: () => {
                                                            formik.resetForm();
                                                            handleSelectedRow(null);
                                                            toggleDrawer("right", false)();
                                                        }
                                                    },
                                                    {
                                                        btnTitle: "Submit",
                                                        variant: "contained",
                                                        disabled: formik.isSubmitting,
                                                        onClick: (e) => {
                                                            formik.handleSubmit();
                                                            formik.validateForm().then((res) => {
                                                                if (Object.keys(res).length === 0) {
                                                                    toggleDrawer("right", false)(e);
                                                                    setTimeout(() => {
                                                                        formik.resetForm();
                                                                    }, 1500);
                                                                }
                                                            });
                                                        },
                                                        sx: (t) => ({
                                                            color: t.palette.background.paper
                                                        })
                                                    }
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default RejectQuote;
