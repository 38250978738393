import {Box, Button, Grid, IconButton, InputAdornment, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
// import MobileCloseBtn from "components/Buttons/MobileCloseBtn";
// import NewCustomDrawer from "components/CommonComponent/SideDrawer/NewCustomeDrawer";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {customerResetPasswordValidation} from "constants/validationConstant";
import {ErrorMessage, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import {API} from "network/core";
import networkManager from "network/core/networkManager";
import React, {useState} from "react";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import Loader from "ui-component/Loader";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useStyles} from "../styles";

const CustomerResetPasswordForm = ({parentFormik, profileId, setIsResetOpen, customerData}) => {
    const {toggleDrawer} = useTempDrawer();
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [submitEvent, setSubmitEvent] = useState(null);
    const [initialState] = useState({
        newPassword: "",
        confirmPassword: ""
    });
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleResetPassword = async (val, setSubmitting) => {
        setSubmitting(true);
        setLoading(true);
        const payload = {
            email: parentFormik?.values?.email,
            new_password: val.newPassword,
            confirm_password: val.confirmPassword
        };

        const instance = networkManager(API.CUSTOMER.RESETPASSWORD(`${profileId}`));
        const res = await instance.request(payload);
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            toggleDrawer("right", false)();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setSubmitting(false);
        setLoading(false);
    };

    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();
    return (
        <>
            {loading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={initialState}
                validationSchema={customerResetPasswordValidation}
                onSubmit={(val, {setSubmitting}) => handleResetPassword(val, setSubmitting)}
            >
                {(formik) => (
                    <TemporaryDrawer drawerName="customerResetPasswordDrawer" zIndex={1350}>
                        <Box sx={{height: "100%", display: "flex", flexDirection: "column", marginTop: "-0.5rem"}}>
                            <Box sx={{pb: 10, flex: 1, overflowY: "scroll"}}>
                                <Box>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography fontSize="1.625rem" sx={{fontWeight: 700}}>
                                                {customerData?.is_verified ? "Reset password" : "Set password"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Grid container rowSpacing={2.5} mt={1}>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            label="New password"
                                            fullWidth
                                            id="outlined-adornment-new-password-login"
                                            inputProps={{
                                                autocomplete: "new-password",
                                                maxLength: 250,
                                                form: {
                                                    autocomplete: "new-password"
                                                }
                                            }}
                                            type={showNewPassword ? "text" : "password"}
                                            {...formik.getFieldProps("newPassword")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle new password visibility"
                                                            onClick={handleClickShowNewPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            size="small"
                                                        >
                                                            {showNewPassword ? (
                                                                <Visibility style={styles.password} />
                                                            ) : (
                                                                <VisibilityOff style={styles.password} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <ErrorMessage name="newPassword" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            label="Confirm new password"
                                            fullWidth
                                            inputProps={{maxLength: 250}}
                                            type={showConfirmPassword ? "text" : "password"}
                                            {...formik.getFieldProps("confirmPassword")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle confirm password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            size="small"
                                                        >
                                                            {showConfirmPassword ? (
                                                                <Visibility style={styles.password} />
                                                            ) : (
                                                                <VisibilityOff style={styles.password} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <ErrorMessage name="confirmPassword" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Typography fontSize="0.68rem" sx={{fontWeight: 400}} fontStyle="italic">
                                            Password must be at least 7 characters long including one number.
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                            </Box>
                            <Box sx={{p: 2}}>
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Button
                                        size="large"
                                        sx={{
                                            height: "44px",
                                            minWidth: "92px",
                                            color: "#F0475C"
                                        }}
                                        disabled={formik.isSubmitting}
                                        onClick={(e) => {
                                            formik.resetForm();
                                            toggleDrawer("right", false)(e);
                                            setIsResetOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            height: "44px",
                                            minWidth: "92px",
                                            color: "#FFF"
                                        }}
                                        disabled={formik.isSubmitting}
                                        onClick={(e) => {
                                            setSubmitEvent(e);
                                            formik.handleSubmit();
                                            formik.validateForm().then((res) => {
                                                // to close the sidebar if no field has validation error
                                                if (Object.keys(res).length === 0) {
                                                    toggleDrawer("right", false)(e);
                                                    setIsResetOpen(false);
                                                    setTimeout(() => {
                                                        formik.resetForm();
                                                    }, 1500);
                                                }
                                            });
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </TemporaryDrawer>
                )}
            </Formik>
        </>
    );
};

export default CustomerResetPasswordForm;
