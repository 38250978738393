import {Box, Grid, Typography, useTheme} from "@mui/material";
import {useStyles} from "../styles";
import {useEffect, useState} from "react";
import {NetworkManager, API} from "network/core";
import Visa from "assets/images/card/visa.svg";
import Master from "assets/images/card/master.svg";
import Discover from "assets/images/card/discover.svg";
import American from "assets/images/card/american.svg";
import PayPal from "assets/images/card/PayPal.svg";
import ApplePay from "assets/images/card/ApplePay.svg";
import Other from "assets/images/card/other.svg";
import {useDispatch} from "react-redux";
import useTempDrawer from "hooks/useTempDrawer";
import {openSnackbar} from "store/slices/snackbar";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import ChipComponent from "commonComponent/ChipComponent";
import ActionButton from "commonComponent/Apptable/ActionButton";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import PlusIcon from "assets/images/icons/plus.png";
import AddNewCardForm from "views/pages/privatePages/customers/CreateCustomer/AddNewCardForm";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";

function Wallet({formik, profileId}) {
    const styles = useStyles();
    const dispatch = useDispatch();
    const {toggleDrawer} = useTempDrawer();
    const [loading, setLoading] = useState(false);
    const [cardList, setCardList] = useState([]);
    const [houseAccount, setHouseAccount] = useState({});
    const [isAddNewCardFormOpen, setIsAddNewCardFormOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [cardType, setCardType] = useState("");

    const theme = useTheme();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const cardLogo = (cardType) => {
        switch (cardType) {
            case "Visa":
                return Visa;
            case "AmericanExpress":
                return American;
            case "Discover":
                return Discover;
            case "MasterCard":
                return Master;
            case "PayPal":
                return PayPal;
            case "ApplePay":
                return ApplePay;
            // case "DinersClub":
            //     return Discover;
            default:
                return Other;
        }
    };

    const getCardList = async () => {
        const instance = NetworkManager(API.CUSTOMER.GETCARDLIST(`${profileId}`));
        const res = await instance.request();
        if (res.success) {
            const cardsList = res?.data?.profile?.paymentProfiles || [];

            setCardList([...cardsList]);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    const getHouseAccountData = async () => {
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTDATA(profileId));

        const response = await instance.request();

        if (response?.success) {
            if (response.data && response?.data?.is_active) {
                setHouseAccount(response.data);
            } else {
                setHouseAccount({});
            }
        } else {
            setHouseAccount({});
        }
    };
    const actionButtonArray = ["Delete"];
    const getActionArray = (isDefault) => {
        return isDefault ? actionButtonArray.filter((item) => item !== "Make default") : actionButtonArray;
    };

    useEffect(() => {
        getCardList();
        getHouseAccountData();
    }, []);

    const formatCardInfo = (cardInfo) => {
        const {cardNumber, expirationDate} = cardInfo;
        const maskedCardNumber = cardNumber.replace(/X/g, "•");
        const replacedString = maskedCardNumber.replace(/(\D)(\d)/, "$1 $2");
        const maskedExpirationDate = expirationDate.replace(/X/g, "•");

        const formattedString = `${replacedString}   `;

        return formattedString;
    };

    const handleDeleteCard = async () => {
        setLoading(true);
        setOpen((pre) => !pre);

        const id = selectedCard;

        const instance = NetworkManager(API.CARD.DELETE(profileId, id));

        const res = await instance.request();

        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );

            getCardList();
            getHouseAccountData();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setLoading(false);
    };

    const handleActionBtn =
        (...params) =>
        (e) => {
            const type = params[0];
            const row = params[1];
            const handleClose = params[2];
            handleClose();
            setCardType("creditCard");
            switch (type) {
                case "Delete":
                    setSelectedCard(row?.customerPaymentProfileId);
                    handleDeleteModal();
                    break;

                case "Make default":
                    if (!row?.defaultPaymentProfile) {
                        // handleMakeDefault(
                        //     row?.card_type === "HOUSE_ACCOUNT" ? "HOUSE_ACCOUNT" : "CREDIT_CARD",
                        //     row?.customerPaymentProfileId
                        // );
                    }
                    break;

                default:
                    break;
            }
        };

    const handledeleteHouseAccount =
        (...params) =>
        (e) => {
            const type = params[0];
            const row = params[1];
            const handleClose = params[2];
            handleClose();
            setCardType("houseAccount");
            switch (type) {
                case "Delete":
                    setSelectedCard(row?.customer_card_id);
                    handleDeleteModal();
                    break;

                case "Make default":
                    if (!row?.defaultPaymentProfile) {
                        // handleMakeDefault(
                        //     row?.card_type === "HOUSE_ACCOUNT" ? "HOUSE_ACCOUNT" : "CREDIT_CARD",
                        //     row?.customerPaymentProfileId
                        // );
                    }
                    break;

                default:
                    break;
            }
        };

    const deleteHouseAccount = async () => {
        setLoading(true);
        setOpen((pre) => !pre);
        const payload = {
            customer_card_id: selectedCard
        };
        const instance = NetworkManager(API.CARD.DELETEHOUSEACCOUNT);
        const response = await instance.request(payload);
        let errorMessage = response.message;

        if (errorMessage === '"customer_card_id" is required') {
            errorMessage = "Customer Card ID is required.";
        }
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: errorMessage,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            getCardList();
            getHouseAccountData();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setLoading(false);
    };

    return (
        <Grid item xs={12}>
            {Object.keys(houseAccount).length > 0 && <Typography style={styles.paymetHeading}>HOUSE ACCOUNT</Typography>}
            {Object.keys(houseAccount).length > 0 ? (
                <Grid container sx={styles.paymentWrapper} gap={2}>
                    <Grid
                        item
                        justifyContent="space-between"
                        flexDirection="column"
                        xs={11.8}
                        sm={4.8}
                        md={4.8}
                        lg={2.8}
                        key={houseAccount.id}
                        border={`1px solid ${theme.palette.other.slateWhite}`}
                        borderRadius="4px"
                        px={4}
                        py={2}
                    >
                        <Grid container style={styles.justifyBetween}>
                            <Typography style={styles.cardHolderName}>House account</Typography>
                            <Box sx={{...styles?.flexer, ...styles?.flexGap, ...styles?.flexClass}}>
                                {" "}
                                {houseAccount?.is_house_account_default && <ChipComponent title="Default" />}
                                <ActionButton
                                    row={houseAccount}
                                    ActionArray={getActionArray(houseAccount?.is_house_account_default)}
                                    handleActionBtn={handledeleteHouseAccount}
                                />
                            </Box>
                        </Grid>

                        <Grid item sx={{paddingTop: "7px"}} style={styles.flexProp}>
                            <Box>
                                <Typography style={{...styles?.forteenText, ...styles?.totalOrders}}>
                                    {houseAccount?.check_number ? "Check" : "ACH"}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                ""
            )}
            <Box>
                {cardList.length > 0 && <Typography style={styles.paymetHeading}>CREDIT CARDS</Typography>}
                <Grid container sx={styles.paymentWrapper} gap={2.5}>
                    {cardList?.length > 0
                        ? cardList.map((account) => (
                              <Grid
                                  key={account.id}
                                  item
                                  container
                                  justifyContent="space-between"
                                  flexDirection="column"
                                  xs={11.8}
                                  sm={4.8}
                                  md={4.8}
                                  lg={2.8}
                                  sx={{
                                      border: `1px solid ${theme.palette.other.slateWhite}`,
                                      borderRadius: "4px",
                                      padding: "16px 32px"
                                  }}
                              >
                                  <Grid container style={styles.justifyEnd}>
                                      <Box sx={{...styles?.flexer, ...styles?.flexGap, ...styles?.flexClass}}>
                                          {account?.defaultPaymentProfile && <ChipComponent title="Default" />}
                                          <ActionButton
                                              row={account}
                                              ActionArray={getActionArray(account?.defaultPaymentProfile)}
                                              handleActionBtn={handleActionBtn}
                                          />
                                      </Box>
                                  </Grid>
                                  <Typography style={styles.cardHolderName}>{ellipsizeText(account?.billTo?.firstName, 15)}</Typography>
                                  <Grid item sx={{...styles.flexProp, ...styles.cardGap}}>
                                      <Box pt={0.9}>
                                          <img
                                              width="52px"
                                              height="36px"
                                              src={cardLogo(account?.payment?.creditCard?.cardType)}
                                              alt="Card logo"
                                          />
                                      </Box>
                                      <Box>
                                          <Typography style={styles?.forteenText} variant="subtitle3">
                                              {formatCardInfo(account?.payment?.creditCard)}
                                          </Typography>
                                      </Box>
                                  </Grid>
                              </Grid>
                          ))
                        : cardList?.length === 0 &&
                          Object.keys(houseAccount).length === 0 && (
                              <Grid container justifyContent="center">
                                  <Grid item>
                                      <Typography>No data available</Typography>
                                  </Grid>
                              </Grid>
                          )}
                </Grid>
            </Box>

            <Grid container>
                <Box
                    onClick={(e) => {
                        toggleDrawer("right", "addNewCardForm")(e);
                        setIsAddNewCardFormOpen(true);
                    }}
                    sx={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center"
                    }}
                >
                    <Box sx={styles?.iconBox}>
                        <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                    </Box>
                    <Typography
                        sx={{
                            marginLeft: "5px",
                            fontWeight: 600,
                            color: (theme) => theme.palette.primary.main
                        }}
                    >
                        ADD CREDIT CARD
                    </Typography>
                </Box>
            </Grid>

            {/* Preferred Pricing Section */}
            {formik?.values?.prefferedPrice && (
                <Grid container gap="24px" mt="16px">
                    <Grid item xs={12}>
                        <Typography style={styles.paymetHeading}>PREFERRED PRICING</Typography>
                    </Grid>
                    <Grid item xs={11.8} md={5.8} lg={3.8}>
                        <Box sx={styles.preferredPricing}>
                            <Typography style={styles.defaultTextStyle}>{formik?.values?.prefferedPrice} off all orders</Typography>
                        </Box>
                    </Grid>
                </Grid>
            )}
            {isAddNewCardFormOpen && (
                <AddNewCardForm userID={profileId} setIsAddNewCardFormOpen={setIsAddNewCardFormOpen} handleCardUpdated={getCardList} />
            )}
            <DeleteModal
                open={open}
                buttonTitle="Delete"
                title="Are you sure you want to delete this card?"
                description="This will remove this from the list"
                handleClose={handleDeleteModal}
                handleSubmit={() => {
                    if (cardType === "creditCard") {
                        handleDeleteCard();
                    } else if (cardType === "houseAccount") {
                        deleteHouseAccount();
                    }
                }}
            />
        </Grid>
    );
}

export default Wallet;
