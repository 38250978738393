import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box} from "@mui/material";
import ActionButton from "commonComponent/Apptable/ActionButton";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTimePicker from "commonComponent/Form/Fields/CustomTimePicker";
import CustomeDatePicker from "commonComponent/Form/Fields/CustomeDatePicker";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";
import {ErrorMessage, FieldArray} from "formik";
import React from "react";
import {convertDaysToHHMM, getHHMMTime, getformateDate, getformateDateUS} from "utils/app-dates/dates";
import Moment from "moment";

const TemporaryClosures = (props) => {
    const {formik, readOnly, timezone} = props;
    const {values, setFieldValue} = formik;
    const {temporaryClosures} = values;
    const {cloures} = temporaryClosures;
    const header = [
        {key: "from", label: "From"},
        {key: "startingAt", label: "Starting at"},
        {key: "through", label: "Through"},
        {key: "endingAt", label: "Ending at"},
        {key: "timeClosed", label: "Time closed"},
        {key: "action", label: ""}
    ];

    const mapDateAndTime = (date, time) => {
        const date1 = getformateDate(date, "mm/dd/yyyy");
        const time1 = getHHMMTime(time);
        return new Date(`${date1} ${time1}`);
    };

    const timeClosed = (from, startingAt, through, endingAt) => {
        if (from && startingAt && through && endingAt) {
            const date1 = getformateDate(from, "mm/dd/yyyy");
            const time1 = getHHMMTime(startingAt);
            const date2 = getformateDate(through, "mm/dd/yyyy");
            const time2 = getHHMMTime(endingAt);

            const date001 = new Date(`${date1} ${time1}`);
            const date002 = new Date(`${date2} ${time2}`);

            const daysDiff = Math.abs((date001 - date002) / (1000 * 60 * 60 * 24));
            const result = convertDaysToHHMM(daysDiff);
            return result;
        }
        return "No data";
    };

    const handleActionBtn = (type, arrayHelpers, arrIndex, obj, handleClose) => () => {
        switch (type) {
            case "Duplicate": {
                arrayHelpers.insert(arrIndex + 1, {...obj});
                handleClose();
                break;
            }
            case "Delete": {
                arrayHelpers.remove(arrIndex);
                handleClose();
                break;
            }
            default:
                break;
        }
    };

    const TableCellStyle = () => ({
        p: 0,
        pl: 1,
        pb: 2,
        border: "none",
        verticalAlign: "Top",
        minWidth: 132
    });

    const condition = (i) => (i === 2 || i === 4 ? 3 : 1);

    return (
        <>
            <Grid container>
                <Grid item lg={12} xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {header.map((obj, i) => (
                                        <TableCell key={obj.key} sx={{p: 0, pl: i === 0 ? 0 : condition(i), border: "none"}}>
                                            {obj.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray
                                    name="temporaryClosures.cloures"
                                    render={(arrayHelpers) => (
                                        <>
                                            {cloures?.length ? (
                                                cloures.map((obj, index) => (
                                                    <TableRow key={index}>
                                                        {console.log(obj, "object13215")}
                                                        <TableCell sx={() => ({...TableCellStyle(), pl: 0})}>
                                                            <CustomeDatePicker
                                                                disablePast
                                                                sx={{maxWidth: "220px"}}
                                                                disabled={readOnly}
                                                                valueBasic={obj.from || null}
                                                                setValueBasic={(val) => {
                                                                    setFieldValue(`temporaryClosures.cloures[${index}].from`, val);
                                                                    if (new Date(val) >= new Date(obj.through)) {
                                                                        setFieldValue(`temporaryClosures.cloures[${index}].through`, "");
                                                                        setFieldValue(`temporaryClosures.cloures[${index}].endingAt`, "");
                                                                        setFieldValue(
                                                                            `temporaryClosures.cloures[${index}].startingAt`,
                                                                            val
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                name={`temporaryClosures.cloures[${index}].from`}
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={() => ({...TableCellStyle()})}>
                                                            <CustomNewDateTimePicker
                                                                disabled={readOnly || !obj.from}
                                                                timepicker
                                                                placeholder="hh:mm"
                                                                day={obj.from}
                                                                value={obj.startingAt || null}
                                                                onChange={(val) => {
                                                                    console.log(val, "vall");
                                                                    setFieldValue(
                                                                        `temporaryClosures.cloures[${index}].startingAt`,
                                                                        mapDateAndTime(obj.from, val)
                                                                    );
                                                                }}
                                                            />

                                                            <ErrorMessage
                                                                name={`temporaryClosures.cloures[${index}].startingAt`}
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={() => ({...TableCellStyle(), pl: 3})}>
                                                            <CustomeDatePicker
                                                                disabled={readOnly || !obj.startingAt}
                                                                defaultCalendarMonth={obj.startingAt}
                                                                minDate={new Date(obj.from)}
                                                                valueBasic={obj.through || null}
                                                                setValueBasic={(val) => {
                                                                    setFieldValue(`temporaryClosures.cloures[${index}].through`, val);
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                name={`temporaryClosures.cloures[${index}].through`}
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={() => ({...TableCellStyle()})}>
                                                            <CustomNewDateTimePicker
                                                                disabled={readOnly || !obj.through}
                                                                timepicker
                                                                {...(getformateDate(obj.from) === getformateDate(obj.through) && {
                                                                    minValue: new Date(obj.startingAt)
                                                                })}
                                                                day={obj.through}
                                                                placeholder="hh:mm"
                                                                value={obj.endingAt || null}
                                                                onChange={(val) => {
                                                                    setFieldValue(
                                                                        `temporaryClosures.cloures[${index}].endingAt`,
                                                                        mapDateAndTime(obj.through, val)
                                                                    );
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                name={`temporaryClosures.cloures[${index}].endingAt`}
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={() => ({
                                                                ...TableCellStyle(),
                                                                minWidth: "210px",
                                                                verticalAlign: "center",
                                                                pl: 3.5
                                                            })}
                                                        >
                                                            {timeClosed(obj.from, obj.startingAt, obj.through, obj.endingAt)}
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            sx={() => ({
                                                                ...TableCellStyle(),
                                                                width: "40px",
                                                                minWidth: "40px",
                                                                verticalAlign: "center"
                                                            })}
                                                        >
                                                            {!readOnly && (
                                                                <ActionButton
                                                                    ActionArray={["Duplicate", "Delete"]}
                                                                    handleActionBtn={(type, _, handleClose) => (e) =>
                                                                        handleActionBtn(type, arrayHelpers, index, obj, handleClose)(e)}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={header?.length}>No data available</TableCell>
                                                </TableRow>
                                            )}
                                            {!readOnly && (
                                                <TableRow>
                                                    <Box
                                                        sx={{width: "120px"}}
                                                        onClick={() =>
                                                            arrayHelpers.push({
                                                                from: "",
                                                                startingAt: "",
                                                                through: "",
                                                                endingAt: ""
                                                            })
                                                        }
                                                    >
                                                        <SymbolButton title="Add closure" />
                                                    </Box>
                                                </TableRow>
                                            )}
                                        </>
                                    )}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
};

export default TemporaryClosures;
