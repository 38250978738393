export const dayList = [
    {
        label: "Mon",
        id: "Monday"
    },
    {
        label: "Tue",
        id: "Tuesday"
    },
    {
        label: "Wed",
        id: "Wednesday"
    },
    {
        label: "Thu",
        id: "Thursday"
    },
    {
        label: "Fri",
        id: "Friday"
    },
    {
        label: "Sat",
        id: "Saturday"
    },
    {
        label: "Sun",
        id: "Sunday"
    }
];

export const homeMealHeader = [
    {key: "area", label: "Area", width: "20rem"},
    {key: "zipCode", label: "Zip code", width: "25rem"},
    {key: "deliveryDays", label: "Delivery days", width: "25rem"},
    {key: "action", label: ""}
];
