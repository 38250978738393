// third-party
import {FormattedMessage} from "react-intl";
import OrderIcon from "assets/images/icons/order.svg";
import CustomerIcon from "assets/images/icons/customer.svg";
import MarketIcon from "assets/images/icons/locationPoint.svg";
import LocationIcon from "assets/images/icons/market.svg";
import BrandIcon from "assets/images/icons/brand.svg";
import ProductIcon from "assets/images/icons/productIcon.svg";
import ServicesIcon from "assets/images/icons/servicesIcon.svg";
import MenuIcon from "assets/images/icons/menus.svg";
import DeliveryIcon from "assets/images/icons/delivery.svg";
import ReportIcon from "assets/images/icons/reports.svg";
import SettingIcon from "assets/images/icons/setting.svg";
import PromotionsIcon from "assets/images/icons/promotions.svg";
import ActiveCustomers from "assets/images/icons/activeCustomers.svg";
import ActiveLocation from "assets/images/icons/activeLocation.svg";
import ActiveMarket from "assets/images/icons/activeMarket.svg";
import Reviews from "assets/images/icons/reviews.svg";
import ActivePromotion from "assets/images/icons/activePromotion.svg";
import ActiveBrands from "assets/images/icons/activeBrands.svg";
import ActiveProduct from "assets/images/icons/activeProductIcon.svg";
import ActiveMenu from "assets/images/icons/activeMenu.svg";
import ActiveDelivery from "assets/images/icons/activeDelivery.svg";
import ActiveReviews from "assets/images/icons/activeReviews.svg";
import ActiveOrder from "assets/images/icons/activeOrder.svg";
import ActiveReports from "assets/images/icons/activeReports.svg";
import ActiveSetting from "assets/images/icons/activeSettings.svg";
import ActiveServicesIcon from "assets/images/icons/activeServicesIcon.svg";
// assets
import {
    IconKey,
    IconBug,
    IconUsers,
    IconFileDollar,
    IconHome,
    IconMapPin,
    IconBrandAppgallery,
    IconCurrency,
    IconTruckDelivery,
    IconReport,
    IconSettings,
    IconBuildingStore
} from "@tabler/icons";
import WithPermission from "commonComponent/RBAC/permissionHoc";

// constant
const icons = {
    IconBug,
    IconFileDollar,
    IconKey,
    IconUsers,
    IconMapPin,
    IconHome,
    IconBrandAppgallery,
    IconCurrency,
    IconTruckDelivery,
    IconReport,
    IconSettings,
    IconBuildingStore
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: "pages",
    type: "group",
    children: [
        {
            id: "orders",
            name: "Orders",
            title: <FormattedMessage id="Orders" defaultMessage="Orders" />,
            type: "item",
            url: "/orders?tab=scheduled",
            icon: OrderIcon,
            active: ActiveOrder,
            alt: "OrderIcon",
            breadcrumbs: false,
            counter: true
        },
        {
            id: "customers",
            name: "Customers",
            title: <FormattedMessage id="Customers" defaultMessage="Customers" />,
            type: "item",
            url: "/customers?tab=customer",
            icon: CustomerIcon,
            active: ActiveCustomers,
            alt: "CustomerIcon"
        },
        {
            id: "promotions",
            name: "Promotions",
            title: <FormattedMessage id="Promotions" defaultMessage="Promotions" />,
            type: "item",
            url: "/promotions",
            icon: PromotionsIcon,
            active: ActivePromotion,
            alt: "PromotionsIcon"
        },
        {
            id: "markets",
            name: "Markets",
            title: <FormattedMessage id="Markets" defaultMessage="Markets" />,
            type: "item",
            icon: MarketIcon,
            active: ActiveMarket,
            alt: "MarketIcon",
            url: "/markets"
        },
        {
            id: "locations",
            name: "Locations",
            title: <FormattedMessage id="Locations" defaultMessage="Locations" />,
            type: "item",
            icon: LocationIcon,
            active: ActiveLocation,
            alt: "LocationIcon",
            url: "/locations"
        },
        // {
        //     id: "brands",
        //     name: "Brands",
        //     title: <FormattedMessage id="Brands" defaultMessage="Brands" />,
        //     type: "item",
        //     icon: BrandIcon,
        //     active: ActiveBrands,
        //     alt: "brandIcon",
        //     url: "/brands"

        // },
        {
            id: "brands",
            name: "Brands",
            title: <FormattedMessage id="Brands" defaultMessage="Brands" />,
            type: "item",
            icon: BrandIcon,
            active: ActiveBrands,
            alt: "BrandIcon",
            url: "/brands"
        },
        {
            id: "menus",
            name: "Menu",
            title: <FormattedMessage id="Product" defaultMessage="Product" />,
            type: "item",
            icon: ProductIcon,
            active: ActiveProduct,
            alt: "ProductIcon",
            url: "/menus?tab=menu"
        },
        {
            id: "service",
            name: "Services",
            title: <FormattedMessage id="Services" defaultMessage="Services" />,
            type: "item",
            icon: ServicesIcon,
            active: ActiveServicesIcon,
            alt: "BrandIcon",
            url: "/services"
        },
        {
            id: "delivery",
            name: "Deliveries",
            title: <FormattedMessage id="Delivery" defaultMessage="Delivery" />,
            type: "item",
            url: "/delivery?tab=deliveries",
            icon: DeliveryIcon,
            active: ActiveDelivery,
            alt: "DeliveryIcon",
            breadcrumbs: false
        },
        {
            id: "Reviews",
            name: "Reviews",
            title: <FormattedMessage id="Reviews" defaultMessage="Reviews" />,
            type: "item",
            url: "/Reviews",
            icon: Reviews,
            active: ActiveReviews,
            alt: "Reviews",
            breadcrumbs: false
        },
        {
            id: "Reports",
            name: "Reports",
            title: <FormattedMessage id="Reports" defaultMessage="Reports" />,
            type: "item",
            url: "/Reports?tab=orders",
            icon: ReportIcon,
            active: ActiveReports,
            alt: "ReportIcon",
            breadcrumbs: false
        },
        {
            id: "Settings",
            name: "Account",
            title: <FormattedMessage id="Settings" defaultMessage="Settings" />,
            type: "item",
            url: "/settings?tab=account",
            icon: SettingIcon,
            active: ActiveSetting,
            alt: "SettingIcon",
            breadcrumbs: false
        }
    ]
};

export default pages;
