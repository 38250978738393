import React from "react";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {Box, Grid, ImageList, ImageListItem, Typography} from "@mui/material";
import Loader from "ui-component/Loader";
import {MainHeading, SubContainer} from "../../../locations/createLocations/CreateLocationForm";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import {useNavigate, useParams} from "react-router-dom";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {formatDate} from "commonComponent/Apptable/dateUtils";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {convertDaysToHHMM, convertToLocal, getFormattedTimeinAMPM, getHHMMTime, getformateDate} from "utils/app-dates/dates";
import {format, parseISO, startOfDay} from "date-fns";
import moment from "moment";

const dietaryMap = {
    VEGETARIAN: "V ",
    VEGAN: "VG ",
    "GLUTEN-FREE": "GF ",
    "DAIRY-FREE": "DF ",
    SPICY: "S "
};

const dietarySequence = ["VEGETARIAN", "VEGAN", "GLUTEN-FREE", "DAIRY-FREE", "SPICY"];

const sortAndMapDietary = (arr1) => {
    // Create a copy of arr1 to avoid modifying the original array
    const sortedArr = [...arr1];

    // Sort the array based on the index in dietarySequence
    sortedArr.sort((a, b) => dietarySequence.indexOf(a.dietary) - dietarySequence.indexOf(b.dietary));

    // Map each value using dietaryMap
    const mappedArr = sortedArr.map((obj) => dietaryMap[obj.dietary]);

    // Return the resulting array as in the original format
    return mappedArr;
};

const sortDaysOfWeek = (daysArray) => {
    // Define the order of weekdays
    const weekdaysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    // Custom sorting function based on weekdays order
    const customSort = (a, b) => {
        return weekdaysOrder.indexOf(a) - weekdaysOrder.indexOf(b);
    };

    // Sort the array using the custom sorting function
    const sortedDays = daysArray.sort(customSort);

    return sortedDays;
};

const MenuViewPage = () => {
    const [menuData, setMenudata] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const navigation = useNavigate();
    const prm = useParams();
    const {id, menuid} = prm;

    const TableCellStyle = () => ({
        p: 0,
        pl: 1,
        pb: 2,
        border: "none",
        verticalAlign: "Top",
        color: "#25272B",
        fontWeight: 500,
        fontSize: "14px"
    });

    const getMenuDataById = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.MENU.GETBYID(id));
        const response = await instance.request();

        const newSchema = {
            serviceType: "",
            homeMealMenuType: "",
            homeMealMenuNameInternal: "",
            homeMealMenuNameVisible: "",
            homeMealAvailabilityDate: null,
            homeMealCutoffDate: null,
            homeMealWeekDetails: [],
            homeMealDaysDetails: [],
            selectedMenu: "",
            menuName: "",
            cuisine: "",
            category: [],
            scheduleName: "",
            image: [],
            locations: [],
            frequency: ["Daily"],
            startDate: "",
            endDate: "",
            noEndDate: false,
            repeatEvery: 1,
            recurring: "Day",
            recurringValues: [],
            ends: "never",
            end_value_on: null,
            end_value_after: "",
            menuDetails: {},
            schedule: {}
        };

        if (response.success) {
            const data = response.data;
            newSchema.serviceType = data?.menuList?.service_type;
            newSchema.menuDetails.menuName = data.menuList.menu_name;
            newSchema.menuDetails.cuisine = data.menuList.cusine;
            newSchema.menuDetails.image = data.menuList.menuImage?.map((row) => row.image);
            newSchema.locations = data.menuList.menuScheduleKitchenLocation?.map((el) => el?.kitchenLocation?.name).join(", ");
            if (data.menuList.cut_off_hour) {
                newSchema.menuDetails.cut_off_hour = data.menuList.cut_off_hour;
            }

            newSchema.noEndDate = data.menuList.no_end_date;
            if (data.menuList?.kitchen_location_name?.length > 0) {
                newSchema.schedule.Locations = data.menuList.kitchen_location_name;
            }

            if (data.menuList.start_on_date) {
                newSchema.schedule["Start date"] = {date: data.menuList.start_on_date, isDate: true};
            }
            if (data.menuList.end_on_date) {
                newSchema.schedule["End date"] = {date: data.menuList.end_on_date, isDate: true};
            }

            if (data.menuList?.menuScheduleRepeat) {
                newSchema.schedule["Available on"] =
                    data.menuList.menuScheduleRepeat.length > 0 ? data.menuList.menuScheduleRepeat.map((row) => row.day) : "";
            } else if (data.menuList.schedule_end_on === "Occurance") {
                newSchema.ends = "after";
                newSchema.schedule.Occurance = data.menuList.end_on_occurance;
            } else {
                newSchema.ends = "never";
            }

            if (data?.menuList?.menu_type && data?.menuList?.menu_type !== null) {
                newSchema.homeMealMenuType = data?.menuList?.menu_type;
            }
            if (data?.menuList?.service_type === "Home Meals" && data?.menuList?.menu_name && data?.menuList?.menu_name !== null) {
                newSchema.homeMealMenuNameInternal = data?.menuList?.menu_name;
            }
            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Special" &&
                data?.menuList?.menu_name !== null
            ) {
                newSchema.homeMealMenuNameVisible = data?.menuList?.menu_name_external;
            }
            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Special" &&
                data?.menuList?.cut_off_date_time !== null
            ) {
                newSchema.homeMealCutoffDate = convertToLocal(data?.menuList?.cut_off_date_time);
            }
            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Special" &&
                data?.menuList?.menuHomeMealsSchedule !== null
            ) {
                newSchema.homeMealAvailabilityDate = convertToLocal(data?.menuList?.availability_date);
            }

            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Regular" &&
                data?.menuList?.menuHomeMealsSchedule &&
                data?.menuList?.menuHomeMealsSchedule.length > 0
            ) {
                const formattedWeeks = data?.menuList?.menuHomeMealsSchedule
                    .map(({startDate, endDate}) => {
                        const weekStart = parseISO(startDate);
                        const weekEnd = parseISO(endDate);

                        return {
                            label: `Week of ${format(weekStart, "MMM d")} - ${format(weekEnd, "MMM d")}`,
                            id: `${format(weekStart, "MM-dd")}`,
                            startDate: format(weekStart, "yyyy-MM-dd"),
                            endDate: format(weekEnd, "yyyy-MM-dd")
                        };
                    })
                    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
                newSchema.homeMealWeekDetails = formattedWeeks;
            }
            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Special" &&
                data?.menuList?.menuHomeMealsSchedule &&
                data?.menuList?.menuHomeMealsSchedule.length > 0
            ) {
                newSchema.homeMealDaysDetails = data?.menuList?.menuHomeMealsSchedule.map((item) => startOfDay(new Date(item.startDate)));
            }

            // get menu categories
            const instance1 = NetworkManager(API.MENU.GETCATEGORYBYID(id));
            const response1 = await instance1.request();
            if (response1.success) {
                const menuCategory = response1.data?.menuList;
                if (menuCategory?.length > 0) {
                    const tempObj = [];
                    menuCategory.sort((a, b) => a.order_by - b.order_by);
                    menuCategory.forEach((row) => {
                        const obj = {};
                        obj.label = row.category.category_name;
                        obj.value = row.category.category_name;
                        obj.order_by = row.order_by;
                        obj.description = row.category.description;
                        obj.id = row.category_id;
                        obj.item = [];

                        row.menuCategoryItem.sort((a, b) => a.order_by - b.order_by);

                        row.menuCategoryItem
                            .filter((row) => row.item)
                            .forEach((col) => {
                                const tempItem = {};
                                tempItem.item_id = col.item_id;
                                tempItem.order_by = col.order_by;
                                tempItem.price = col.item.price;
                                tempItem.item_name = col.item.item_name;
                                tempItem.description = col.item.description;
                                tempItem.item_dietary = col.item.itemDietary;
                                obj.item.push(tempItem);
                            });
                        tempObj.push(obj);
                    });
                    newSchema.category = tempObj;
                }
                setMenudata(newSchema);
            }
        } else {
            // dispatch(
            //     openSnackbar({
            //         open: true,
            //         message: response.message,
            //         variant: "alert",
            //         alert: {
            //             color: "error"
            //         },
            //         transition: "Fade",
            //         close: true
            //     })
            // );
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (menuid) {
            getMenuDataById(menuid);
        }
    }, []);

    const mapNamingForID = (type, val) => {
        switch (type) {
            // case "Market": {
            //     const market = LDC.marketList.filter((el) => el.market_id === val);
            //     return market[0]?.market_name;
            // }
            // case "brands": {
            //     const brands = LDC.brandList.filter((el) => el.brand_id === val);
            //     return brands[0]?.brand_name;
            // }
            default: {
                return val;
            }
        }
    };

    return (
        <>
            {/* {loading && <Loader />} */}

            <Grid container>
                {menuData && (
                    <Grid item xs={12}>
                        <Box sx={{mb: 5}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={(t) => ({p: 2, border: "1px solid #dbdbdb", borderRadius: 1, height: "100%"})}
                                        >
                                            <Grid item container spacing={2} justifyContent="space-between" xs={12}>
                                                <Grid item xs={12}>
                                                    <Grid item xs={11}>
                                                        <Typography sx={{fontSize: "1.125rem", fontWeight: 600}} color="#020A11">
                                                            Menu details
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                            <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                Service type
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{wordBreak: "break-all"}}
                                                                fontSize="14px"
                                                                fontWeight={500}
                                                                color="#212121"
                                                            >
                                                                {menuData?.serviceType}
                                                            </Typography>
                                                        </Grid>
                                                        {menuData && menuData?.serviceType === "Home Meals" && (
                                                            <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                                <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                    Menu type
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{wordBreak: "break-all"}}
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                >
                                                                    {menuData?.homeMealMenuType}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {menuData && menuData?.serviceType === "Catering" && (
                                                            <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                                <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                    Menu
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{wordBreak: "break-all"}}
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                >
                                                                    {menuData.menuDetails.menuName}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {menuData && menuData?.serviceType === "Home Meals" && (
                                                            <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                                <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                    Menu name (visible)
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{wordBreak: "break-all"}}
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                >
                                                                    {menuData?.homeMealMenuNameVisible
                                                                        ? menuData?.homeMealMenuNameVisible
                                                                        : "N/A"}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {menuData && menuData?.serviceType === "Home Meals" && (
                                                            <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                                <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                    Menu name (internal)
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{wordBreak: "break-all"}}
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                >
                                                                    {menuData?.homeMealMenuNameInternal}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {menuData && menuData?.serviceType === "Catering" && (
                                                            <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{wordBreak: "break-all"}}
                                                                    fontSize="10px"
                                                                    color="#9e9e9e"
                                                                >
                                                                    Cuisine
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                    sx={{wordBreak: "break-all"}}
                                                                >
                                                                    {ellipsizeText(menuData?.menuDetails?.cuisine, 50)}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {menuData && menuData?.serviceType === "Catering" && (
                                                            <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                                <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                    Cut-off time
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                >
                                                                    {menuData?.menuDetails.cut_off_hour}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                {menuData?.menuDetails?.image?.length > 0 && (
                                                    <Box sx={{width: 384, height: 166, marginLeft: "16px", marginTop: "38px"}}>
                                                        {menuData?.menuDetails?.image?.map((item) => (
                                                            <img
                                                                src={`${item}?w=384&h=166&fit=crop&auto=format`}
                                                                srcSet={`${item}?w=384&h=166&fit=crop&auto=format&dpr=2 2x`}
                                                                alt="menuThumbnail"
                                                                loading="lazy"
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "384px",
                                                                    maxHeight: "166px",
                                                                    width: "384px",
                                                                    height: "auto",
                                                                    objectFit: "cover"
                                                                }}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <SubContainer>
                                            {!!menuData.category.length &&
                                                menuData.category.map((obj, index) => (
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography sx={{fontSize: "1.125rem", fontWeight: 600}} color="#020A11">
                                                                {obj.label}
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                fontSize="14px"
                                                                fontWeight={400}
                                                                fontStyle="italic"
                                                                sx={{wordBreak: "break-all"}}
                                                                color="#212121"
                                                            >
                                                                {obj.description}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            key={index}
                                                            sx={{paddingTop: "18px !important", paddingBottom: "32px !important"}}
                                                        >
                                                            <Grid container spacing={1}>
                                                                {obj.item.length > 0 &&
                                                                    obj.item.map((col, ind) => (
                                                                        <>
                                                                            <Grid key={ind + col.item_name} item xs={11}>
                                                                                <Typography
                                                                                    variant="checkBoxUnSelected"
                                                                                    fontSize="15px"
                                                                                    fontWeight={500}
                                                                                    color="#26272A"
                                                                                >
                                                                                    {col.item_name} &nbsp;&nbsp;{" "}
                                                                                    {sortAndMapDietary(col.item_dietary)?.map(
                                                                                        (item) => item
                                                                                    )}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={1} pr={0.7} sx={{textAlign: "right"}}>
                                                                                <Typography
                                                                                    variant="checkBoxUnSelected"
                                                                                    fontSize="15px"
                                                                                    fontWeight={500}
                                                                                    color="#25272B"
                                                                                >
                                                                                    ${parseFloat(col.price).toFixed(2)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </>
                                                                    ))}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </SubContainer>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <SubContainer title="Schedule" fontWeight={600}>
                                            {menuData && menuData?.serviceType === "Catering" && (
                                                <Grid container spacing={2} mt={0.3}>
                                                    {!!Object.entries(menuData.schedule).length &&
                                                        Object.entries(menuData.schedule).map((val, i) => (
                                                            <Grid key={i} item xs={3}>
                                                                <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                    {val[0]}
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                >
                                                                    {Array.isArray(val[1])
                                                                        ? sortDaysOfWeek(val[1])?.map((el, indx) => (
                                                                              <span key={indx}>{`${indx !== 0 ? ", " : ""}${el}`}</span>
                                                                          ))
                                                                        : val[1]?.isDate
                                                                        ? formatDate(val[1]?.date)
                                                                        : mapNamingForID(val[0], val[1])}
                                                                </Typography>
                                                            </Grid>
                                                        ))}
                                                </Grid>
                                            )}
                                            {menuData && menuData?.serviceType === "Home Meals" && (
                                                <Grid container gap={2}>
                                                    <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                        <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                            Locations
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{wordBreak: "break-all"}}
                                                            fontSize="14px"
                                                            fontWeight={500}
                                                            color="#212121"
                                                        >
                                                            {menuData.locations}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                        <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                            Availability
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{wordBreak: "break-all"}}
                                                            fontSize="14px"
                                                            fontWeight={500}
                                                            color="#212121"
                                                        >
                                                            {menuData?.homeMealAvailabilityDate
                                                                ? moment(menuData.homeMealAvailabilityDate)?.format("MM/DD/YY, hh:mm a")
                                                                : "N/A"}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                        <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                            Cut-off date and time
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{wordBreak: "break-all"}}
                                                            fontSize="14px"
                                                            fontWeight={500}
                                                            color="#212121"
                                                        >
                                                            {menuData?.homeMealCutoffDate
                                                                ? moment(menuData.homeMealCutoffDate)?.format("MM/DD/YY, hh:mm a")
                                                                : "N/A"}
                                                        </Typography>
                                                    </Grid>

                                                    {menuData.serviceType === "Home Meals" && menuData.homeMealMenuType === "Special" && (
                                                        <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                            <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                Delivery days
                                                            </Typography>
                                                            {menuData?.homeMealDaysDetails.length > 0 ? (
                                                                menuData?.homeMealDaysDetails?.map((day, i) => (
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        sx={{wordBreak: "break-all"}}
                                                                        fontSize="14px"
                                                                        fontWeight={500}
                                                                        color="#212121"
                                                                        key={i}
                                                                    >
                                                                        {moment(day).format("ddd, MMM DD")}
                                                                    </Typography>
                                                                ))
                                                            ) : (
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{wordBreak: "break-all"}}
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                >
                                                                    N/A
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    )}
                                                    {menuData.serviceType === "Home Meals" && menuData.homeMealMenuType === "Regular" && (
                                                        <Grid item xs={3} sx={{marginTop: "16px"}}>
                                                            <Typography variant="subtitle2" fontSize="10px" color="#9e9e9e">
                                                                Delivery weeks
                                                            </Typography>
                                                            {menuData?.homeMealWeekDetails.length > 0 ? (
                                                                menuData?.homeMealWeekDetails?.map((item, i) => (
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        sx={{wordBreak: "break-all"}}
                                                                        fontSize="14px"
                                                                        fontWeight={500}
                                                                        color="#212121"
                                                                        key={i}
                                                                    >
                                                                        {item?.label}
                                                                    </Typography>
                                                                ))
                                                            ) : (
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    sx={{wordBreak: "break-all"}}
                                                                    fontSize="14px"
                                                                    fontWeight={500}
                                                                    color="#212121"
                                                                >
                                                                    N/A
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            )}
                                        </SubContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default MenuViewPage;
