import {memo, useMemo, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
// material-ui
import {useTheme} from "@mui/material/styles";
import {Box, Drawer, Stack, useMediaQuery, Button, Typography, List, ListItemButton, ListItemIcon, ListItemText, Link} from "@mui/material";
import "./sidebar.css";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MenuList from "../MenuList";
import MiniDrawerStyled from "./MiniDrawerStyled";
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import {drawerWidth} from "store/constant";
import {useDispatch, useSelector} from "store";
import {openDrawer} from "store/slices/menu";
import LogoSection from "../LogoSection";
import UserSection from "./UserSection";
import AnimateButton from "ui-component/extended/AnimateButton";
import Popper from "commonComponent/Popper";
import useToggleDilog from "hooks/useToggleDilog";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import iconlogoImg from "assets/images/iconLogo.svg";
import {IconChevronLeft, IconChevronRight} from "@tabler/icons";
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({handleDrawerToggle}) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

    const dispatch = useDispatch();
    const {drawerOpen} = useSelector((state) => state.menu);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const {layout, drawerType} = useConfig();
    const navigate = useNavigate();

    const {toggleDilog} = useToggleDilog();

    const handleListItemClick = (event, index, route = "") => {
        setSelectedIndex(index);
        if (route && route !== "") {
            navigate(route);
        }
    };

    const logo = useMemo(
        () => (
            <Box sx={{pl : 1}}>
                <LogoSection />
            </Box>
        ),
        []
    );

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                style={{
                    height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 140px)",
                    // paddingLeft: drawerOpen ? "16px" : 0,
                    paddingRight: drawerOpen ? "16px" : 0,
                    marginTop: matchUpMd ? "57px" : "17px"
                }}
                useBothWheelAxes
            >
                {drawerOpen && WithPermission({module: "Orders", permission: ["create_permission"]}) && (
                    <div style={{display: "flex", width:"100%", paddingLeft : "16px"}}>
                            <Button
                                onClick={() => {
                                    navigate("/orders?tab=scheduled");
                                    toggleDilog("CreateOrder")();
                                }}
                                variant="contained"
                                sx={{color: (theme) => theme.palette.background.paper, width: "100%", height: "35px"}}
                            >
                                <Typography variant="body1" fontWeight={500} sx={{textTransform: "none"}}>
                                    Create order
                                </Typography>
                            </Button>
                    </div>
                )}
                <MenuList />
                {/* {layout === LAYOUT_CONST.VERTICAL_LAYOUT && drawerOpen && (
                    <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
                        <Chip
                            label={process.env.REACT_APP_VERSION}
                            disabled
                            chipcolor="secondary"
                            size="small"
                            sx={{ cursor: 'pointer' }}
                        />
                    </Stack>
                )} */}
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={drawerOpen ? "space-between" : "center"}
                    sx={{
                        pb: 2,
                        bgcolor: "#00000",
                        position: "fixed",
                        bottom: 0,
                        zIndex: 1300,
                        // borderLeft: "4px solid #eee",
                        width: drawerOpen ? `calc(${drawerWidth}px - 16px)` : "72px"
                    }}
                >
                    {drawerOpen && <UserSection drawerOpen={drawerOpen} />}
                    <Box style={{cursor: "pointer", display: "flex", alignItems: "center"}} onClick={handleDrawerToggle}>
                        {drawerOpen ? (
                            <IconChevronLeft width={22} height={22} color="#ffffff" />
                        ) : (
                            <IconChevronRight width={22} height={22} color="#ffffff" />
                        )}
                    </Box>
                </Stack>
            </PerfectScrollbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd, drawerOpen, drawerType]
    );
    return (
        <Box component="nav" sx={{flexShrink: {md: 0}, width: matchUpMd ? drawerWidth : "auto"}} aria-label="mailbox folders">
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                // for mobile view
                <Drawer
                    variant={matchUpMd ? "persistent" : "temporary"}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        "& .MuiDrawer-paper": {
                            mt: 0,
                            zIndex: 1099,
                            width: drawerWidth,
                            borderRight: "none",
                            backgroundColor: "#000000"
                        }
                    }}
                    ModalProps={{keepMounted: true}}
                    color="inherit"
                >
                     <Box sx={{display: "flex", p: 2}}>
                        <div />
                    </Box>
                    {logo}
                    {drawer}
                </Drawer>
            ) : (
                // for desktop view
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    <Box sx={{display: "flex", p: 2}}>
                        <div />
                    </Box>
                    {drawer}
                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
