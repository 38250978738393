import {Box, Grid, InputAdornment, Typography} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, Formik} from "formik";
import React, {useState, useEffect} from "react";
import {enforceFormat, formatToPhone, FormatPhoneNumber, formatFetchedPhoneNumber} from "utils/helperFunctions/helpers";
import networkManager from "network/core/networkManager";
import {API} from "network/core";
import * as yup from "yup";

const defaultInitialValues = {
    customerName: "",
    email: "",
    phone: "",
    company: "",
    companyId: "",
    userId: "",
    customerAddresses: []
};

const CustomerForm = (props) => {
    const {handleClose, children, Parentformik, customerName, customerId} = props;
    const [searchedVlaue, setSearchedValue] = useState("");
    const [companySearch, setCompanySearch] = useState("");
    const [existingCustomer, setExisitingCustomer] = useState([]);
    const [existingCompany, setExistingCompany] = useState([]);
    const [initialValues, setInitialValues] = useState(defaultInitialValues);
    const [showAddCustomer, setShowAddCustomer] = useState(false);

    const getExisitingCustomer = async () => {
        setShowAddCustomer(true);
        const instance = networkManager(API.CUSTOMER.GET);
        const response = await instance.request({}, [`?full_name=${searchedVlaue}`]);
        if (response.success) {
            const data = response.data.customers;
            setExisitingCustomer(data);
        }
    };

    const getExisitingCompany = async () => {
        const instance = networkManager(API.COMPANY.GET);
        const response = await instance.request({}, [`?company_name=${companySearch}`]);
        if (response.success) {
            const data = response.data.companyList;
            setExistingCompany(data);
        }
    };

    const handleSelectCustomerModifier = (obj) => {
        let name = obj?.full_name;
        if (obj?.last_name) {
            name = `${name} ${obj?.last_name}`;
        }
        setInitialValues({
            customerName: name,
            name: name,
            email: obj?.email,
            phone: obj?.phone ?? "",
            company: obj?.company?.company_name ?? "",
            companyId: obj?.company_id ?? "",
            customerAddresses: obj?.customerAddresses,
            userId: obj?.user_id
        });
        setExisitingCustomer([]);
        setExistingCompany([]);
        setSearchedValue("");
    };

    const handleSubmit = (values) => {
        Parentformik.setFieldValue("headerData.customer", values);

        const defaultAddress = values.customerAddresses?.filter((a) => a.is_default || a?.is_active) ?? null;
        const selectedAddress =
            defaultAddress?.length === 1 ? defaultAddress : values.customerAddresses?.filter((a) => a.is_default || a?.is_active) ?? null;
        if (defaultAddress.length > 0) {
            Parentformik.setFieldValue("headerData.deliveryTo", selectedAddress[0]?.delivery_address);
            Parentformik.setFieldValue("headerData.longitude", selectedAddress[0]?.longitude);
            Parentformik.setFieldValue("headerData.latitude", selectedAddress[0]?.latitude);
            Parentformik.setFieldValue("name", selectedAddress[0]?.contact_name);
            Parentformik.setFieldValue("phone", selectedAddress[0]?.contact);
            Parentformik.setFieldValue("company", selectedAddress[0]?.location_name);
            Parentformik.setFieldValue("apartment", selectedAddress[0]?.apartment_name);

            Parentformik.setFieldValue("deliveryInstruction", selectedAddress[0]?.instruction ?? "");
        } else {
            Parentformik.setFieldValue("headerData.deliveryTo", "");
            Parentformik.setFieldValue("headerData.longitude", "");
            Parentformik.setFieldValue("headerData.latitude", "");
            Parentformik.setFieldValue("name", values?.customerName);
            Parentformik.setFieldValue("phone", values?.phone);
            Parentformik.setFieldValue("company", values?.company);
            Parentformik.setFieldValue("apartment", selectedAddress[0]?.apartment_name);
            Parentformik.setFieldValue("deliveryInstruction", selectedAddress[0]?.instruction ?? "");
        }
        Parentformik.setTouched({"headerData.deliveryTo": true});
        Parentformik.setTouched({"headerData.customer": true});

        Parentformik.setFieldValue("discountOrFee.description", ""); // Clear the description field
        Parentformik.setFieldValue("discount_applied_coupon_id", ""); // Clear the discount ID
        Parentformik.setFieldValue("discountOrFee.value", 0); // Clear the discount value field
       
        Parentformik.setFieldTouched("discountOrFee.description", true); // Mark the field as touched
        handleClose();
    };

    useEffect(() => {
        if (searchedVlaue) {
            getExisitingCustomer();
        } else {
            setExisitingCustomer([]);
            setShowAddCustomer(false);
        }
    }, [searchedVlaue]);

    useEffect(() => {
        if (Parentformik?.values?.headerData?.customer) {
            const {customer} = Parentformik?.values?.headerData;

            setInitialValues({
                customerName: customer.name,
                name: customer.name,
                email: customer?.email,
                phone: customer?.phone ?? "",
                company: customer?.company ?? "",
                companyId: customer?.company_id ?? "",
                customerAddresses: customer?.customerAddresses,
                userId: customer?.userId
            });
        }
    }, [Parentformik]);

    useEffect(() => {
        if (companySearch) {
            getExisitingCompany();
        } else {
            setExistingCompany([]);
        }
    }, [companySearch]);

    const getCustomerProfileData = async (id) => {
        const instance = networkManager(API.CUSTOMER.GETCUSTOMERDATA(`${id}`));

        const response = await instance.request();

        if (response.success) {
            handleSelectCustomerModifier(response?.data);
        }
    };

    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    return (
        <>
            <Box
                p={2}
                sx={{maxWidth: 400}}
                onClick={(e) => {
                    if (e.target.id !== "customer_id") {
                        setSearchedValue("");
                    }
                }}
            >
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={yup.object({
                        customerName: yup.string().required("Customer is required").nullable(),
                        email: yup.string().required("Email address is required").email("Please enter valid email").trim().nullable(),
                        company: yup.string().nullable().trim()
                    })}
                    onSubmit={(val, {setSubmitting}) => handleSubmit(val)}
                >
                    {(formik) => (
                        <>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    {/* <CustomTextField  fullWidth label="Customer name" {...formik.getFieldProps("customerName")} /> */}
                                    <CustomTextField
                                        fullWidth
                                        label="Customer name"
                                        sx={{minWidth: "350px"}}
                                        id="customer_id"
                                        onKeyDown={stopPropagationForTab}
                                        onClick={(e) => {
                                            setSearchedValue(e.target.value?.trim());
                                        }}
                                        value={formik.values.customerName}
                                        {...formik.getFieldProps("customerName")}
                                        onChange={(e) => {
                                            setSearchedValue(e.target.value?.trim());
                                            formik.setFieldValue("customerName", e.target.value);
                                        }}
                                    />
                                    {(!!existingCustomer?.length || showAddCustomer) && (
                                        <Box
                                            sx={{
                                                p: 1,
                                                boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                                borderRadius: 0.5,
                                                maxWidth: "350px",
                                                position: "absolute",
                                                width: "100%",
                                                bgcolor: "#fff",
                                                maxHeight: "200px",
                                                overflow: "auto",
                                                zIndex: 1380
                                            }}
                                        >
                                            {existingCustomer?.map((obj, i) => (
                                                <Box
                                                    key={i}
                                                    sx={{p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}}
                                                    onClick={() => {
                                                        handleSelectCustomerModifier(obj);
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body3" fontSize="0.875rem" fontWeight={600}>
                                                                {obj?.full_name} {obj?.last_name ?? ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lineHeight="1.3rem">
                                                            <Typography variant="body3" fontSize="0.7rem" fontWeight={400}>
                                                                {obj?.company?.company_name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} lineHeight="1.3rem">
                                                            <Typography variant="body3" fontSize="0.7rem" fontWeight={400}>
                                                                {obj?.email}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} lineHeight="1.3rem">
                                                            <Typography variant="body3" fontSize="0.7rem" fontWeight={400}>
                                                                {obj?.phone ? FormatPhoneNumber(obj?.phone) : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ))}
                                            {children}
                                        </Box>
                                    )}
                                    <ErrorMessage name="customerName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    {searchedVlaue !== "" && existingCustomer.length === 0 ? (
                                        <CustomErrorMessage errorMessage="No customer found" />
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                                <Grid item xs={12} sx={{pt: "8px !important"}}>
                                    <Grid container>
                                        <Grid item xs={12} lineHeight="1.3rem">
                                            <Typography variant="body3" fontSize="0.875rem" fontWeight={700}>
                                                {formik.values?.name ?? ""}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lineHeight="1.3rem">
                                            <Typography variant="body3" fontSize="0.75rem" fontWeight={400}>
                                                {formik.values?.company ?? ""} {formik.values?.company && " | "}{" "}
                                                {formik.values?.email ?? ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <CustomTextField fullWidth disabled onKeyDown={stopPropagationForTab} label="Email address" {...formik.getFieldProps("email")} />
                                    <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} /> */}
                                </Grid>

                                <Grid item xs={12}>
                                    {/* <CustomTextField
                                        fullWidth
                                        label="Phone number"
                                        disabled
                                        onKeyDown={enforceFormat}
                                        onKeyUp={formatToPhone}
                                        inputProps={{maxLength: 16}}
                                        {...(formik.values.phone && {
                                            InputProps: {
                                                startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                            }
                                        })}
                                        {...formik.getFieldProps("phone")}
                                    />
                                    <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} /> */}
                                </Grid>

                                {/* <Grid item xs={12}>

                                    <CustomTextField
                                            
                                            fullWidth
                                            name="company"
                                            label="Company (optional)"
                                            placeholder=""
                                            disabled
                                            onKeyDown={stopPropagationForTab}
                                            value={formik.values.company}
                                            {...formik.getFieldProps("company")}
                                            onChange={(e) => {
                                                setCompanySearch(e.target.value);
                                                formik.setFieldValue("company", e.target.value);
                                            }}
                                           
                                        />
                                        {!!existingCompany?.length && (
                                            <Box
                                                sx={{
                                                    p: 1,
                                                    boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                                    borderRadius: 0.5,
                                                    position: "absolute",
                                                    width: "100%",
                                                    bgcolor: "#fff",
                                                    maxHeight: "200px",
                                                    overflow: "auto",
                                                    zIndex: 1380
                                                }}
                                            >
                                                {existingCompany?.map((obj, i) => (
                                                    <Box
                                                        key={i}
                                                        sx={{p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}}
                                                        onClick={() => {
                                                            formik.setFieldValue("company", obj.company_name);
                                                            formik.setFieldValue("companyId", obj.company_id);
                                                            setExistingCompany([])
                                                        }}
                                                    >
                                                        <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                                            {obj?.company_name}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}

                                </Grid> */}
                            </Grid>

                            <Box mt={1}>
                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: "#f0475c"}),
                                            onClick: handleClose
                                        },
                                        {
                                            btnTitle: formik.isSubmitting ? "Sending" : "Save",
                                            disabled: formik.isSubmitting,
                                            variant: "contained",
                                            onClick: () => {
                                                formik.handleSubmit();

                                                setTimeout(() => {
                                                    formik.resetForm();
                                                }, 25000);
                                            },
                                            sx: (t) => ({color: t.palette.background.paper})
                                        }
                                    ]}
                                />
                            </Box>
                        </>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CustomerForm;
