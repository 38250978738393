import {useEffect, useMemo, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";

// material-ui
import {styled, useTheme} from "@mui/material/styles";
import {AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery} from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";
import HorizontalBar from "./HorizontalBar";
import Breadcrumbs from "ui-component/extended/Breadcrumbs";

import navigation from "menu-items";
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import {drawerWidth} from "store/constant";
import {openDrawer} from "store/slices/menu";
import {useDispatch, useSelector} from "store";
import Logo from "assets/images/BitetimeLogoNew.svg";
// assets
import {IconChevronRight} from "@tabler/icons";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import CreateOrder from "views/pages/privatePages/orders/CreateOrder/CreateOrder";
import CreateTemplate from "views/pages/privatePages/Menus/Tabs/Templates/CreateTemplate/CreateTemplate";

// styles
const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})(({theme, open, layout}) => ({
    ...theme.typography.mainContent,
    backgroundColor: theme.palette.background.default,
    position: "relative",
    marginRight: "0px",
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // transition: theme.transitions.create("margin", {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.shorter + 200
        // }),
        [theme.breakpoints.up("md")]: {
            marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : "20px",
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "80px",
            marginLeft: "20px",
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "10px",
            width: `calc(100% - ${drawerWidth}px)`
        }
    }),
    ...(open && {
        // transition: theme.transitions.create("margin", {
        //     easing: theme.transitions.easing.easeOut,
        //     duration: theme.transitions.duration.shorter + 200
        // }),
        marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up("md")]: {
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 18
        },
        [theme.breakpoints.down("md")]: {
            marginLeft: "20px",
            marginTop: 18
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: "10px",
            marginTop: 18
        }
    }),
    padding: 0
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const location = useLocation();

    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const dispatch = useDispatch();
    const {drawerOpen} = useSelector((state) => state.menu);
    const {drawerType, container, layout} = useConfig();

    const [open, setOpen] = useState(drawerType === LAYOUT_CONST.DEFAULT_DRAWER && drawerOpen);

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            setOpen(true);
            dispatch(openDrawer(true));
        } else {
            setOpen(false);
            dispatch(openDrawer(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerType]);

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer(!drawerOpen));
    };

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            setOpen(true);
            dispatch(openDrawer(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;
    const mainStyle = {
        p: condition ? "10px" : "16px",
        [theme.breakpoints.up("md")]: {
            paddingLeft: "18px"
        }
    };
    const mobileStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "80px"
    };
    const header = useMemo(
        () => (
            <Toolbar sx={isMobile ? mobileStyle : mainStyle}>
                {/* {isMobile && <img src={Logo} alt="" />} */}
                <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layout, matchDownMd, open, isMobile]
    );

    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline />
            {/* header */}
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    bgcolor: isMobile ? "#fff" : "transparent",
                    transition: drawerOpen ? theme.transitions.create("width") : "none",
                    width: isMobile ? "100%" : "auto",
                    right: "auto",
                    left: 0,
                    mt: isMobile ? 0 : 1.5
                }}
            >
                {location?.pathname !== "/quote/detail" && location?.pathname !== "/print" && header}
            </AppBar>

            {/* horizontal menu-list bar */}
            {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

            {/* drawer */}
            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) &&
                location?.pathname !== "/quote/detail" &&
                location?.pathname !== "/print" && <Sidebar open={open} handleDrawerToggle={handleDrawerToggle} />}

            {/* main content */}
            <Main theme={theme} open={drawerOpen} layout={layout}>
                <Container maxWidth={container ? "lg" : false} sx={{overflow: "hidden"}}>
                    <CreateOrder />
                    <CreateTemplate />
                    {/* breadcrumb */}
                    {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                    <Outlet />
                </Container>
            </Main>
        </Box>
    );
};

export default MainLayout;
