import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {Stack, Typography, Grid, Toolbar, Drawer, Accordion, AccordionSummary, AccordionDetails, LinearProgress} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useToggleDilog from "hooks/useToggleDilog";
import {ErrorMessage, Form, Formik} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {NetworkManager, API} from "network/core";
import {dispatch} from "store/index";
import {generateUrl} from "utils/helperFunctions/helpers";
import SearchSection from "commonComponent/SearchSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import {Link, useLocation, useSearchParams, useHistory} from "react-router-dom";
import moment from "moment";
import PastOrdersAccordion from "./pastOrder/pastOrder";
import TemplateOrder from "./templateOrder/templateOrder";
import TickIcon from "assets/images/icons/tick.svg";
import useOrderDialog from "hooks/useOrderDialog";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import OrderName from "../customOrderName/OrderName";
import {openSnackbar} from "store/slices/snackbar";
import useCreateOrderController from "../createOrder.controller";

const Schema = {
    selectedBrand: "",
    selectedMenu: "",
    kitchenLocationId: "",
    categories: "",
    items: ""
};

function SideSection(props) {
    const {
        window,
        orderedMenuId,
        setOrderedMenuId,
        orderedBrandId,
        initialValues,
        mobileOpen,
        drawerWidth,
        handleDrawerToggle,
        orderId,
        navigate,
        formik,
        handleReset,
        Tab,
        orderSaved,
        closeModal,
        setCloseModal,
        ordereredId,
        waitForItem,
        getTemplateDetailById,
        orderName,
        cartName,
        setCartName,
        setOrederPastData,
    } = props;
 

    const {userId} = formik.values.headerData.customer;
    const [brandList, setBrandList] = React.useState([]);
    const [menuList, setMenuList] = React.useState([]);
    const [brandId, setBrandId] = React.useState(null);
    const [menuData, setMenuData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedMenuId, setMenuId] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openCancelModal, setCancelModal] = useState(false);
    const [openBackModal, setBackModal] = useState(false);
    const [dummyMenuId, setDummyMenu] = useState("");
    const [search, setSearch] = useState("");
    const [filteredMenuData, setFilteredMenu] = useState(null);
    const {handlePastOrder, orderIngFromPast, pastOrderId} = useOrderDialog();
    const [refresh, setRefresh] = useState(0);
    const [searchParams] = useSearchParams();
    const [orderIdParam, setOrderIdParam] = useState(searchParams.get("orderId"));
    const [reOrder, setreOrder] = useState(searchParams.get("reOrder"));


    useEffect(() => {
        if (orderedBrandId !== "") {
            setBrandId(orderedBrandId);
        }
    }, [orderedBrandId]);

    useEffect(() => {
        if (orderedMenuId !== "") {
            setMenuId(orderedMenuId);
        } else {
            setMenuId(null);
        }
    }, [orderedMenuId]);

    const handleSelectKitchenListDefault = async (isEditOrder = false) => {
        const filteredKitchenList = [];
        console.log("default data comes here", brandList, "comeess");
        const uniqueLocations = {};
        brandList.forEach((row) => {
            row?.kitchenList?.forEach((item) => {
                if (!uniqueLocations[item.kitchen_location_id]) {
                    console.log(item, "item");
                    filteredKitchenList.push(item);
                    uniqueLocations[item.kitchen_location_id] = item.kitchen_location_id;
                }
            });
        });
        formik.setFieldValue("kitchenList", filteredKitchenList);
    };

    const handleSelectKitchenList = async (brandId, isEditOrder = false) => {
        let filteredBrandList = [];
        if (brandId) {
            filteredBrandList = brandList.filter((row) => row.id === brandId);
        } else {
            // if none is selected from brand dropdown
            const filteredKitchenList = [];
            const uniqueLocations = {};
            brandList.forEach((row) => {
                row?.kitchenList?.forEach((item) => {
                    if (!uniqueLocations[item.kitchen_location_id]) {
                        filteredKitchenList.push(item);
                        uniqueLocations[item.kitchen_location_id] = item.kitchen_location_id;
                    }
                });
            });
            filteredBrandList = [{label: null, kitchenList: filteredKitchenList}];
        }
        console.log(filteredBrandList?.[0]?.kitchenList, "new kitchen list");

        formik.setFieldValue("brandName", filteredBrandList?.[0]?.label);
        formik.setFieldValue("kitchenList", filteredBrandList?.[0]?.kitchenList ?? []);

        if (!isEditOrder) {
            // if not prefilled while getting order detail in case of editing order
            // since it is opened as edit order initially all data will be prefilled
            formik.setFieldValue("marketIDForDiscount", filteredBrandList?.[0]?.kitchenList?.[0]?.market?.market_id ?? "");
            formik.setFieldValue("marketNameForDiscount", filteredBrandList?.[0]?.kitchenList?.[0]?.market?.market_name ?? "");
            formik.setFieldValue("kitchenLocationId", filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_location_id ?? "");
            formik.setFieldValue("selectedKitchen", filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_name ?? "");
            formik.setFieldValue("kitchenLatitude", filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_latitude ?? "");
            formik.setFieldValue("kitchenLongitude", filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_longitude ?? "");
            formik.setFieldValue("kitchenMinimumServiceFee", filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_minimum_service_fee ?? 0);
            formik.setFieldValue("kitchen_location_name", filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_name);

            const serviceFee = {
                unitType: "percent",
                value: JSON.stringify(filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_service_fee ?? 0)
            };
            const salesTax = {value: JSON.stringify(filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_sales_tax_rate ?? 0)};
            const setuptime = filteredBrandList?.[0]?.kitchenList?.[0]?.kitchen_setup_time ?? 0;
            // formik.setFieldValue("serviceFees", serviceFee);
            formik.setFieldValue("salesTax", salesTax);
            formik.setFieldValue("setup_time", setuptime?.toString());
        }
    };

    const getBrandList = async () => {
        // while changing brand kitchen location should be emptied
        const instance = NetworkManager(API.BRAND.GETBRANDWITHADDRESSFILTER);
        const params = {
            orderBy: "ASC"
        };
        if (formik.values.headerData.deliveryTo !== "") {
            params.search = formik.values.headerData.deliveryTo.split(",").slice(0, -1).join(",") || formik.values.headerData.deliveryTo;
            params.latitude = formik.values.headerData.latitude;
            params.longitude = formik.values.headerData.longitude;
        }
        // if (formik.values.headerData.when !== "") {
        //     params.deliveryDate = formik.values.headerData.when
        // }
        const response = await instance.request(params);
        if (response.success) {
            const states = response.data
                ?.sort((a, b) => a?.brand_name?.localeCompare(b?.brand_name))
                ?.map((state) => ({
                    label: state.brand_name,
                    value: state.brand_id,
                    id: state.brand_id,
                    kitchenList: state?.kitchenlocationdetails
                }));
            setBrandList(states);
            if (formik.values.selectedBrand !== "") {
                const selectedBrandPresentInList = response.data?.map((row) => row.brand_id === formik.values.selectedBrand);
                if (selectedBrandPresentInList.length === 0) {
                    formik.setFieldValue("itemDetail", []);
                    formik.setFieldValue("selectedBrand", "");
                    formik.setFieldValue("brandName", "");
                    formik.setFieldValue("marketIDForDiscount", "");
                    formik.setFieldValue("marketNameForDiscount", "");
                    formik.setFieldValue("selectedMenu", "");
                    formik.setFieldValue("kitchenLocationId", "");
                    formik.setFieldValue("selectedKitchen", "");
                    formik.setFieldValue("kitchenLatitude", "");

                    formik.setFieldValue("kitchenLongitude", "");
                    formik.setFieldValue("kitchenMinimumServiceFee", "");
                    formik.setFieldValue("kitchenList", []);
                    formik.setFieldValue("categories", {});
                    formik.setFieldValue("items", []);
                    setMenuData(null);
                    setFilteredMenu(null);
                    setSearch("");
                    setMenuList([]);
                    setBrandId("");
                    setMenuId(null);
                }
            }
        }
    };

    const leaveByTimeCalculation = (driveTime, setupTime) => {
        if (formik.values.headerData.when !== "") {
            if (driveTime !== "" || setupTime !== "") {
                const duration =
                    ((driveTime !== "" ? parseFloat(driveTime) : 0) + (setupTime !== "" ? parseFloat(setupTime) : 0)) * 60 * 1000;
                const endTime = moment(formik.values.headerData.when);
                const startTime = endTime.subtract(duration, "ms").format("MM/DD/YYYY, h:mm A");

                formik.setFieldValue("leave_by", new Date(startTime));
            }
        }
    };

    const calculateDriveTime = async () => {
        const {values} = formik;
        const origin = `${values.kitchenLatitude},${values.kitchenLongitude}`;
        const destination = `${values.headerData.latitude},${values.headerData.longitude}`;
        const instance = NetworkManager(API.ORDER.DRIVE);
        const params = {
            origin: origin,
            destination: destination
        };
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);
        if (response.success) {
            const driveTimeInSeconds = response.data.duration.value;
            let driveTimeInMinutes = Math.ceil(driveTimeInSeconds / 60);
            // if (values.drive_time ===""){

            // }
            driveTimeInMinutes = Math.ceil(driveTimeInMinutes / 5) * 5; // round of to next multiple of 5

            formik.setFieldValue("drive_time", JSON.stringify(driveTimeInMinutes));
            formik.setFieldValue("location_distance", response.data.distance.value);
            leaveByTimeCalculation(driveTimeInMinutes, formik.values.setup_time);
        }
    };

    useEffect(() => {
        const {values} = formik;
        // if customer delivery address and  kitchen location data are filled
        if (
            (values.headerData.latitude !== "" &&
                values.headerData.longitude !== "" &&
                values.kitchenLongitude !== "" &&
                values.kitchenLatitude) ||
            values?.addressChanged
        ) {
            calculateDriveTime();
        } else {
            formik.setFieldValue("drive_time", "");
            formik.setFieldValue("leave_by", null);
        }
    }, [
        formik.values.headerData.latitude,
        formik.values.headerData.longitude,
        formik.values.kitchenLongitude,
        formik.values.kitchenLatitude
    ]);

    useEffect(() => {
        if (brandId !== "" && brandList.length > 0 && formik.values.kitchenLocationId !== "") {
            // if brandId is already selected like while editing it gets prefilled
            // in that case kitchen list should be updated
            handleSelectKitchenList(brandId, true);
        } else if (orderIngFromPast === "templateOrder" && brandId !== "" && brandList.length > 0) {
            // if template duplicated to prefil the order
            handleSelectKitchenList(brandId);
            handlePastOrder(null);
        }
    }, [brandId, brandList]);

    useEffect(() => {
        if (formik.values.headerData.deliveryTo !== "" && !brandId && formik.values.kitchenLocationId === "") {
            handleSelectKitchenListDefault();
        }
    }, [formik.values.headerData.deliveryTo, brandList]);

    const getMenuList = async () => {
        const instance = NetworkManager(API.MENU.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "menu_name",
            orderBy: "asc"
        };
        if (brandId) {
            params.brand_id = brandId;
        }
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const tempList = [];
            response.data.menuList
                ?.filter((a) => a.menu.is_active === "true" || a.menu.is_active === "false")
                .forEach((row) => {
                    const tempObj = {};
                    tempObj.brand_id = row.brand_id;
                    tempObj.brandName = row.brand.brand_name;
                    tempObj.createdAt = row.createdAt;
                    tempObj.menu_name = row.menu.menu_name;
                    tempObj.menu_id = row.menu_id;
                    tempObj.is_active = row.menu.is_active;
                    tempObj.abbreviation = row.brand.abbreviation;
                    tempObj.brandServices = row.brand.brandServices;

                    tempObj.label = row.menu.menu_name;
                    tempObj.value = row.menu_id;
                    tempObj.id = row.menu_id;
                    tempList.push(tempObj);
                });
            setMenuList(tempList);
            if (orderedMenuId === "") {
                if (tempList.length > 0) {
                    setMenuId(tempList[0].id);
                    formik.setFieldValue("selectedMenu", tempList[0].id);
                }
            } else if (orderedMenuId !== "") {
                setOrderedMenuId("");
            }
        }
    };

    const getCustomItemList = async () => {
        const instance = NetworkManager(API.ORDER.CUSTOMITEMLIST);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "item_name",
            orderBy: "asc"
        };
        if (brandId) {
            params.brand_id = brandId;
        }
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        const customItemList = response.data?.item;
        const customItemObj = {
            label: "Custom",
            value: "Custom",
            description: "",
            id: "customItem",
            item: []
        };
        customItemList.forEach((col) => {
            const tempItem = {};
            tempItem.item_id = col.item_id;
            tempItem.order_by = col?.order_by ?? 0;
            tempItem.price = col?.price ?? 0;
            tempItem.item_name = col?.item_name ?? "";
            tempItem.description = col?.description ?? "";
            tempItem.item_dietary = null;
            tempItem.item_image = null;
            tempItem.item_review = null;
            tempItem.serving_size = col?.serving_size ?? 0;
            customItemObj.item.push(tempItem);
        });
        return customItemObj;
    };

    const getMenuData = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.MENU.GETBYID(id));
        const response = await instance.request();

        const newSchema = {
            menuName: "",
            cuisine: "",
            category: [],
            kitchenDetail: {},
            menuDetails: {}
        };

        if (response.success) {
            const data = response.data;
            newSchema.menuDetails.menuName = data.menuList.menu_name;
            // newSchema.menuDetails.brandLogo = data.menuList.brandDetail?.[0]?.brand_logo ?? null
            // newSchema.menuDetails.brandName = data.menuList.brandDetail?.[0]?.brand_name ?? null
            newSchema.menuDetails.cuisine = data.menuList.cusine;

            // get menu categories
            const instance1 = NetworkManager(API.MENU.GETCATEGORYBYID(id));
            const response1 = await instance1.request();
            if (response1.success) {
                const menuCategories = {};
                const menuCategory = response1.data?.menuList;
                menuCategory?.sort((a, b) => a.order_by - b.order_by);
                menuCategory?.forEach((row) => {
                    menuCategories[`${row?.menu_category_id}`] = row?.category?.category_name;
                });

                formik.setFieldValue("categories", menuCategories);
                if (menuCategory.length > 0) {
                    const tempObj = [];
                    const arr = [];
                    menuCategory.sort((a, b) => a.order_by - b.order_by);

                    menuCategory.forEach((row) => {
                        const obj = {};
                        obj.label = row.category.category_name;
                        obj.value = row.category.category_name;
                        obj.description = row.category.description;
                        obj.id = row.category_id;
                        obj.item = [];
                        row.menuCategoryItem.sort((a, b) => a.order_by - b.order_by);
                        row.menuCategoryItem
                            .filter((row) => row.item)
                            .forEach((col) => {
                                const tempItem = {};
                                tempItem.item_id = col.item_id;
                                tempItem.order_by = col.order_by;
                                tempItem.price = col.item.price;
                                tempItem.item_name = col.item.item_name;
                                tempItem.description = col.item.description;
                                tempItem.item_dietary = col.item.itemDietary;
                                tempItem.item_image = col.item.itemImage;
                                tempItem.item_review = col.item.item_review;
                                tempItem.serving_size = col.item.serving_size;
                                obj.item.push(tempItem);
                            });
                        tempObj.push(obj);

                        const dummyObj = {};
                        dummyObj.value = row.category.category_name;
                        dummyObj.label = row.category.category_name;
                        dummyObj.comp = () => <div />;
                        dummyObj.id = row.category_id;
                        arr.push(dummyObj);
                    });
                    // get custom item list
                    const customItem = await getCustomItemList();
                    tempObj.push(customItem);

                    newSchema.category = tempObj;
                }
                setMenuData(newSchema);
                setFilteredMenu(newSchema);
                setLoading(false);
            }
        }
    };

    const handleToggle = (id) => {
        if (!waitForItem) {
            dispatch({
                type: "UpdateItem",
                payload: {
                    id: id
                }
            });
        }
    };

    useEffect(() => {
        // if edit screen is openend so initially will check for address and date
        // if (editScreen) {
        //     if (formik.values.headerData.deliveryTo !== "" && formik.values.headerData.when !== "") {
        //         getBrandList()
        //     }
        // }
        // else {
        //     // if create screen is opened
        //     getBrandList()
        // }
        getBrandList();
    }, [formik.values.headerData.deliveryTo]);
    const COC = useCreateOrderController();

    const getOrderHistory = async () => {
        const instance = NetworkManager(API.ORDER.ORDERHISTORY(ordereredId));
        const response = await instance.request();
        if (response.success) {
            formik.setFieldValue("orderHistory", response.data);
            formik.values.orderHistory = response.data;
            setOrederPastData(response.data);

            const orignalValues = {...formik?.values};
            orignalValues.orderHistory = response.data;
            COC.handleInitialValues(orignalValues);
        }
    };

    useEffect(() => {
        if (ordereredId) {
            getOrderHistory();
        }
    }, [ordereredId]);

    function filterCategoriesAndItems(menuData, itemName) {
        return menuData.filter((category) => {
            const filteredCategory = {...category}; // Clone the category to avoid modifying the original data
            filteredCategory.item = category.item.filter((item) => item.item_name.toLowerCase().includes(itemName.toLowerCase()));
            return filteredCategory.item.length > 0 || category.label.toLowerCase().includes(itemName.toLowerCase());
        });
    }

    useEffect(() => {
        if (search !== "") {
            const tempList = JSON.parse(JSON.stringify(menuData)) || {};
            tempList.category = tempList?.category?.filter((category) => {
                return (
                    category.item &&
                    category.item.some((item) => {
                        return item && item.item_name.toLowerCase().includes(search.toLowerCase());
                    })
                );
            });
            tempList?.category?.forEach((category) => {
                category.item = category.item.filter((item) => {
                    return item.item_name.toLowerCase().includes(search.toLowerCase());
                });
            });
            setFilteredMenu(tempList);
        } else {
            setFilteredMenu(menuData);
        }
    }, [search]);

    useEffect(() => {
        if (brandId) {
            getMenuList();
        }
    }, [brandId]);

    useEffect(() => {
        if (selectedMenuId) {
            getMenuData(selectedMenuId);
        }
    }, [selectedMenuId]);

    useEffect(() => {
        setMenuData(null);
        setFilteredMenu(null);
        setSearch("");
    }, [formik.values.selectedBrand]);

    const {toggleDilog} = useToggleDilog();

    const handleDeleteOrder = async () => {
        const instance = NetworkManager(API.ORDER.DELETE(orderId.order_id));
        const response = await instance.request();
        // console.log("response : ", response);
        if (response.success) {
            navigate(`/orders?tab=${Tab}`);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleBackModal = () => {
        setBackModal((pre) => !pre);
        localStorage.setItem("quote", "");
    };

    const handleBackOrder = () => {
        toggleDilog(false)();
        formik.resetForm({values: initialValues});
        handleReset();
        dispatch({
            type: "RemoveItem"
        });
        dispatch({
            type: "ORDER"
        });
        navigate(`/orders?tab=${Tab}`);
        localStorage.setItem("quote", "");
        if (!orderSaved) {
            handleDeleteOrder();
        }
    };

    const handleSubmitBackOrder = () => {
        toggleDilog(false)();
        formik.resetForm({values: initialValues});
        handleReset();
        dispatch({
            type: "RemoveItem"
        });
        dispatch({
            type: "ORDER"
        });
        navigate(`/orders?tab=draft`);
        localStorage.setItem("quote", "");
        if (!orderSaved) {
            handleDeleteOrder();
        }
    };

    const handleBackOrderToDraft = () => {
        console.log("State back to order");
        toggleDilog(false)();
        formik.resetForm({values: initialValues});
        handleReset();
        dispatch({
            type: "RemoveItem"
        });
        dispatch({
            type: "ORDER"
        });
        navigate(`/orders?tab=draft`);
        localStorage.setItem("quote", "");
        if (!orderSaved) {
            handleDeleteOrder();
        }
    };

    useEffect(() => {
        if (closeModal) {
            handleBackOrder();
            setCloseModal(false);
        }
    }, [closeModal]);

    const handleExitModal = () => {
        console.log("State handleExit");
        setBackModal((pre) => !pre);
        handleDeleteOrder();
        handleBackOrderToDraft();
    };

    const handleBack = () => {
        formik.validateForm().then((res) => {
            //  if no field has validation error
            const touchedFieldsCount = Object.keys(formik.touched).filter((fieldName) => formik.touched[fieldName]).length;
            // console.log("touchedFieldsCount : ", Object.keys(formik.touched));
            if (touchedFieldsCount > 0 || Object.keys(formik.touched).length > 0) {
                // ask for confirmation
                handleBackModal();
            } else {
                handleBackOrder();
            }
        });
    };

    React.useEffect(() => {
        if (pastOrderId) {
            if (orderIngFromPast === "templateOrder") {
                getTemplateDetailById(pastOrderId, formik);
            }
        }
    }, [pastOrderId]);

    const drawer = (
        <div className="orderSideSection">
            <Toolbar disableGutters sx={{minHeight: "10 !important", pb: 0}}>
                <Stack onClick={() => handleBack()} direction="row" alignItems="center" spacing={0.5} sx={{cursor: "pointer"}}>
                    <KeyboardBackspaceIcon fontSize="small" sx={(t) => ({color: t.palette.primary.main})} />
                    <Typography variant="subtitle1" fontSize="0.857em" fontWeight={600} sx={(t) => ({color: t.palette.primary.main})}>
                        Back to orders
                    </Typography>
                </Stack>
            </Toolbar>
            <Toolbar disableGutters sx={{pt: 0, marginTop: "-20px"}}>
                <Stack direction="row" alignItems="left" justifyContent="space-between" sx={{cursor: "pointer", width: "100%"}}>
                    <Typography variant="h7" sx={{marginRight: 1}}>
                        Order #{orderId?.order_number}
                    </Typography>{" "}
                    {formik?.values?.orderStatus === "Draft" && (
                        <Typography variant="draft" sx={{marginLeft: "-50px !important", marginTop: "1px"}}>
                            Draft
                        </Typography>
                    )}
                    <Box sx={{display: "flex", minWidth: "50px"}}>
                        {(Object.keys(formik.touched).filter((fieldName) => formik.touched[fieldName]).length === 0 ) && (
                            <Typography
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: "#26272A"
                                }}
                            >
                                <img src={TickIcon} alt="tick" width="12px" height="18px" style={{marginRight: "4px"}} />
                                Saved
                            </Typography>
                        )}
                    </Box>
                </Stack>
            </Toolbar>
            <Toolbar sx={{pt: 0, marginTop: "-20px"}}>
                <OrderName orderName={orderName} cartName={cartName} setCartName={setCartName} />
            </Toolbar>

            <Box sx={{height: "6px"}}>{loading && <LinearProgress />}</Box>
            <Toolbar disableGutters sx={{pt: 0}}>
                <Form style={{width: "100%"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{mb: 1}}>
                                {brandList.length === 0 && <CustomErrorMessage errorMessage="No brand available at your location" />}
                            </Box>
                            <CustomSelect
                                label="Brand"
                                placeholder="Select brand"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            minWidth: 200,
                                            backgroundColor: "#ffff"
                                        }
                                    },
                                    MenuListProps: {
                                        sx: {
                                            backgroundColor: "#ffff"
                                        }
                                    }
                                }}
                                values={formik.values.selectedBrand}
                                menuItems={brandList}
                                {...formik.getFieldProps("selectedBrand")}
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        formik.setFieldValue("selectedBrand", null);
                                        formik.setFieldValue("brandName", null);
                                    }
                                    // emptied fields after brand change
                                    formik.setFieldValue("items", []);

                                    formik.setFieldValue("itemDetail", []);
                                    formik.setFieldValue("selectedMenu", "");
                                    formik.setFieldValue("kitchenLocationId", "");
                                    formik.setFieldValue("categories", {});
                                    formik.setFieldValue("selectedBrand", e.target.value);

                                    handleSelectKitchenList(e.target.value);
                                    setBrandId(e.target.value);
                                }}
                            />

                            <ErrorMessage name="selectedBrand" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomSelect
                                label="Menu"
                                placeholder="Select menu"
                                disabled={loading}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            minWidth: 200,
                                            backgroundColor: "#ffff"
                                        }
                                    },
                                    MenuListProps: {
                                        sx: {
                                            backgroundColor: "#ffff"
                                        }
                                    }
                                }}
                                values={formik.values.selectedMenu}
                                menuItems={menuList}
                                {...formik.getFieldProps("selectedMenu")}
                                onChange={(e) => {
                                    if (formik.values.itemDetail.length === 0) {
                                        if (e.target.value === "") {
                                            formik.setFieldValue("selectedMenu", null);
                                            setMenuData(null);
                                            setFilteredMenu(null);
                                        }
                                        formik.setFieldValue("selectedMenu", e.target.value);

                                        setMenuId(e.target.value);
                                    } else if (formik.values.itemDetail.length > 0) {
                                        setDummyMenu(e.target.value);
                                        setCancelModal(true);
                                    }
                                }}
                            />

                            <ErrorMessage name="selectedMenu" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                        {formik.values.headerData.customer?.userId &&
                            (formik.values.orderStatus === "" || formik.values.orderStatus === "Draft") && (
                                <Grid item xs={12}>
                                    <PastOrdersAccordion formik={formik} />
                                </Grid>
                            )}
                        {(formik.values.orderStatus === "" || formik.values.orderStatus === "Draft") &&
                            formik.values.selectedBrand !== "" && (
                                <Grid item xs={12} sx={{pt: "8px !important"}}>
                                    <TemplateOrder formik={formik} />
                                </Grid>
                            )}

                        <Grid item xs={11} className="searchGrid">
                            <SearchSection
                                placeholder="Search items"
                                color="#000A12"
                                border="1px solid #C2C2C2"
                                backgroundColor="#ffff"
                                handleUpdate={(value) => {
                                    setSearch(value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ErrorMessage
                                name="itemDetail"
                                render={(msg) => <CustomErrorMessage errorMessage={Array.isArray(msg) > 0 ? "" : msg} />}
                            />
                            {filteredMenuData &&
                                filteredMenuData?.category?.map((row, index) => (
                                    <Accordion
                                        key={index + row?.label}
                                        sx={{
                                            width: "250px",
                                            backgroundColor: "transparent",
                                            "&.MuiAccordion-root": {
                                                "&.Mui-expanded": {
                                                    margin: "0px"
                                                }
                                            },
                                            "&.MuiAccordion-root:before": {
                                                opacity: 0
                                            }
                                        }}
                                        defaultExpanded={!index}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{
                                                padding: 0,

                                                flexDirection: "row-reverse",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                margin: 0,
                                                minHeight: {xs: "16px", md: "42px"},

                                                "&.Mui-expanded": {
                                                    minHeight: {xs: "42px !important", md: "8px"},
                                                    margin: "0px"
                                                },
                                                "& .MuiAccordionSummary-content": {
                                                    margin: "0px"
                                                }
                                            }}
                                        >
                                            <Stack>
                                                <Typography variant="h4" fontWeight={600}>
                                                    {ellipsizeText(row.label)}
                                                </Typography>
                                            </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flexWrap: "wrap",
                                                rowGap: "10px",
                                                columnGap: "10px",
                                                padding: 0
                                            }}
                                        >
                                            {row?.item?.length > 0 &&
                                                row.item.map((col, ind) => (
                                                    <Grid
                                                        sx={{
                                                            mb: 0,
                                                            padding: 1,
                                                            backgroundColor: "#ffff",
                                                            borderRadius: "4px",
                                                            border: "1px solid #C2C2C2",
                                                            background: "#FAFAFA",
                                                            cursor: "pointer",
                                                            maxHeight: "58px"
                                                        }}
                                                        item
                                                        xs={12}
                                                        onClick={() => handleToggle(col.item_id)}
                                                        key={ind + col.item_id}
                                                    >
                                                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                            {" "}
                                                            <Typography variant="subtitle1">{ellipsizeText(col.item_name)}</Typography>
                                                        </Box>
                                                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                            <Typography variant="orderItemDescription">
                                                                Serves {Math.round(Number(col?.serving_size))}
                                                            </Typography>
                                                            <Typography variant="subtitle3">${parseFloat(col.price).toFixed(2)}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            {filteredMenuData && filteredMenuData?.category?.length === 0 && (
                                <CustomErrorMessage errorMessage="No items available" />
                            )}
                        </Grid>
                    </Grid>
                </Form>
            </Toolbar>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };

    const handleCancelOrder = async () => {
        formik.setFieldValue("itemDetail", []);
        formik.setFieldValue("categories", {});
        formik.setFieldValue("items", []);
        if (dummyMenuId === "") {
            formik.setFieldValue("selectedMenu", null);
        }

        formik.setFieldValue("selectedMenu", dummyMenuId);
        setMenuId(dummyMenuId);
        setMenuData(null);
        setFilteredMenu(null);
        handleCancelModal();
    };

    return (
        <>
            <CssBaseline />

            <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

                <Drawer
                    container={container}
                    variant="temporary"
                    className="sideSection"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    id="order-drawer"
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                    sx={{
                        zIndex: 1400,
                        display: {xs: "block", sm: "none"},
                        borderRight: "none",
                        "& .MuiDrawer-paper": {borderRight: "none", boxSizing: "border-box", width: drawerWidth, bgcolor: "#F5F5F6"}
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: "none", sm: "block"},

                        "& .MuiDrawer-paper": {borderRight: "none", boxSizing: "border-box", width: drawerWidth, bgcolor: "#F5F5F6"}
                    }}
                    open
                >
                    {drawer}
                </Drawer>
                <DeleteModal
                    open={openCancelModal}
                    buttonTitle="Confirm"
                    title="Are you sure you want to change menu?"
                    description="This will clear your order data."
                    handleClose={handleCancelModal}
                    handleSubmit={handleCancelOrder}
                />
                {openBackModal ? (
                    orderIdParam ? (
                        reOrder ? (
                            <DeleteModal
                                open={openBackModal}
                                redButtonText="Exit without saving"
                                buttonTitle="Save as draft "
                                title="Do you want to save order as a draft?"
                                description="Not saving will cause order data to be lost!"
                                handleClose={handleBackModal}
                                handleSubmit={handleSubmitBackOrder}
                                handleDraftClose={handleExitModal}
                            />
                        ) : (
                            <DeleteModal
                                open={openBackModal}
                                buttonTitle="Confirm"
                                title="Are you sure you want to go back?"
                                description="This will clear your order data or you can save as draft go back later."
                                handleClose={handleBackModal}
                                handleSubmit={handleBackOrder}
                            />
                        )
                    ) : (
                        <DeleteModal
                            open={openBackModal}
                            redButtonText="Exit without saving"
                            buttonTitle="Save as draft"
                            title="Do you want to save order as a draft?"
                            description="Not saving will cause order data to be lost!"
                            handleClose={handleBackModal}
                            handleSubmit={handleSubmitBackOrder}
                            handleDraftClose={handleExitModal}
                        />
                    )
                ) : null}
            </Box>
        </>
    );
}

SideSection.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
};

export default SideSection;
