import {Stack, InputAdornment, Typography, FormControl, FormControlLabel, FormGroup, Box, Grid, Checkbox} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {enforceFormat, formatPhoneNumber, formatToPhone} from "utils/helperFunctions/helpers";
import {ErrorMessage, Form, Formik, FieldArray} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect} from "react";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {API, NetworkManager} from "network/core";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import ImageCropper from "../../../../../commonComponent/ImageCropper/imageCropper";
import useMenuController from "../../brands/brandDetail/menu/menuForm.controller";

const formSchema = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
    userType: "",
    market: [],
    location: [],
    calendar_link: "",
    linkedin_link: "",
    image_url: "",
    designation: ""
};

const AddNewUser = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const [initialValues, setInitialValues] = useState(formSchema);
    const {sideBarStateName = false, selectedRow, handleIsEdited} = props;
    const [marketList, setMarketList] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [locationList, setLocationList] = React.useState([]);
    const [selectedKitchenList, setSelectedKitchen] = React.useState([]);

    const PC = useMenuController();
    const {isEdit, setIsEdit} = PC;
    const dispatch = useDispatch();
    const formikRef = React.useRef(null); // Ref to store formik instance

    useEffect(() => {
        setIsEdit(true);
    }, []);

    const getRoles = async () => {
        const params = `?page=${1}&pageSize=5000&sortBy=createdAt&orderBy=desc`;
        const instance = NetworkManager(API.ROLES.LISTING);
        const response = await instance.request({}, [params]);

        if (response.success) {
            const data = response.data;
            const idAndLabel = data.roles
                ?.filter((el) => el.is_active)
                .map((el) => ({id: el.role_id, label: el.role_name, value: el.role_id}));

            setRolesList([...idAndLabel]);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getAllMarkets = async (pageNumber = 1) => {
        const params = `?page=${pageNumber}&pageSize=5000&sortBy=createdAt&orderBy=desc&is_active=true`;
        const instance = NetworkManager(API.MARKET.LIST);
        const response = await instance.request({}, [params]);

        if (response.success) {
            const data = response.data;
            const idAndLabel = data.marketList.map((el) => ({
                id: el.market_id,
                label: el.market_name,
                kitchenlocationdetails: el.kitchenLocation
            }));
            setMarketList([...idAndLabel]);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getLocation = async (data, formik) => {
        if (!data) {
            return;
        }
        const marketNewList = JSON.parse(JSON.stringify(marketList));
        const kitchenIds = [];
        const kitchenList = [];
        data.forEach((id) => {
            const kitchenData = marketNewList.filter((row) => row.id === id);
            if (kitchenData[0]?.kitchenlocationdetails?.length > 0) {
                const states = kitchenData[0].kitchenlocationdetails?.map((state) => ({
                    label: state.name,
                    value: state.kitchen_location_id,
                    id: state.kitchen_location_id
                }));
                kitchenList.push(...states);
                kitchenIds.push(...kitchenData[0].kitchenlocationdetails?.map((state) => state.kitchen_location_id));
            }
        });
        setLocationList(kitchenList);

        // Ensure formik is updated after locationList is set
        if (formik) {
            formik.setFieldValue("location", kitchenIds);
        }
    };

    useEffect(() => {
        getRoles();
        getAllMarkets();
    }, []);

    const getUserProfileData = async (Userid) => {
        const instance = NetworkManager(API.SUBADMIN.DETAIL(`${Userid}`));
        const response = await instance.request();

        if (response.success) {
            const data = response?.data?.subAdmin;
            setInitialValues({
                first_name: data?.subAdminDetail?.first_name,
                last_name: data?.subAdminDetail?.last_name,
                email: data?.subAdminDetail?.email,
                phone: data?.subAdminDetail?.phone,
                role: data?.subAdminDetail?.sub_role_id,
                market: data?.subAdminMarketIds,
                location: data?.subAdminKitchen,
                calendar_link: data?.subAdminDetail?.additional_details?.calendar_link,
                linkedin_link: data?.subAdminDetail?.additional_details?.linkedin_link,
                designation: data?.subAdminDetail?.additional_details?.designation,
                image_url: data?.subAdminDetail?.additional_details?.image_url ? [data.subAdminDetail.additional_details.image_url] : []
            });

            getLocation(data?.subAdminMarketIds, null);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    useEffect(() => {
        if (selectedRow) {
            getUserProfileData(selectedRow?.user_id);
        } else {
            setInitialValues({
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                role: "",
                market: [],
                location: [],
                calendar_link: "",
                linkedin_link: "",
                designation: "",
                image_url: ""
            });
        }
    }, [selectedRow]);

    useEffect(() => {
        if (formikRef.current) {
            getLocation(formikRef.current.values.market, formikRef.current);
        }
    }, [formikRef.current?.values.market]);

    const handleNewCustomer = async (val, setSubmitting) => {
        setSubmitting(true);

        const instance = NetworkManager(selectedRow ? API.SUBADMIN.PUT(selectedRow?.user_id) : API.SUBADMIN.CREATE);

        const payload = {
            email: val.email,
            first_name: val.first_name,
            last_name: val.last_name,
            phone: val.phone,
            sub_role_id: val.role,
            market_Ids: val.market,
            location_Ids: val.location,
            additional_details: {
                calendar_link: val?.calendar_link ? val?.calendar_link : "",
                linkedin_link: val?.linkedin_link ? val?.linkedin_link : "",
                designation: val?.designation ? val?.designation : "",
                image_url: val?.image_url ? val?.image_url[0] : ""
            }
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: selectedRow ? "User updated successfully" : "User created successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };
    const [phoneNumber, setPhoneNumber] = useState("");

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    first_name: yup.string().required("First name is required").nullable().trim(),
                    last_name: yup.string().required("Last name is required").nullable().trim(),
                    email: yup.string().required("Email is required").email("Enter valid email").nullable().trim(),
                    phone: yup
                        .string()
                        .required("Phone number is required")
                        .nullable()
                        .trim()
                        .matches(
                            /^\(\d{3}\) \d{3}-\d{4}( Ext\. \d{1,3})?$/,
                            "Phone number should be in format (XXX) XXX-XXXX or (XXX) XXX-XXXX Ext. XXX"
                        ),
                    role: yup.string().required("Role is required").nullable().trim(),
                    market: yup.array().min(1, "Market is required").required().nullable(),
                    location: yup.array().min(1, "Location is required").required().nullable(),
                    designation: yup.string().required("Designation is required").nullable().trim(),
                    linkedin_link:
                        yup.string().required("Linkedin link is required").nullable().trim() ||
                        yup.string().matches(/^https?:\/\/(www\.)?linkedin\.com\/.*$/, "Enter a valid LinkedIn url"),
                    calendar_link:
                        yup.string().required("Calendar link is required").nullable().trim() || yup.string().url("Enter a valid URL"),
                    image_url: yup.array().min(1, "Image is required").required().nullable()
                })}
                onSubmit={(val, {setSubmitting}) => handleNewCustomer(val, setSubmitting)}
            >
                {(formik) => {
                    formikRef.current = formik; // Store formik instance in the ref
                    return (
                        <Form>
                            <TemporaryDrawer
                                sideBarStateName={sideBarStateName}
                                handleSelectedRow={handleIsEdited}
                                resetForm={formik.resetForm}
                                closingFunction={() => {
                                    toggleDrawer("right", false)();
                                    handleIsEdited(false);
                                }}
                                touched={formik.touched}
                            >
                                <Stack sx={{height: "100%"}} justifyContent="space-between">
                                    <Stack spacing={4}>
                                        <Typography sx={{fontSize: "1.625rem", fontWeight: 700, color: "#000"}}>
                                            {selectedRow ? "Edit new user" : "Add new user"}
                                        </Typography>
                                        <Stack spacing={3} sx={{marginTop: "28px !important"}}>
                                            <CustomTextField
                                                placeholder="First name"
                                                label="First name"
                                                inputProps={{maxLength: 50}}
                                                {...formik.getFieldProps("first_name")}
                                            />
                                            <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            <CustomTextField
                                                placeholder="Last name"
                                                label="Last name"
                                                inputProps={{maxLength: 50}}
                                                {...formik.getFieldProps("last_name")}
                                            />
                                            <ErrorMessage name="last_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            <CustomTextField
                                                placeholder="Email"
                                                disabled={!!selectedRow}
                                                label="Email"
                                                inputProps={{maxLength: 50}}
                                                {...formik.getFieldProps("email")}
                                            />
                                            <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            {/* <CustomTextField
                                                fullWidth
                                                label="Phone number"
                                                onKeyDown={enforceFormat}
                                                onKeyUp={formatToPhone}
                                                inputProps={{maxLength: 14}}
                                                {...formik.getFieldProps("phone")}
                                            /> */}
                                            <CustomTextField
                                                fullWidth
                                                label="Phone number"
                                                type="text"
                                                inputProps={{maxLength: 23}}
                                                value={phoneNumber || formik.values.phone}
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;

                                                    if (inputValue.length < phoneNumber.length) {
                                                        setPhoneNumber(inputValue);
                                                        formik.setFieldValue("phone", inputValue);
                                                        return;
                                                    }

                                                    const formattedValue = formatPhoneNumber(inputValue);
                                                    setPhoneNumber(formattedValue);
                                                    formik.setFieldValue("phone", formattedValue);
                                                }}
                                                onBlur={(event) => {
                                                    formik.handleBlur(event);
                                                }}
                                            />
                                            <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />

                                            <Grid item xs={12}>
                                                <CustomSelect
                                                    label="Role"
                                                    fullWidth
                                                    menuItems={rolesList}
                                                    {...formik.getFieldProps("role")}
                                                    onChange={(e) => {
                                                        const id = e.target.value;
                                                        formik.setFieldValue("role", id);
                                                    }}
                                                />
                                                <ErrorMessage name="role" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>

                                            <CustomTextField
                                                fullWidth
                                                label="Designation"
                                                inputProps={{maxLength: 14}}
                                                {...formik.getFieldProps("designation")}
                                            />
                                            <ErrorMessage name="designation" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />

                                            <CustomTextField
                                                fullWidth
                                                label="Calendar link"
                                                inputProps={{maxLength: 250}}
                                                {...formik.getFieldProps("calendar_link")}
                                            />
                                            <ErrorMessage
                                                name="calendar_link"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />

                                            <CustomTextField
                                                fullWidth
                                                label="Linkedin link"
                                                inputProps={{maxLength: 250}}
                                                {...formik.getFieldProps("linkedin_link")}
                                            />
                                            <ErrorMessage
                                                name="linkedin_link"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />

                                            <Box
                                                sx={{
                                                    borderRadius: "4px",
                                                    border: "1px solid #bdbdbd",
                                                    p: 2,
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <ImageCropper
                                                    isEdit={isEdit}
                                                    title="Add a photo"
                                                    multiPhoto={false}
                                                    image={formik.values.image_url}
                                                    handleChange={(list) => {
                                                        formik.setFieldValue(`image_url`, list);
                                                    }}
                                                    handleError={(msg = "Minimum 155KB file size is required") => {
                                                        formik.setTouched({image_url: true});
                                                        setTimeout(() => formik.setErrors({image_url: msg}), 100);
                                                    }}
                                                />
                                            </Box>
                                            <ErrorMessage name="image_url" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />

                                            <Typography sx={{fontSize: "1.125rem", fontWeight: 600}}>Access</Typography>

                                            <Grid item xs={12}>
                                                <CustomSelectWithCheckbox
                                                    label="Markets"
                                                    selectAll
                                                    menuItems={marketList}
                                                    values={formik.values.market}
                                                    onChange={(val) => {
                                                        formik.setFieldValue("market", val);
                                                        getLocation(val, formik);
                                                    }}
                                                />
                                                <ErrorMessage name="market" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomSelectWithCheckbox
                                                    label="Locations"
                                                    selectAll
                                                    menuItems={locationList}
                                                    values={formik.values.location}
                                                    onChange={(e) => formik.setFieldValue("location", e)}
                                                />
                                                <ErrorMessage name="location" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                    <Box sx={{mt: 2, pt: 1, width: "100%", position: "sticky", bottom: 0, bgcolor: "#fff", zIndex: 1}}>
                                        <ButtonGrouping
                                            btnprops={[
                                                {
                                                    btnTitle: "Cancel",
                                                    sx: (t) => ({color: t.palette.error.dark, width: "92px"}),
                                                    onClick: (e) => {
                                                        formik.resetForm();
                                                        toggleDrawer("right", false)(e);
                                                        handleIsEdited(false);
                                                    }
                                                },
                                                {
                                                    btnTitle: selectedRow ? "Save" : "Invite",
                                                    variant: "contained",
                                                    sx: (t) => ({color: t.palette.background.paper, width: "92px"}),
                                                    onClick: (e) => {
                                                        formik.handleSubmit();

                                                        formik.validateForm().then((res) => {
                                                            if (Object.keys(res).length === 0) {
                                                                toggleDrawer("right", false)(e);
                                                                setTimeout(() => {
                                                                    formik.resetForm();
                                                                }, 1500);
                                                            }
                                                        });
                                                    }
                                                }
                                            ]}
                                        />
                                    </Box>
                                </Stack>
                            </TemporaryDrawer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default AddNewUser;
