import React, {useLayoutEffect, useState} from "react";
import {ErrorMessage, Form, Formik} from "formik";
import * as Yup from "yup";
import {CircularProgress, Grid, InputAdornment, Typography} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {Box} from "@mui/system";
import {enforceFormat} from "utils/helperFunctions/helpers";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {useStyles} from "./styles";
import {API, NetworkManager} from "network/core";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import Loader from "ui-component/Loader";

const defaultInitailValues = {
    orderMinimum: "",
    deliveryFee: "",
    cancellationFees: ""
};
const validationSchema = Yup.object().shape({
    orderMinimum: Yup.number().required("Order minimum is required"),
    deliveryFee: Yup.string().required("Delivery fee is required"),
    cancellationFees: Yup.string().required("Cancellation fees is required")
});
const HomemealsServices = () => {
    const [loadingSave, setLoadingSave] = useState(false);
    const [initialValues, setInitialValues] = useState(defaultInitailValues);
    const [originalValues, setOriginalValues] = useState(null);
    const [loading, setLoading] = useState(false);
    const isChangable =
        WithPermission({module: "Services", permission: "update_permission"}) ||
        WithPermission({module: "Services", permission: "create_permission"});
    const isCreatable = WithPermission({module: "Services", permission: "create_permission"});
    const isEditable = WithPermission({module: "Services", permission: "update_permission"});
    const isDeletable = WithPermission({module: "Services", permission: "delete_permission"});

    const styles = useStyles();

    const getHomeMealServiceFee = async () => {
        setLoading(true);

        const instance = NetworkManager(API.SERVICE.GETHOMEMEALSERVICE);
        const response = await instance.request();
        if (response?.success) {
            const serviceFee = response?.data?.homeMealServiceFee;
            const inititalValueObject = {
                orderMinimum: serviceFee?.homeMealRate?.order_minimum,
                deliveryFee: serviceFee?.homeMealRate?.delivery_fee,
                cancellationFees: serviceFee?.cancellationPolicy?.cancellation_fee
            };
            setInitialValues(inititalValueObject);
            setOriginalValues(inititalValueObject);
        }
        setLoading(false);
    };
    useLayoutEffect(() => {
        getHomeMealServiceFee();
    }, []);

    const handleSaveServiceFee = async (values) => {
        const payload = {
            homeMealRate: {
                order_minimum: values?.orderMinimum,
                delivery_fee: values?.deliveryFee
            },
            cancellationPolicy: {
                cancellation_fee: values?.cancellationFees
            }
        };
        const instance = NetworkManager(API.SERVICE.UPDATEHOMEMEALSERVICE);
        const response = await instance.request(payload);
        if (response) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        }
    };
    return loading ? (
        <Loader />
    ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={handleSaveServiceFee}>
            {({values, handleChange, handleSubmit, handleBlur, errors, touched, resetForm}) => (
                <Form style={styles.form}>
                    <Grid item xs={12} lg={9}>
                        <Box sx={styles.feeWrapper}>
                            <Typography sx={styles.heading} variant="h6">
                                Home meals rates and fees
                            </Typography>
                            <Typography sx={styles.smallHeading} variant="h6">
                                Non member
                            </Typography>
                            <Grid container item xs={12} md={6} columnGap={1} rowSpacing={1.5}>
                                <Grid item xs>
                                    <CustomTextField
                                        label="Order minimum"
                                        fullWidth
                                        name="orderMinimum"
                                        value={values.orderMinimum} // Use Formik's values here directly
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" sx={{color: "#212121"}}>
                                                    $
                                                </InputAdornment>
                                            )
                                        }}
                                        inputProps={{maxLength: 10}}
                                        onKeyDown={enforceFormat}
                                        error={touched.orderMinimum && Boolean(errors.orderMinimum)}
                                        disabled={!isEditable}
                                    />
                                    <ErrorMessage name="orderMinimum" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                                <Grid item xs>
                                    <CustomTextField
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" sx={{color: "#212121"}}>
                                                    $
                                                </InputAdornment>
                                            )
                                        }}
                                        onKeyDown={enforceFormat}
                                        label="Delivery fee"
                                        name="deliveryFee"
                                        value={values.deliveryFee}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inputProps={{maxLength: 10}}
                                        error={touched.deliveryFee && Boolean(errors.deliveryFee)}
                                        disabled={!isEditable}
                                        sx={{
                                            "& .MuiInputLabel-root": {
                                                width: "auto",
                                                whiteSpace: "nowrap",
                                                overflow: "visible",
                                                transform: "translate(14px, -9px) scale(0.75)"
                                            }
                                        }}
                                    />
                                    <ErrorMessage name="deliveryFee" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                                <Grid item xs={1} />
                            </Grid>
                        </Box>

                        <Box sx={styles.feeWrapper}>
                            <Typography sx={styles.heading} variant="h6">
                                Cancellation policy
                            </Typography>
                            <Grid container columnGap={1} rowSpacing={1.5}>
                                <Grid item xs={10.75} sm={5.375} lg={2.62} xl={2.657} sx={{mb: 2}}>
                                    <CustomTextField
                                        label="Cancellation fee "
                                        fullWidth
                                        name="cancellationFees"
                                        value={values.cancellationFees}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inputProps={{maxLength: 3}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start" sx={{color: "#212121"}}>
                                                    %
                                                </InputAdornment>
                                            )
                                        }}
                                        error={touched.cancellationFees && Boolean(errors.cancellationFees)}
                                        onKeyDown={enforceFormat}
                                        disabled={!isEditable}
                                    />

                                    <ErrorMessage name="cancellationFees" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {(isChangable || isDeletable || isCreatable) && (
                        <Grid item xs={12}>
                            <ButtonGrouping
                                btnprops={[
                                    {
                                        btnTitle: "Cancel",
                                        sx: (t) => ({color: t.palette.error.dark}),
                                        onClick: () => {
                                            // Reset the form to the original data
                                            resetForm({values: originalValues});
                                        }
                                    },
                                    {
                                        btnTitle: loadingSave ? <CircularProgress color="#ffffff" /> : "Save",
                                        variant: "contained",
                                        sx: (t) => ({color: t.palette.background.paper}),
                                        onClick: handleSubmit
                                    }
                                ]}
                            />
                        </Grid>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default HomemealsServices;
