import {useTheme} from "@mui/material";

export const useStyles = () => {
    const theme = useTheme();
    return {
        wrapper: {
            height: "max-content",
            width: "100%",
            borderRadius: "8px",
            p: 2,
            border: `1px solid ${theme.palette.other.slateWhite}`
        },
        addressName: {
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "25px",
            width: "100%"
        },
        flexer: {
            display: "flex"
        },
        flexBetween: {
            justifyContent: "space-between"
        },
        detailTitle: {
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "16.8px",
            color: theme.palette.text.textlight
        },
        detail: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "17.5px",
            color: theme.palette.text.primary
        },
        paddingAdjust: {
            pr: "12px"
        },
        columnView: {
            flexDirection: "column"
        },
        flexGap: {
            gap: "12px"
        },
        defaultChip: {
            minHeight: 29,
            minWidth: 61,
            color: theme.palette.orange.orangeDefault,
            backgroundColor: theme.palette.orange.orangeFaded
        },
        titleContainer: {
            minHeight: 39,
            maxHeight: "max-content"
        },
        nameContainer: {
            minHeight: 29,
            maxHeight: 29
        },
        aptContainer: {
            minHeight: "min-content",
            maxHeight: "min-content"
        },
        lineBreaker: {
            width: "100%",
            pr: "4px",
            lineBreak: "normal",
            wordBreak: "break-word"
        },
        instuctionPadding: {
            pr: "12px !important"
        }
    };
};
