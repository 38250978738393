import React, {useEffect, useLayoutEffect, useState} from "react";
import {Box, Grid, Typography, Button} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {enforceFormat, FormatCurrency, formatToPhone, getManagers} from "utils/helperFunctions/helpers";
import {ErrorMessage, Formik} from "formik";
import * as yup from "yup";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import PlusIcon from "assets/images/icons/plus.png";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewAddressForm from "../../../CreateCustomer/AddNewAddressForm";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import SalesTaxExempt from "./SalesTaxExempt/SalesTaxExempt";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import Loader from "ui-component/Loader";
import ProfileView from "./ProfileView/ProfileView";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import AddressCard from "commonComponent/AddressCards";
import {useStyles} from "./styles";
import Wallet from "./Wallet/Wallet";
import SettingsSection from "./SettingsSection/SettingsSection";

const formSchema = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    addCard: "",
    company: "",
    companyId: "",
    title: "",
    state: "",
    taxId: "",
    sales_tax_doc_url: "",
    accountManager: "",
    is_text: false
};

function Profile(props) {
    const styles = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {toggleDrawer} = useTempDrawer();

    const [initialValues, setInitialValues] = useState({
        ...formSchema,
        address: ""
    });
    const [isAddAddressFormOpen, setIsAddAddressFormOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [urlSearchParams] = useSearchParams();
    const prm = useParams();
    const profileId = prm.type;
    const [addressList, setAddresList] = useState([]);

    const mrkName = urlSearchParams.get("customerName");
    const editMode = urlSearchParams.get("edit");
    const [selectedAddress, setSelectedAddress] = useState("");
    const [buttonType, setbuttonType] = useState("");
    const [isAddressUpdate, setIsAddressUpdate] = useState(false);

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [selectEditAddress, setSelectEditAddress] = useState("");
    const [deleteType, setDeleteType] = useState("");
    const [managers, setManagers] = useState([]);
    useLayoutEffect(() => {
        getManagers(setManagers, setLoading);
    }, []);
    const handleAddressUpdated = () => {
        setIsAddressUpdate(!isAddressUpdate);
    };

    const getCustomerProfileData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.CUSTOMER.GETCUSTOMERDATA(`${profileId}`));

        const response = await instance.request();

        if (response.success) {
            setCustomerData(response?.data);

            const defaultAddress = response?.data?.customerAddresses?.find((address) => address.is_default);
            setInitialValues({
                first_name: response?.data?.full_name || "",
                last_name: response?.data?.last_name || "",
                email: response?.data?.email || "",
                phone: response?.data?.phone || "",
                address: defaultAddress?.delivery_address || "",
                addCard: response?.data?.addCard || "",
                company: response?.data?.company?.company_name ?? "",
                title: response?.data?.designation || "",
                state: response?.data?.sales_tax_state_id || "",
                taxId: response?.data?.tax_exempt_id || "",
                sales_tax_doc_url: response?.data?.sales_tax_doc_url || "",
                accountManager: response?.data?.account_manager_id || "",
                prefferedPrice:
                    response?.data?.discount_value &&
                    `${
                        response?.data?.discount_value_type === "DISCOUNT"
                            ? `${FormatCurrency(response?.data?.discount_value)}`
                            : `${response?.data?.discount_value}%`
                    }`,
                is_text: response?.data?.is_text || false
            });
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
    };

    const getAddressList = async () => {
        let data;
        const instance = NetworkManager(API.CUSTOMER.GETADDRESSLIST(`${profileId}`));
        const response = await instance.request();

        if (response.success) {
            data = response?.data;
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        return setAddresList(data);
    };

    useEffect(() => {
        getAddressList();
    }, [isAddressUpdate]);

    useEffect(() => {
        getCustomerProfileData();
    }, [profileId]);

    const findSelectedAddress = (addressId) => addressList.find((address) => address.address_id === addressId);

    const handleAddCategory = (type, row, ind) => (e) => {
        setbuttonType(type);
        switch (type) {
            case "addAddressForm": {
                setIsAddAddressFormOpen(true);
                break;
            }
            case "addNewCardForm": {
                // setIsAddNewCardFormOpen(true);
                break;
            }
            case "editAddress": {
                setIsAddAddressFormOpen(true);
                break;
            }
            default:
                break;
        }
    };

    const handleCreateCustomer = async (values) => {
        const instance = NetworkManager(API.CUSTOMER.UPDATEPROFILE(`${profileId}`));
        const payload = {
            email: values.email,
            full_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            company_id: values.companyId ? values.companyId : "",
            address_id: values.address,
            customer_card_id: values.addCard,
            sales_tax_state_id: values.state,
            tax_exempt_id: values.taxId,
            designation: values.title,
            sales_tax_doc_url: values.sales_tax_doc_url,
            account_manager_id: values.accountManager !== "" ? values.accountManager : null,
            is_text: values?.is_text
        };
        const response = await instance.request(payload);

        if (response.success) {
            setCustomerData(response?.data);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            navigate("/customers?tab=customer");
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };
    useEffect(() => {
        getCustomerProfileData();
    }, [profileId]);

    useEffect(() => {
        if (customerData.customerAddresses) {
            const defaultAddress = customerData?.customerAddresses.find((address) => address?.is_default);
            setInitialValues((prevValues) => ({
                ...prevValues,
                address: defaultAddress?.address_id || ""
            }));
        }
    }, [customerData]);

    const handleDeleteModal = (type) => {
        setOpen((pre) => !pre);
        setDeleteType(type);
        if (type === "address") {
            setTitle("Are you sure you want to delete this address?");
        } else if (type === "card") {
            setTitle("Are you sure you want to delete this card?");
        }
    };

    const handleActionBtn =
        (...params) =>
        (e) => {
            const type = params[0];
            const row = params[1];
            const handleClose = params[2];
            handleClose();

            switch (type) {
                case "Delete":
                    setSelectEditAddress(row?.address_id);
                    handleDeleteModal("address");
                    break;
                case "Edit":
                    setSelectedAddress(row?.address_id);
                    handleAddCategory("editAddress")(e);
                    toggleDrawer("right", "addAddressForm")(e);
                    break;
                default:
                    break;
            }
        };

    const handleDeleteAddress = async (formik) => {
        setOpen((pre) => !pre);
        const instance = NetworkManager(API.ADDRESS.DELETE(selectEditAddress));
        const res = await instance.request();
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );

            setSelectEditAddress("");
            setInitialValues((prevValues) => ({
                ...prevValues,
                address: ""
            }));
            setSelectedAddress("");
            formik.setFieldValue("address", ""); // Clear the form field
            getAddressList();
            setDeleteType("");
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    return (
        <>
            {loading && <Loader />}
            {isAddAddressFormOpen && (
                <AddNewAddressForm
                    userID={profileId}
                    setIsAddAddressFormOpen={setIsAddAddressFormOpen}
                    selectedAddress={selectedAddress}
                    findSelectedAddress={findSelectedAddress}
                    buttonType={buttonType}
                    handleAddressUpdated={handleAddressUpdated}
                    // selectedAddressId={selectedAddressId}
                />
            )}
            {editMode ? (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={yup.object({
                        first_name: yup.string().required("First name is required").nullable().trim(),
                        last_name: yup.string().required("Last name is required").nullable().trim(),
                        email: yup.string().required("Email is required").email("Enter valid email").nullable().trim()
                        // phone: yup.string().required("Phone number is required").nullable().trim()
                        // marketId: yup.string().required("Market is required").nullable().trim(),
                        // address: yup.string().required("address is required").nullable().trim(),
                        // addCard: yup.string().required("Card is required").nullable().trim(),
                        // state: yup.string().required("State is required").nullable().trim(),
                        // taxId: yup.string().required("Tax exempt ID is required").nullable().trim()
                    })}
                    onSubmit={(values, actions) => handleCreateCustomer(values, actions)}
                >
                    {(formik) => {
                        return (
                            <Grid container spacing={2}>
                                <Grid item xs={12} mt="29px">
                                    <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}>Customer details</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles?.subHeaderFont}>BASIC DETAILS</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} xl={3.5} mt="12px">
                                    <CustomTextField
                                        placeholder="First name"
                                        label="First name"
                                        fullWidth
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                        }}
                                        inputProps={{maxLength: 50}}
                                        InputLabelProps={{
                                            style: {color: "#9E9E9E"}
                                        }}
                                        {...formik.getFieldProps("first_name")}
                                    />
                                    <Box sx={{height: "27px"}}>
                                        <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} xl={3.5} mt="12px">
                                    <CustomTextField
                                        placeholder="Last name"
                                        label="Last name"
                                        fullWidth
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                        }}
                                        inputProps={{maxLength: 50}}
                                        InputLabelProps={{
                                            style: {color: "#9E9E9E"}
                                        }}
                                        {...formik.getFieldProps("last_name")}
                                    />
                                    <Box sx={{height: "27px"}}>
                                        <ErrorMessage name="last_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4} xl={3.5}>
                                            <CustomTextField
                                                placeholder="Email"
                                                label="Email"
                                                fullWidth
                                                inputProps={{maxLength: 50}}
                                                {...formik.getFieldProps("email")}
                                                InputLabelProps={{
                                                    style: {color: "#9E9E9E"}
                                                }}
                                            />
                                            <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={3.5}>
                                            <CustomTextField
                                                fullWidth
                                                label="Phone number"
                                                onKeyDown={enforceFormat}
                                                onKeyUp={formatToPhone}
                                                inputProps={{maxLength: 14}}
                                                InputLabelProps={{
                                                    style: {color: "#9E9E9E"}
                                                }}
                                                {...formik.getFieldProps("phone")}
                                            />
                                            <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <CompanyDetails formik={formik} />

                                <Grid item xs={12} mt={3}>
                                    <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}> Addresses</Typography>
                                </Grid>
                                <Grid container sx={styles.addressesOuterWrapper} mt={2} gap={2} ml={2}>
                                    {addressList?.length > 0 ? (
                                        <Grid container item xs={12} sx={styles.addressesInnerWrapper}>
                                            {addressList.map((address, i) => (
                                                <AddressCard data={address} key={address?.address_id} handleActionBtn={handleActionBtn} />
                                            ))}
                                        </Grid>
                                    ) : (
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <Typography>No data available</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            cursor: "pointer",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}
                                        onClick={(e) => {
                                            handleAddCategory("addAddressForm")(e);
                                            toggleDrawer("right", "addAddressForm")(e);
                                        }}
                                    >
                                        <Box sx={styles?.iconBox}>
                                            <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                        </Box>

                                        <Typography
                                            sx={{
                                                marginLeft: "5px",
                                                fontWeight: 500,
                                                color: (theme) => theme.palette.primary.main
                                            }}
                                        >
                                            ADD ADDRESS
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}> Wallet</Typography>
                                </Grid>

                                {/* {!isUpdate && (
                                        <Box
                                            onClick={(e) => {
                                                navigate(
                                                    `/customers/${profileId}/?tab=houseAccount&customerName=${mrkName}&edit=true&show=true`
                                                );
                                            }}
                                            mt={1}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                            <Typography
                                                sx={{
                                                    marginLeft: "5px",
                                                    fontWeight: 600,
                                                    color: (theme) => theme.palette.primary.main
                                                }}
                                            >
                                                Create house account
                                            </Typography>
                                        </Box>
                                    )} */}
                                <Wallet formik={formik} profileId={profileId} />

                                <SalesTaxExempt formik={formik} />
                                <Grid item xs={12} sm={6} md={4} mt="19px" xl={3.5}>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}>Account manager</Typography>
                                    </Grid>
                                    <Box mt="35px">
                                        {" "}
                                        <CustomSelect
                                            label="Account manager (optional)"
                                            menuItems={managers}
                                            {...formik.getFieldProps("accountManager")}
                                        />
                                        {formik?.values?.accountManager !== "" && (
                                            <Button
                                                variant="text"
                                                sx={{fontSize: "0.937rem", fontWeight: 600, color: "#00ab3a"}}
                                                onClick={() => formik.setFieldValue("accountManager", "")}
                                            >
                                                Unassign
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>

                                <SettingsSection formik={formik} profileId={profileId} customerData={customerData} />
                                <Grid item xs={12} mt={1}>
                                    <ButtonGrouping
                                        btnprops={[
                                            {
                                                btnTitle: "Cancel",
                                                sx: (t) => ({color: t.palette.error.dark, width: "92px"}),
                                                onClick: (e) => {
                                                    formik.resetForm();
                                                    navigate("/customers?tab=customer");
                                                }
                                            },
                                            {
                                                btnTitle: "Save",
                                                variant: "contained",
                                                sx: (t) => ({color: t.palette.background.paper, width: "92px"}),
                                                onClick: (e) => {
                                                    formik.handleSubmit();
                                                }
                                            }
                                        ]}
                                    />
                                </Grid>
                                <DeleteModal
                                    open={open}
                                    buttonTitle="Delete"
                                    title={title}
                                    description="This will remove this from the list"
                                    handleClose={handleDeleteModal}
                                    handleSubmit={() => {
                                        if (deleteType === "card") {
                                            // handleDeleteCard();
                                        } else if (deleteType === "address") {
                                            handleDeleteAddress(formik);
                                        }
                                    }}
                                />
                            </Grid>
                        );
                    }}
                </Formik>
            ) : (
                <ProfileView />
            )}
        </>
    );
}

export default Profile;
