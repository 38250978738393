import React, {useEffect, useState, useRef} from "react";
import {Box, Grid, Typography, Stack, IconButton} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {ErrorMessage, Form, Formik} from "formik";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";

import {Link} from "react-router-dom";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PreviewThumbnail from "commonComponent/FilePreview";
import {useStyles} from "../styles";
import PlusIcon from "assets/images/icons/plus.png";

function SalesTaxExempt({formik}) {
    const styles = useStyles();
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [loading, setLoading] = useState(false);

    const getState = async () => {
        const instance = NetworkManager(API.USER.STATE);
        const response = await instance.request();
        if (response.success) {
            const states = response.data.stateList.map((state) => ({
                label: state.name,
                value: state.name,
                id: state.state_id
            }));
            setStateList(states);
        }
    };

    const getCity = async (id) => {
        const instance = NetworkManager(API.USER.CITY(`?stateId=${id}`));
        const response = await instance.request();
        if (response.success) {
            const cities = response.data.cityList.map((city) => ({
                label: city.name,
                value: city.name,
                id: city.city_id
            }));

            setCityList(cities);
        }
    };

    useEffect(() => {
        getState();
    }, []);

    const handleDeleteImage = async (formik) => {
        // delete from backend
        const instance = NetworkManager(API.IMAGE.DELETE);

        const packet = {
            url: formik.values.sales_tax_doc_url
        };
        const response = await instance.request(packet);
        if (response.success) {
            formik.setFieldValue("sales_tax_doc_url", "");
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        }
    };
    // eslint-disable-next-line consistent-return
    const handleImgChange = (e, formik) => {
        const target = e.target;
        if (target.files && target.files[0]) {
            const minAllowedSize = 10; // 10kb
            const maxAllowedSize = 5.5e7; // 55mb
            if (target.files[0].size < minAllowedSize) {
                target.value = "";
                formik.setFieldError("sales_tax_doc_url", "Minimum 155KB file size is required");
            } else if (target.files[0].size > maxAllowedSize) {
                target.value = "";
                formik.setFieldError("sales_tax_doc_url", "Maximum 55MB file size is required");
            } else {
                return true;
            }
        }
        e.target.value = null;
    };
    const onDrageOver = (e) => {
        e.preventDefault();
    };
    const uploadImage = async (file, formik) => {
        setLoading(true);
        const instance = NetworkManager(API.CUSTOMER.UPLOADFILE);
        const packet = new FormData();
        packet.append("file", file);
        const response = await instance.request(packet);
        console.log(file.size, "HAHAH");
        if (file?.size <= 2000000) {
            if (response.success) {
                formik.setFieldValue("sales_tax_doc_url", response.data.url);
                formik.setFieldValue("fileName", file?.name);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.message,
                        variant: "alert",
                        alert: {
                            color: "success"
                        },
                        close: false
                    })
                );
            } else if (response.error) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.message,
                        variant: "alert",
                        alert: {
                            severity: "error",
                            color: "error"
                        },
                        close: false
                    })
                );
            }
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "File size too large.",
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
    };

    const onDrop = (e, formik) => {
        e.preventDefault();
        const isFileValid = handleImgChange({target: {files: e.dataTransfer.files}}, formik);
        if (isFileValid) {
            uploadImage(e.dataTransfer.files[0], formik);
        }
    };

    const onFileDrop = (e, formik) => {
        const newFile = e.target.files[0];
        if (newFile) {
            const isFileValid = handleImgChange({target: {files: e.target.files}}, formik);
            if (isFileValid) {
                uploadImage(newFile, formik);
            }
        }
    };
    function getFileNameFromUrl(url) {
        const parts = url.split("/");
        const temp = decodeURIComponent(parts[parts.length - 1]);
        const result = temp.replace(/^([^_]*_){2}/, "");

        return result;
    }

    function downloadFile(fileUrl) {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = getFileNameFromUrl(fileUrl) || "unknown.file";
        link.click();
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography sx={styles?.subHeaderFont}>SALES TAX EXEMPT</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                        <CustomSelect
                            label="State"
                            menuItems={stateList}
                            {...formik.getFieldProps("state")}
                            // value={formik.state}
                            // name='state'
                            onChange={(e) => {
                                const id = e.target.value;
                                formik.setFieldValue("state", id);
                                getCity(id);
                            }}
                            InputLabelProps={{
                                style: {color: "#9E9E9E"}
                            }}
                        />
                        <ErrorMessage name="state" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                        <Grid item xs={12}>
                            <CustomTextField
                                placeholder="Tax exempt ID"
                                label="Tax exempt ID"
                                fullWidth
                                inputProps={{maxLength: 50}}
                                {...formik.getFieldProps("taxId")}
                                labelProps={{sx: {color: "#9E9E9E"}}}
                            />
                            <ErrorMessage name="taxId" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                    <Grid
                        item
                        xs={12}
                        borderRadius="4px"
                        p={0}
                        sx={{
                            height: formik.values.sales_tax_doc_url ? "100%" : "89px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "1rem",

                            padding: "0px !important"
                        }}
                    >
                        <Grid item xs={12}>
                            {formik.values.sales_tax_doc_url ? (
                                <Box>
                                    <Stack
                                        p={2}
                                        direction="row"
                                        sx={{border: "1px dashed #E0E2E6", position: "relative"}}
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}
                                        >
                                            <PreviewThumbnail file={formik.values.sales_tax_doc_url} />
                                            <a
                                                href={`${formik.values.sales_tax_doc_url}?t=${Date.now()}`}
                                                download={getFileNameFromUrl(formik.values.sales_tax_doc_url)}
                                                rel="noreferrer"
                                                target="_blank"
                                                style={{textDecoration: "none"}}
                                            >
                                                <Typography style={{cursor: "pointer", wordBreak: "break-word"}} color="#00ab3a">
                                                    {getFileNameFromUrl(formik.values.sales_tax_doc_url)}
                                                </Typography>
                                            </a>

                                            <Box sx={{position: "absolute", right: "0.5rem", top: "0.5rem", marginLeft: "1rem"}}>
                                                <IconButton
                                                    sx={{padding: "0px !important", background: "#fff"}}
                                                    onClick={() => {
                                                        handleDeleteImage(formik);
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Stack>
                                </Box>
                            ) : (
                                <label
                                    ref={inputRef}
                                    htmlFor="SalesTaxdoc"
                                    onDragOver={onDrageOver}
                                    onDrop={(e) => onDrop(e, formik)}
                                    style={{cursor: "pointer"}}
                                >
                                    <Stack sx={{height: 89, border: "1px dashed #E0E2E6"}} justifyContent="center" alignItems="center">
                                        {loading ? (
                                            "Uploading..."
                                        ) : (
                                            <>
                                                <input id="SalesTaxdoc" type="file" hidden onChange={(e) => onFileDrop(e, formik)} />

                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        cursor: "pointer",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Box sx={styles?.iconBox}>
                                                        <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                                    </Box>
                                                    <Typography
                                                        sx={{
                                                            marginLeft: "5px",
                                                            fontWeight: 600,
                                                            color: (theme) => theme.palette.primary.main
                                                        }}
                                                    >
                                                        ADD SALES TAX EXEMPTION CERTIFICATE
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                    </Stack>
                                </label>
                            )}

                            <ErrorMessage name="sales_tax_doc_url" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default SalesTaxExempt;
