import {Box, Menu, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddAddressForm from "./addAddressForm";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import {ErrorMessage} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import InfoModal from "commonComponent/DilogBox/InfoModal";
import {API, NetworkManager} from "network/core";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {getTzDateTime} from "utils/app-dates/dates";

const Address = (props) => {
    const {Parentformik, orderId} = props;

    const {headerData} = Parentformik.values;

    const addresslist = headerData?.customer?.customerAddresses;
    const selectedAddress = headerData?.deliveryTo;
    const matchingAddress = addresslist?.find((address) => address.delivery_address === selectedAddress);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openCancelModal, setCancelModal] = useState(false);
    const [dummyLocation, setDummyLocation] = useState(null);
    const [matchingAddressId, setMatchingAddressId] = useState("");
    const [openForm, setOpenForm] = useState(addresslist?.length === 0);
    const open = Boolean(anchorEl);
    console.log(Parentformik, "FORmIK");
    useEffect(() => {
        const selectedAddressId = matchingAddress ? matchingAddress.address_id : "";
        setMatchingAddressId(selectedAddressId);
    }, [matchingAddress]);

    useEffect(() => {
        Parentformik.setFieldValue("headerData.address_id", matchingAddressId ?? "");
    }, [matchingAddressId]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (openForm) {
            setOpenForm(false);
        }
        setAnchorEl(null);
    };

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };

    const handleAddressSelect = (obj) => {
        Parentformik.setFieldValue("headerData.deliveryTo", obj?.delivery_address);
        Parentformik.setFieldValue("headerData.longitude", obj?.longitude ?? "");
        Parentformik.setFieldValue("headerData.latitude", obj?.latitude ?? "");
        Parentformik.setTouched({"headerData.deliveryTo": true});
        Parentformik.setFieldValue("headerData.address_details", obj?.address_details ?? {});
        Parentformik.setFieldValue("headerData.address_id", obj?.address_id ?? "");
        Parentformik.setFieldValue("company", obj?.location_name ?? "");
        Parentformik.setFieldValue("phone", obj?.contact ?? "");
        Parentformik.setFieldValue("name", obj?.contact_name ?? "");
        Parentformik.setFieldValue("apartment", obj?.apartment_name ?? "");
        Parentformik.setFieldValue("addressChanged", true);
        handleClose();
        setCancelModal(false);
    };
    const handleCancelOrder = async () => {
        handleAddressSelect(dummyLocation);
    };

    const getBrandList = async (values) => {
        const instance = NetworkManager(API.BRAND.GETBRANDWITHADDRESSFILTER);
        const params = {
            orderBy: "ASC"
        };
        if (values.delivery_address !== "") {
            params.search = values.delivery_address.split(",").slice(0, -1).join(",") || values.delivery_address;
            params.latitude = values.latitude;
            params.longitude = values.longitude;
        }
        // if (Parentformik.values.headerData.when !== "") {
        //     params.deliveryDate = getTzDateTime(timezone, Parentformik.values.headerData.when);
        // }
        const response = await instance.request(params);
        if (response.success) {
            if (Parentformik.values.selectedBrand !== "") {
                const selectedBrandPresentInList = response.data?.filter((row) => row.brand_id === Parentformik.values.selectedBrand);
                if (selectedBrandPresentInList.length === 0) {
                    setCancelModal(true);
                } else {
                    handleAddressSelect(values);
                }
            }
        }
    };
    return (
        <>
            <Stack
                nowrap
                sx={{minWidth: 0, cursor: "pointer", flexShrink: 20}}
                spacing={0.5}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="muiStack_start"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography variant="OrderSectionHeading" sx={{whiteSpace: "nowrap"}}>
                    Deliver to
                </Typography>

                <Stack direction="row" spacing={1} mt="0px !important" alignItems="center">
                    <Typography
                        noWrap
                        variant="OrderSectionHeading"
                        fontWeight={400}
                        // {...(!selectedAddress && { sx: (t) => ({ color: t.palette.error.light }) })}
                    >
                        {selectedAddress ? ellipsizeText(selectedAddress, 20) : "Add address"}
                    </Typography>
                    <KeyboardArrowDownIcon fontSize="small" />
                </Stack>
                <ErrorMessage name="headerData.deliveryTo" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
            </Stack>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                {openForm ? (
                    <AddAddressForm handleClose={handleClose} Parentformik={Parentformik} orderId={orderId} />
                ) : (
                    <Box p={2} pb={0} pt={addresslist?.length > 0 ? 2 : 0}>
                        <Stack spacing={2}>
                            {addresslist?.length > 0 &&
                                addresslist?.map((obj, i) => (
                                    <Stack
                                        spacing={0.5}
                                        sx={{cursor: "pointer", mt: "2px !important"}}
                                        key={i}
                                        onClick={() => {
                                            if (Parentformik.values.itemDetail.length === 0) {
                                                handleAddressSelect(obj);
                                            } else if (Parentformik.values.itemDetail.length > 0) {
                                                setDummyLocation(obj);
                                                // getTimeZone(obj);
                                                getBrandList(obj);
                                            }
                                        }}
                                    >
                                        <Typography varient="headingOne" fontWeight={obj.delivery_address === selectedAddress ? 600 : 400}>
                                            {obj.delivery_address}
                                        </Typography>
                                    </Stack>
                                ))}
                        </Stack>

                        <Box
                            onClick={(e) => {
                                if (e.target.id === "symbol12" || e.target.id === "symbol13") {
                                    setOpenForm(!openForm);
                                }
                            }}
                        >
                            <SymbolButton title="Add new address" />
                        </Box>
                    </Box>
                )}
                <InfoModal
                    open={openCancelModal}
                    buttonTitle="Select brand"
                    title="Order is not deliverable."
                    description="Selected brand is not deliverable at the selected delivery address."
                    handleClose={handleCancelModal}
                    handleSubmit={handleCancelOrder}
                />
            </Menu>
        </>
    );
};

export default Address;
