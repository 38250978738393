import {Grid,} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import {API, NetworkManager} from "network/core";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Loader from "ui-component/Loader";
import {enforceFormat,} from "utils/helperFunctions/helpers";
import * as Yup from "yup";
import useLocationController from "../locations/locations.controller";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import CustomizedTabs from "commonComponent/NewTab";
import ServiceForm from "./Tabs/CateringServices";
import HomemealsServices from "./Tabs/HomemealsServices";

const validationSchema = Yup.object().shape({
    serviceFee: Yup.number().required("Service fee is required").max(100, "Service fee must be 100 or less"),
    minimumServiceFee: Yup.string().required("Minimum service fee is required"),
    largeOrderMinimum: Yup.string().required("Large order minimum is required"),
    largeOrderFees: Yup.array().of(
        Yup.object().shape({
            hours: Yup.number().required("Hours are required"),
            fee: Yup.number().required("Fee is required").max(100, "Fee must be 100 or less")
        })
    ),
    smallOrderFees: Yup.array().of(
        Yup.object().shape({
            hours: Yup.number().required("Hours are required"),
            fee: Yup.number().required("Fee is required").max(100, "Fee must be 100 or less")
        })
    )
});

const Services = () => {
    const [searchParam] = useSearchParams();
    const [initialValues, setInitialValues] = useState({
        serviceFee: "",
        minimumServiceFee: "",
        largeOrderMinimum: "",
        largeOrderFees: [{hours: "", fee: ""}],
        smallOrderFees: [{hours: "", fee: ""}]
    });
    const [originalData, setOriginalData] = useState(null);
    const marketName = searchParam.get("marketName");
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [serviceFess, setserviceFess] = useState(0);
    const [value, setValue] = useState("catering");
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const LC = useLocationController();

    function sortFeesByHoursDescending(fees) {
        return fees.sort((a, b) => b.hours - a.hours);
    }

    function sortOrderFees(data) {
        return {
            ...data,
            largeOrderFees: sortFeesByHoursDescending(data.largeOrderFees),
            smallOrderFees: sortFeesByHoursDescending(data.smallOrderFees)
        };
    }

    const transformValuesToPayload = (values) => {
        return {
            cateringRate: {
                service_fee: values.serviceFee,
                minimum_service_fee: values.minimumServiceFee
            },
            cancellationPolicy: {
                large_order_minimum: values.largeOrderMinimum,
                greater_than_order_minimum: values.largeOrderFees.map((fee) => ({
                    hours_before_delivery: fee.hours,
                    cancellation_fee: fee.fee
                })),
                less_than_order_minimum: values.smallOrderFees.map((fee) => ({
                    hours_before_delivery: fee.hours,
                    cancellation_fee: fee.fee
                }))
            }
        };
    };

    const getCateringService = async () => {
        setLoading(true);
        const instance = NetworkManager(API.SERVICE.GET);
        const response = await instance.request();
        const data = response.data;
        if (data) {
            setLoading(false);
        }
        const transformedData = {
            serviceFee: data.serviceFee.cateringRate.service_fee,
            minimumServiceFee: data.serviceFee.cateringRate.minimum_service_fee,
            largeOrderMinimum: data.serviceFee.cancellationPolicy.large_order_minimum,
            largeOrderFees: data.serviceFee.cancellationPolicy.greater_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee,
                isOld: true
            })),
            smallOrderFees: data.serviceFee.cancellationPolicy.less_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee,
                isOld: true
            })),
            isOld: true
        };
        setserviceFess(transformedData.serviceFee);

        const sortedData = sortOrderFees(transformedData);

        setInitialValues(sortedData);
        setOriginalData(sortedData);
    };

    useEffect(() => {
        getCateringService();
    }, []);

    const handleActionBtn = (index, arrayHelpers) => () => {
        arrayHelpers.remove(index);
    };
    const isChangable =
        WithPermission({module: "Services", permission: "update_permission"}) ||
        WithPermission({module: "Services", permission: "create_permission"});
    const isCreatable = WithPermission({module: "Services", permission: "create_permission"});
    const isEditable = WithPermission({module: "Services", permission: "update_permission"});
    const isDeletable = WithPermission({module: "Services", permission: "delete_permission"});
    const getFieldDisabled = (item) => {
        if (item?.isOld && isCreatable && !isEditable) {
            return true;
        } else if (!isChangable) {
            return true;
        } else {
            return false;
        }
    };
    const updateIntialValue = (newValues) => {
        const newInitvalues = {
            serviceFee: newValues.cateringRate.service_fee,
            minimumServiceFee: newValues.cateringRate.minimum_service_fee,
            largeOrderMinimum: newValues.cancellationPolicy.large_order_minimum,
            largeOrderFees: newValues.cancellationPolicy.greater_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee,
                isOld: true
            })),
            smallOrderFees: newValues.cancellationPolicy.less_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee,
                isOld: true
            })),
            isOld: true
        };
        setInitialValues(newInitvalues);
    };
    const handleSaveServiceFee = async (values) => {
        setLoading(true);
        const payload = transformValuesToPayload(values);
        const instance = NetworkManager(API.SERVICE.UPSERT);
        const response = await instance.request(payload);
        if (response) {
            updateIntialValue(payload);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setLoading(false);
        }
    };
    const TabsData = [
        {
            value: "catering",
            label: "Catering",
            comp: () => (
                <ServiceForm
                    loading={loading}
                    originalData={originalData}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    handleSubmit={handleSaveServiceFee}
                    loadingSave={loadingSave}
                    isChangable={isChangable}
                    isDeletable={isDeletable}
                    isCreatable={isCreatable}
                    isEditable={isEditable}
                    enforceFormat={enforceFormat}
                    getFieldDisabled={getFieldDisabled}
                    handleActionBtn={handleActionBtn}
                />
            )
        },
        {
            value: "homemeals",
            label: "Home meals",
            comp: () => <HomemealsServices />
        }
    ];
    const handleTabChange = (event, newValue) => {
        // setSearch("");
        setValue(newValue);
        // dispatch(resetState());
        const search = `?tab=${newValue}`;

        navigate({
            pathname: window.location.pathname,
            search: search
        });
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <TableTopFilterWrapper
                    lessmarginOnTop
                    pageheader={
                        <PageHeader
                            title={marketName || "Services"}
                            {...(LC.id && {
                                breadsCrumData: [
                                    {label: "Markets", href: "/markets"},
                                    {label: "Services", isActive: true}
                                ]
                            })}
                        />
                    }
                    handleSearch={LC.handleSearch}
                    filterType="locationFilter"
                    onlyHeading
                >
                    {loading && <Loader />}
                    <CustomizedTabs tabsInfo={TabsData} value={value} handleChange={handleTabChange} />
                </TableTopFilterWrapper>
            </Grid>
        </Grid>
    );
};

export default Services;
