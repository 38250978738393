import {useState} from "react";
import {useOrderModel} from "./order.model";
import {sortFunction} from "utils/helperFunctions/helpers";

export const useOrderController = () => {
    const [marketList, setMarketList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const staticParams = {
        page: 1,
        pageSize: 1000,
        sortBy: "createdAt",
        orderBy: "desc"
    };
    const model = useOrderModel();
    const getMarketList = async () => {
        const response = await model.getMarketList(staticParams);
        const tempData = [];
        if (response?.success) {
            response?.data?.marketList.forEach((el) => {
                tempData.push({id: el?.market_id, label: el?.market_name});
            });
        }
        setMarketList(sortFunction(tempData));
    };
    const getBrandList = async () => {
        const response = await model.getBrandList(staticParams);
        const tempData = [];
        if (response?.success) {
            response?.data?.brandList.forEach((el) => {
                tempData.push({id: el?.brand_id, label: el?.brand_name});
            });
        }
        setBrandList(sortFunction(tempData));
    };
    const getCustomerListing = async (searchedValue) => {
        const tempData = [];
        if (!searchedValue) {
            setCustomers([]);
        } else {
            const response = await model.getCustomerListing(searchedValue);
            if (response?.success) {
                response?.data?.customers.forEach((el) => {
                    tempData.push({id: el?.user_id, label: `${el?.full_name} ${el?.last_name}`});
                });
            }
            setCustomers(tempData);
        }
    };

    const getCompanyListing = async (searchedValue) => {
        if (searchedValue === "") {
            setCompanyList([]);
        }
        if (searchedValue) {
            const tempData = [];

            const response = await model.getCompanyListing(searchedValue);
            if (response?.success) {
                response?.data?.companyList.forEach((el) => {
                    tempData.push({id: el?.company_id, label: el?.company_name});
                });
            }
            setCompanyList(tempData);
        } else {
            setCompanyList([]);
        }
    };
    const getTabsData = (tab) => {
        // console.log(tab, "tab data");

        if (tab === "scheduled") {
            return () => [
                {id: "New", label: "New"},
                {id: "Driver Assigned", label: "Active"},
                // {id: "Active", label: "Active"},
                {id: "Needs Driver", label: "Needs driver"},
                {id: "Accepted,Active", label: "Accepted"},
                // {id: "Driver Assigned", label: "Driver assigned"},
                {id: "ON_THE_WAY", label: "On the way"},
                {id: "DELIVERED", label: "Delivered"}
            ];
        } else if (tab === "history") {
            return () => [{id: "Complete", label: "Complete"}];
        } else if (tab === "canceled") {
            return () => [{id: "Cancelled", label: "Canceled"}];
        } else if (tab === "rejected") {
            return () => [{id: "Rejected", label: "Rejected"}];
        } else if (tab === "quotes") {
            return () => [
                {id: "Quote Sent", label: "Quote sent"},
                {id: "QUOTE_REJECTED", label: "Quote rejected"},
                {id: "QUOTE_EXPIRED", label: "Quote expired"},
                {id: "QUOTE_EXPIRY_SOON", label: "Expiry soon"}

                // {id: "Draft", label: "Draft"}
            ];
        } else if (tab === "draft") {
            return () => [
                {id: "Draft", label: "Draft"}
                // {id: "Draft", label: "Draft"}
            ];
        } else {
            return () => [];
        }
    };

    return {
        getMarketList,
        marketList,
        getBrandList,
        brandList,
        getCustomerListing,
        getCompanyListing,
        customers,
        companyList,
        getTabsData,
        setCustomers
    };
};
