const formComponentsStyles = {
    mealContainer: {
        display: "flex",
        flexDirection: "column"
    },
    tableHeadCell: (index) => ({
        p: 0,
        pl: index === 0 ? 0 : 1,
        pb: 1,
        border: "none"
    }),
    base: {
        padding: 0,
        border: "none"
    },
    firstCell: {
        paddingLeft: 0
    },
    areaCell: {
        paddingLeft: 0,
        width: "100%",
        maxWidth: "18%",

        "@media (min-width: 900px)": {
            maxWidth: "26%"
        },
        "@media (min-width: 1200px)": {
            maxWidth: "22%"
        },
        "@media (min-width: 1536px)": {
            maxWidth: "18%"
        }

        // width: "20rem"
    },
    zipCodeCell: {
        // width: "25rem"
        width: "100%",
        maxWidth: "40%",
        "@media (min-width: 900px)": {
            maxWidth: "47%"
        },
        "@media (min-width: 1200px)": {
            maxWidth: "43%"
        },
        "@media (min-width: 1536px)": {
            maxWidth: "40%"
        }
    },
    dayCell: {
        // width: "20rem"
    },
    chip: {
        margin: 0.5,
        color: "#000000",
        backgroundColor: "#f0f0f0"
    },
    chipContainer: {
        maxWidth: "25rem"
    },
    addButton: {
        width: "120px"
    },
    actionCell: {
        verticalAlign: "middle",
        textAlign: "right"
    }
};

export default formComponentsStyles;
