import {Box, Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import React, {useState} from "react";
import {useStyles} from "../styles";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {useNavigate} from "react-router-dom";
import useTempDrawer from "hooks/useTempDrawer";
import CustomerResetPasswordForm from "./CustomerResetPasswordForm";

function SettingsSection({formik, profileId, customerData}) {
    const styles = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {toggleDrawer} = useTempDrawer();
    const [open, setOpen] = useState(false);
    const [isResetOpen, setIsResetOpen] = useState(false);

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.CUSTOMER.BULKDELETE);
        const response = await instance.request({customerIds: profileId});
        if (response.success) {
            navigate("/customers?tab=customer");
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        handleDeleteModal();
    };

    return (
        <Grid item xs={12}>
            <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}>Settings</Typography>
            <Grid container mt="16px">
                <Typography style={styles.paymetHeading}>NOTIFICATIONS</Typography>
                <Grid item xs={12}>
                    <Typography style={{...styles.notificationText, marginTop: "12px"}}>Receive order status update via</Typography>
                </Grid>
                <Grid container marginTop={2}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disableRipple
                                    icon={<img src={Checked} alt="checkbox" width="18px" height="18px" />}
                                    checkedIcon={<img src={Checked} alt="checkbox" width="18px" height="18px" />}
                                    // checked={emailRequiredChecked}
                                    // onChange={handleEmailRequiredChange}
                                />
                            }
                            label={<span style={styles.emailLabel}>Email (required)</span>}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={formik.values?.is_text}
                            onChange={(e) => formik.setFieldValue("is_text", e.target.checked)}
                            control={
                                <Checkbox
                                    disableRipple
                                    icon={<img src={Unchecked} alt="checkbox" width="18px" height="18px" />}
                                    checkedIcon={<img src={Checked} alt="checkbox" width="18px" height="18px" />}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        ...styles.labelFontSize,
                                        // color: "#66676E"
                                        color: formik.values?.is_text ? "#020A11" : "#66676E"
                                    }}
                                >
                                    Text message
                                </span>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container marginTop={3} gap={2}>
                    <Grid item>
                        <Typography style={styles.paymetHeading}>PASSWORD</Typography>
                        <Box marginTop={1.5}>
                            <Button
                                variant="outlined"
                                sx={styles.resetButton}
                                onClick={(e) => {
                                    toggleDrawer("right", "customerResetPasswordDrawer")(e);
                                    setIsResetOpen(true);
                                }}
                            >
                                {customerData?.is_verified ? "Reset password" : "Set password"}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography style={styles.paymetHeading}>DELETE ACCOUNT</Typography>
                        <Box marginTop={1.5}>
                            <Button sx={styles.deletButton} onClick={handleDeleteModal}>
                                Delete account
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <DeleteModal
                open={open}
                title={`Are you sure you want to delete ${ellipsizeText(
                    formik?.values?.first_name + (formik?.values?.last_name ? ` ${formik?.values?.last_name}` : "") || "N/A"
                )}?`}
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
            {isResetOpen && (
                <CustomerResetPasswordForm
                    parentFormik={formik}
                    setIsResetOpen={setIsResetOpen}
                    profileId={profileId}
                    customerData={customerData}
                />
            )}
        </Grid>
    );
}

export default SettingsSection;
