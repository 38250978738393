import * as Yup from "yup";

export const addNewCardValidation = Yup.object({
    name: Yup.string().required("Name is required").nullable().trim(),
    card_number: Yup.string().required("Card number is required").min(13).nullable().trim(),
    month: Yup.number()
        .typeError("Enter a valid month")
        .integer("Enter a month")
        .min(1, "Month must be between 1 and 12")
        .max(12, "Month must be between 1 and 12")
        .required("Month is required")
        .test('is-future-date', 'Invalid date', function (month) {
            const { year } = this.parent; 
            if (!month || !year) return true; 
            const currentYear = new Date().getFullYear() % 100; 
            const currentMonth = new Date().getMonth() + 1;
            if (year < currentYear || (year === currentYear && month < currentMonth)) {
                return false;
            }
            return true;
        }),
        
    year: Yup.number()
        .typeError("Enter a valid year")
        .positive("Enter a valid positive year")
        .integer("Enter a valid integer year")
        .min(new Date().getFullYear() % 100, "Year must be valid")
        .max(99, "Enter a valid two-digit year") // Assuming the maximum allowed is 99
        .required("Year is required"),
    // date: Yup.string()
    //     .test("valid-date", "Invalid date", (value) => {
    //         if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) {
    //             return false;
    //         }
    //         const [inputMonth, inputYear] = value.split("/");
    //         const currentYear = new Date().getFullYear() % 100;
    //         const currentMonth = new Date().getMonth() + 1;

    //         if (
    //             parseInt(inputYear, 10) < currentYear ||
    //             (parseInt(inputYear, 10) === currentYear && parseInt(inputMonth, 10) < currentMonth)
    //         ) {
    //             return false;
    //         }

    //         return true;
    //     })
    //     .required("Date is required")
    //     .nullable()
    //     .trim(),
    cvv: Yup.string().required("Cvv is required").nullable().trim()
})

export const resetPasswordValidation = Yup.object({
    password: Yup.string()
        .required("Current password is required")
        .trim()
        .matches(/^(?=.*[A-Z])/, "One uppercase required")
        .matches(/^(?=.*[!@#$%^&*])/, "One special case character required")
        .matches(/^(?=.*\d)/, "One number required")
        .min(7, "Password must be at least 7 characters")
        .max(32, "Password must not exceed 32 characters"),

    newPassword: Yup.string()
        .required("New password is required")
        .trim()
        .matches(/^(?=.*[A-Z])/, "One uppercase required")
        .matches(/^(?=.*[!@#$%^&*])/, "One special case character required")
        .matches(/^(?=.*\d)/, "One number required")
        .min(7, "Password must be at least 7 characters")
        .max(32, "Password must not exceed 32 characters"),

    confirmPassword: Yup.string()
        .required("Confirm new password is required")
        .trim()
        .oneOf([Yup.ref("newPassword")], "Password doesn't match")
});
export const customerResetPasswordValidation = Yup.object({
    newPassword: Yup.string()
        .required("New password is required")
        .trim()
        .matches(/^(?=.*[A-Z])/, "One uppercase required")
        .matches(/^(?=.*[!@#$%^&*])/, "One special case character required")
        .matches(/^(?=.*\d)/, "One number required")
        .min(7, "Password must be at least 7 characters")
        .max(32, "Password must not exceed 32 characters"),

    confirmPassword: Yup.string()
        .required("Confirm new password is required")
        .trim()
        .oneOf([Yup.ref("newPassword")], "Password doesn't match")
});

export const addNewHouseCardValidation = Yup.object({
    name: Yup.string().required("Name is required").nullable().trim(),
    cardType: Yup.string().required("Card type is required").nullable().trim(),
    paymentTerm: Yup.string().required("Payment term is required").nullable().trim(),

    checkNumber: Yup.string()

        .nullable()
        .trim()
        .when("cardType", {
            is: (cardType) => cardType === "checks",

            then: Yup.string().required("Card number is required").nullable().trim()
        })
});
