import {Box, Grid, Chip, TextField, Typography, Popover} from "@mui/material";
import React, {useState} from "react";
import {useStyles} from "./styles";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";
import {LocalizationProvider, PickersDay, StaticDatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import PlusIcon from "assets/images/icons/plus.png";
import {styled} from "@mui/material/styles";
import startOfDay from "date-fns/startOfDay";
import moment from "moment";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import ActionButton from "commonComponent/Apptable/ActionButton";

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "selected"
})(({theme, selected}) => ({
    ...(selected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark
        },
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%"
    })
}));

function SpecialMenuSection({formik}) {
    const styles = useStyles();
    const [newDates, setNewDates] = React.useState(formik.values.homeMealDaysDetails || []);
    // const [newDates, setNewDates] = React.useState(formik.values.homeMealDaysDetails || []);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const findDate = (dates, date) => {
        const dateTime = date.getTime();
        return dates.find((item) => item.getTime() === dateTime);
    };

    const findIndexDate = (dates, date) => {
        const dateTime = date.getTime();
        return dates.findIndex((item) => item.getTime() === dateTime);
    };

    const renderPickerDay = (date, selectedDates, pickersDayProps) => {
        if (!newDates) {
            return <PickersDay {...pickersDayProps} />;
        }

        const selected = findDate(newDates, date);

        return <CustomPickersDay {...pickersDayProps} disableMargin selected={selected} />;
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleAction =
        (...params) =>
        (e) => {
            const type = params[0];
            const row = params[1];

            switch (type) {
                case "Delete":
                    setNewDates((prevDates) => {
                        const updatedDates = prevDates.filter((date) => date !== row);
                        formik.setFieldValue("homeMealDates", updatedDates);
                        return updatedDates;
                    });
                    break;

                default:
                    break;
            }
        };

    return (
        <>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={4}>
                    <Typography sx={styles?.subHeader}>Availability</Typography>
                    <Box sx={{marginTop: "16px"}}>
                        <CustomNewDateTimePicker
                            dateTitle="Availability date"
                            timeTitle="Availability time"
                            label="Start date and time"
                            disablePast
                            placeholder="Availability date"
                            clearButton
                            handleClear={() => {
                                formik.setFieldValue(`homeMealAvailabilityDate`, null);
                            }}
                            value={formik.values.homeMealAvailabilityDate || null}
                            {...formik.getFieldProps("homeMealAvailabilityDate")}
                            onChange={(val) => {
                                formik.setFieldValue(`homeMealAvailabilityDate`, val);
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={4}>
                    <Typography sx={styles?.subHeader}>Cut-off</Typography>
                    <Box sx={{marginTop: "16px"}}>
                        <CustomNewDateTimePicker
                            dateTitle="Cut-off date"
                            timeTitle="Cut-off time"
                            label="Cut-off date and time"
                            disablePast
                            clearButton
                            placeholder="Cut-off date and time"
                            handleClear={() => {
                                formik.setFieldValue(`homeMealCutoffDate`, null);
                            }}
                            value={formik.values.homeMealCutoffDate || null}
                            {...formik.getFieldProps("homeMealCutoffDate")}
                            onChange={(val) => {
                                formik.setFieldValue(`homeMealCutoffDate`, val);
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={4}>
                    <Typography sx={styles?.subHeader}>Delivery days</Typography>
                    {newDates.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",

                                marginTop: "16px"
                            }}
                        >
                            {newDates.map((day, i) => (
                                <Box
                                    sx={{
                                        borderRadius: "4px",
                                        backgroundColor: "#FAFAFA",
                                        border: "1px solid rgba(0, 0, 0, 0.23)",
                                        padding: "22px 16px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                    key={i}
                                >
                                    <Typography sx={{fontSize: "14px", color: "rgba(0, 0, 0, 0.87)"}}>
                                        {moment(day).format("ddd, MMM D")}
                                    </Typography>
                                    <ActionButton row={day} ActionArray={["Delete"]} handleActionBtn={handleAction} />
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Grid container>
                        <Box
                            onClick={handleClick}
                            sx={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                marginTop: "16px"
                            }}
                        >
                            <Box sx={{height: "24px", width: "24px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                            </Box>
                            <Typography
                                sx={{
                                    marginLeft: "5px",
                                    fontWeight: 600,
                                    color: (theme) => theme.palette.primary.main
                                }}
                            >
                                Select days
                            </Typography>
                        </Box>
                    </Grid>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                    >
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    disablePast
                                    label="Week picker"
                                    value={newDates}
                                    onChange={(newValue) => {
                                        const array = [...newDates];
                                        const date = startOfDay(newValue);
                                        const index = findIndexDate(array, date);
                                        if (index >= 0) {
                                            array.splice(index, 1);
                                        } else {
                                            array.push(date);
                                        }
                                        formik.setFieldValue("homeMealDaysDetails", array);
                                        setNewDates(array);
                                    }}
                                    renderDay={renderPickerDay}
                                    renderInput={(params) => <TextField {...params} />}
                                    inputFormat="'Week of' MMM d"
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{display: "flex", gap: 2, padding: 2, justifyContent: "flex-end"}}>
                            <ButtonGrouping
                                btnprops={[
                                    {
                                        btnTitle: "Cancel",
                                        sx: (t) => ({color: t.palette.error.dark}),
                                        onClick: () => handleClose()
                                    },
                                    {
                                        btnTitle: "Save",
                                        variant: "contained",
                                        // disabled: formik.isSubmitting,
                                        sx: (t) => ({color: t.palette.background.paper}),
                                        onClick: () => handleClose()
                                        // onClick: formik.handleSubmit
                                    }
                                ]}
                            />
                        </Box>
                    </Popover>
                </Grid>
            </Grid>
        </>
    );
}

export default SpecialMenuSection;
