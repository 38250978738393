import React, {useState, useEffect} from "react";
import {Box, Button, Typography, IconButton, Popover, Grid} from "@mui/material";
import {startOfMonth, endOfMonth, eachWeekOfInterval, format, addMonths, subMonths} from "date-fns";
import PlusIcon from "assets/images/icons/plus.png";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import ActionButton from "commonComponent/Apptable/ActionButton";
import {IconChevronLeft, IconChevronRight} from "@tabler/icons";

const DynamicWeek = ({formik}) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [weeks, setWeeks] = useState([]);
    const [selectedWeeks, setSelectedWeeks] = useState(formik.values.homeMealWeekDetails || []);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    useEffect(() => {
        const firstDayOfMonth = startOfMonth(currentDate);
        const lastDayOfMonth = endOfMonth(currentDate);

        const dynamicWeeks = eachWeekOfInterval(
            {start: firstDayOfMonth, end: lastDayOfMonth},
            {weekStartsOn: 1} // Start weeks on Monday
        ).map((weekStart) => {
            const weekEnd = new Date(weekStart);
            weekEnd.setDate(weekStart.getDate() + 6);

            return {
                label: `Week of ${format(weekStart, "MMM d")} - ${format(weekEnd, "MMM d")}`,
                id: `${format(weekStart, "MM-dd")}`,
                startDate: format(weekStart, "yyyy-MM-dd"),
                endDate: format(weekEnd, "yyyy-MM-dd")
            };
        });

        setWeeks(dynamicWeeks);
    }, [currentDate]);

    const toggleSelection = (id, startDate, endDate, label) => {
        setSelectedWeeks((prev) => {
            const alreadySelected = prev.find((week) => week.id === id);

            const updatedWeeks = alreadySelected ? prev.filter((week) => week.id !== id) : [...prev, {id, startDate, endDate, label}];

            // Update Formik field value
            formik.setFieldValue("homeMealWeekDetails", updatedWeeks);

            return updatedWeeks;
        });
    };

    const handlePreviousMonth = () => {
        setCurrentDate((prevDate) => subMonths(prevDate, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate((prevDate) => addMonths(prevDate, 1));
    };

    const handleAction =
        (...params) =>
        (e) => {
            const type = params[0];
            const row = params[1];

            switch (type) {
                case "Delete":
                    setSelectedWeeks((prev) => {
                        const updatedWeeks = prev.filter((week) => week.id !== row?.id);
                        formik.setFieldValue("homeMealWeekDetails", updatedWeeks);
                        return updatedWeeks;
                    });

                    break;

                default:
                    break;
            }
        };
    return (
        <Box>
            <Box>
                {selectedWeeks.length > 0 && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "16px"
                        }}
                    >
                        {selectedWeeks.map((week) => (
                            <Box
                                sx={{
                                    borderRadius: "4px",
                                    backgroundColor: "#FAFAFA",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    padding: "22px 16px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                                key={week.id}
                            >
                                <Typography sx={{fontSize: "14px", color: "rgba(0, 0, 0, 0.87)"}}>{week.label}</Typography>
                                <ActionButton row={week} ActionArray={["Delete"]} handleActionBtn={handleAction} />
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
            <Grid container>
                <Box
                    onClick={handleClick}
                    sx={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center"
                    }}
                >
                    <Box sx={{height: "24px", width: "24px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                    </Box>
                    <Typography
                        sx={{
                            marginLeft: "5px",
                            fontWeight: 600,
                            color: (theme) => theme.palette.primary.main
                        }}
                    >
                        Select week(s)
                    </Typography>
                </Box>
            </Grid>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <Box
                    // ref={tooltipRef}
                    sx={{
                        padding: 2.5,
                        backgroundColor: "#FFF",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        alignItems: "center",
                        width: "300px"
                    }}
                >
                    <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                        <Typography variant="h6" sx={{textAlign: "left", flex: 1, fontSize: "14px", fontWeight: "500"}}>
                            {format(currentDate, "MMMM yyyy")}
                        </Typography>
                        <IconButton onClick={handlePreviousMonth}>
                            <IconChevronLeft />
                        </IconButton>

                        <IconButton onClick={handleNextMonth}>
                            <IconChevronRight />
                        </IconButton>
                    </Box>

                    <Box sx={{display: "flex", flexDirection: "column", gap: 1, width: "100%", maxHeight: "300px", overflowY: "auto"}}>
                        {weeks.map((week) => {
                            const isPastWeek = new Date(week.startDate) < new Date();
                            return (
                                <Button
                                    key={week.id}
                                    disabled={isPastWeek}
                                    onClick={() => toggleSelection(week.id, week.startDate, week.endDate, week.label)}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: selectedWeeks.some((selected) => selected.id === week.id)
                                            ? "#00AB3A !important"
                                            : "#EAEAEA",
                                        color: selectedWeeks.some((selected) => selected.id === week.id)
                                            ? "#fff !important"
                                            : "rgba(0, 0, 0, 0.28)",
                                        textTransform: "none",
                                        justifyContent: "flex-start",
                                        borderRadius: "50px",
                                        "&:hover": {
                                            backgroundColor: selectedWeeks.some((selected) => selected.id === week.id)
                                                ? "#008E2F"
                                                : "#D0D0D0",
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                                        }
                                    }}
                                >
                                    {week.label}
                                </Button>
                            );
                        })}
                        <Box sx={{display: "flex", gap: 2, marginTop: 2, justifyContent: "flex-end"}}>
                            <ButtonGrouping
                                btnprops={[
                                    {
                                        btnTitle: "Cancel",
                                        sx: (t) => ({color: t.palette.error.dark}),
                                        onClick: () => handleClose()
                                    },
                                    {
                                        btnTitle: "Save",
                                        variant: "contained",
                                        // disabled: formik.isSubmitting,
                                        sx: (t) => ({color: t.palette.background.paper}),
                                        onClick: () => handleClose()
                                        // onClick: formik.handleSubmit
                                    }
                                ]}
                            />
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
};

export default DynamicWeek;
