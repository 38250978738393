import {Menu, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {getFormattedTimeinAMPM} from "utils/app-dates/dates";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";
import {ErrorMessage} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import InfoModal from "commonComponent/DilogBox/InfoModal";
import {API, NetworkManager} from "network/core";

const DateTime = (props) => {
    const {Parentformik, deliveryDateRef} = props;
    const {headerData} = Parentformik.values;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openCancelModal, setCancelModal] = useState(false);
    const [dummyLocation, setDummyLocation] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };

    const handleDateSelect = (val) => {
        Parentformik.setFieldValue("headerData.when", val);
        setCancelModal(false);
        // handleCancelModal()
        // handleClose();
    };

    const handleCancelOrder = async () => {
        handleDateSelect(dummyLocation);
    };

    const getBrandList = async (values) => {
        const instance = NetworkManager(API.BRAND.GETBRANDWITHADDRESSFILTER);
        const {headerData, selectedBrand} = Parentformik.values;
        const params = {
            orderBy: "ASC"
        };
        if (headerData.deliveryTo !== "") {
            params.search = headerData.deliveryTo.split(",").slice(0, -1).join(",") || headerData.deliveryTo;
            params.latitude = headerData.latitude;
            params.longitude = headerData.longitude;
        }

        const response = await instance.request(params);
        if (response.success) {
            const selectedBrandPresentInList = response.data?.filter((row) => row.brand_id === selectedBrand);
            if (selectedBrandPresentInList.length === 0) {
                setCancelModal(true);
            } else {
                handleDateSelect(values);
            }
        }
    };
    const formatDate = (dateString) => {
        const options = {
            month: "2-digit",
            day: "2-digit",
            weekday: "short"
        };
        return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
    };
    return (
        <>
            <Stack
                spacing={0.5}
                sx={{minWidth: 0, cursor: "pointer"}}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="muiStack_start"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography variant="OrderSectionHeading">When</Typography>

                <Stack direction="row" spacing={1} mt="0px !important" alignItems="center">
                    <Typography variant="OrderSectionHeading" fontWeight={400} sx={{whiteSpace: "nowrap"}}>
                        {headerData?.when
                            ? `${formatDate(headerData?.when)}, ${getFormattedTimeinAMPM(headerData?.when)}`
                            : "Add event date"}
                    </Typography>
                    <KeyboardArrowDownIcon fontSize="small" />
                </Stack>
                <ErrorMessage name="headerData.when" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
            >
                {console.log(headerData?.when,"WHENEEE")}
                <CustomNewDateTimePicker
                    differentAnchor={anchorEl}
                    deliveryDateRef={deliveryDateRef}
                    disablePast
                    setDifferentAnchor={setAnchorEl}
                    value={headerData?.when || null}
                    {...Parentformik.getFieldProps("headerData.when")}
                    onChange={(val) => {
                        Parentformik.setFieldValue("headerData.when", val);
                        // if (Parentformik.values.itemDetail.length === 0) {
                        //     Parentformik.setFieldValue("headerData.when", val);
                        // }
                        // else if (Parentformik.values.itemDetail.length > 0) {
                        //     setDummyLocation(val)
                        //     getBrandList(val)
                        // }
                    }}
                />
            </Menu>
            <InfoModal
                open={openCancelModal}
                buttonTitle="Select brand"
                title="Order is not deliverable."
                description="Selected brand is not deliverable at the selected date and time."
                handleClose={handleCancelModal}
                handleSubmit={handleCancelOrder}
            />
        </>
    );
};

export default DateTime;
