export const useStyles = () => {
    return {
        form: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "85vh"
        },
        feeWrapper: {
            p: 2,
            mb: 3,
            borderRadius: "4px",
            border: "1px solid #DBDBDB"
        },
        heading: {
            color: "#000",
            fontFamily: "Inter",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "-0.7px",
            marginBottom: "27px"
        },
        smallHeading:{
            color:"rgba(172, 172, 172, 1)",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.94px",
            pb:3
        }
    };
};
