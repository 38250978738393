import React, {useState} from "react";
import Tooltip from "commonComponent/Tooltip";
import Typography from "@mui/material/Typography";
import {ellipsizeText} from "./textUtils";

const WeekDisplay = ({menuData}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const menuDisplayWeek = [];
    menuData.menu_display.forEach((row) => {
        menuDisplayWeek.push(row);
    });

    const count = menuDisplayWeek.length;

    // Display the first 2 menu names for each menu category
    const initialMenuItems = menuDisplayWeek
        .slice(0, 2)
        .map((menu) => ellipsizeText(menu.label))
        .join(", ");

    // Display "View more" button and tooltip
    const tooltipContent = menuDisplayWeek
        .slice(2)
        .map((menu) => menu.label)
        .join(", ");

    return (
        <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
            <div>
                {initialMenuItems}

                {tooltipContent && (
                    <Tooltip
                        title={
                            <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
                                {tooltipContent}
                            </Typography>
                        }
                        arrow
                        followCursor
                        open={isTooltipOpen}
                        onOpen={() => setIsTooltipOpen(true)}
                        onClose={() => setIsTooltipOpen(false)}
                    >
                        <small style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500, fontSize: "11px"}}>
                            &nbsp;+{count - 2} more
                        </small>
                    </Tooltip>
                )}
            </div>
        </Typography>
    );
};

export default WeekDisplay;
