import {API, NetworkManager} from "network/core";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {generateUrl} from "utils/helperFunctions/helpers";

const profileFormSchema = {
    isActive: false,
    brandName: "",
    abbreviation: "",
    owner: "",
    brandServices: [],
    cuisine: "",
    menus: [],
    locations: [],
    defaultLocation: "",
    photos: []
};

const useProfileController = (props) => {
    const {editable} = props;
    const prm = useParams();
    const {id} = prm;
    const [initialValues, setInitialValues] = useState(profileFormSchema);
    // const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [menus, setMenus] = useState([]);
    const navigation = useNavigate();
    // const [searchParam] = useSearchParams();
    // const editable = searchParam.get("isEdit");

    const getProfileData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.BRAND.GETBYID(id));

        const response = await instance.request();

        if (response.success) {
            const data = response.data;
            const bd = data?.brand;

            const getDefaultLocationID = () => {
                const defaultKitchenLocation = bd?.brandKitchenLocation
                    ?.filter((el) => el?.is_default)
                    .map((el) => el?.kitchenLocation?.kitchen_location_id);

                return defaultKitchenLocation;
            };

            const generateSchema = {
                isActive: bd.is_active,
                brandName: bd.brand_name,
                abbreviation: bd.abbreviation,
                owner: bd.owner,
                cuisine: bd.cusine,
                brandServices: bd.brandServices.map((el) => el?.service_name),
                menus: bd?.brandMenu?.map((el) => el?.menu?.menu_id),
                locations: bd?.brandKitchenLocation?.map((el) => el?.kitchenLocation?.kitchen_location_id),
                defaultLocation: getDefaultLocationID()?.[0],
                photos: bd?.brand_logo ? [bd?.brand_logo] : []
            };
            setInitialValues(generateSchema);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }
        setLoading(false);
    };

    const getLocationData = async () => {
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc",
            is_active: true
        };

        const url = generateUrl(params);

        const instance = NetworkManager(API.LOCATION.GET);
        const response = await instance.request({}, [url]);

        if (response.success) {
            setLocations(response.data.kitchenLocationList);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getMenuDataUnassigned = async (tempMenu) => {
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc",
            // is_active: true,
            brand_id: id
        };

        const url = generateUrl(params);

        const instance = NetworkManager(API.MENU.GETUNASSIGNED);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const arr =response.data.menuList.map((el) => ({ label: el?.menu_name, id: el?.menu_id }))
            const temp=[...tempMenu,...arr]
            setMenus(temp);
        } 
    };

    const getMenuData = async () => {
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc",
            // is_active: true,
            brand_id: id
        };

        const url = generateUrl(params);

        const instance = NetworkManager(API.MENU.GET);
        const response = await instance.request({}, [url]);

        if (response.success) {
          const temp=  response.data.menuList.map((el) => ({ label: el?.menu?.menu_name, id: el?.menu_id }))
           setMenus(temp);
            getMenuDataUnassigned(temp);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    useEffect(() => {
        getMenuData();
        getLocationData();
        // if (editable) {
        //     setIsEdit(true);
        // }
        if (id) {
            getProfileData();
        }
    }, []);

    const handleSubmitProfileData = async (val, setSubmitting) => {
        setLoading(true);
        setSubmitting(true);

        const payload = {
            is_active: val.isActive,
            services: val.brandServices,
            cusine: val.cuisine,
            brandName: val.brandName,
            abbreviation: val.abbreviation,
            owner: val.owner,
            cuisine: val.cuisine,
            kitchen_location_id: val.locations,
            default_kitchen_location: val.defaultLocation,
            menu_id: val.menus,
            brand_logo: val.photos[0] ? val.photos[0] : ""
        };

        const instance = NetworkManager(API.BRAND.UPDATE(id));
        const response = await instance.request(payload);

        if (response.code === 200) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    transition: "Fade",
                    close: true
                })
            );
            navigation("/brands");
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }
        setSubmitting(false);
        setLoading(false);
    };

    return {
        loading,
        initialValues,
        // isEdit,
        // setIsEdit,
        locations,
        menus,
        handleSubmitProfileData
    };
};

export default useProfileController;
