import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

// material-ui
import {useTheme} from "@mui/material/styles";
import {Box, Chip, ClickAwayListener, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Typography} from "@mui/material";

// third-party
import {FormattedMessage} from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import useAuth from "hooks/useAuth";

// assets
import {IconLogout, IconUserCircle, IconSettings, IconChevronLeft} from "@tabler/icons";
import useConfig from "hooks/useConfig";
import StorageManager from "storage/StorageManager";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = (props) => {
    const theme = useTheme();
    const {drawerOpen} = props;
    const {borderRadius} = useConfig();
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const {logout, user} = useAuth();
    const [open, setOpen] = useState(false);
    const userName = StorageManager.get("user");
    const userImage = StorageManager.get("userImage");
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleListItemClick = (event, index, route = "") => {
        setSelectedIndex(index);
        // window.location.reload();
        handleClose(event);

        if (route && route !== "") {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    cursor: "pointer",
                    border: "none",
                    paddingLeft: "10px",
                    padding: drawerOpen ? 0 : "16px",
                    borderRadius : "4px",
                    maxWidth : "190px",
                    "& .MuiChip-icon": {
                        marginRight: drawerOpen ? "3px" : "-15px",
                        marginLeft: drawerOpen ? "12px" : "5px"
                    },
                    "& .MuiChip-label": {
                        fontWeight: 400,
                        fontSize: "0.875rem",
                        color: "#ffffff",
                        paddingLeft: "7px"
                    },
                    "&.MuiChip-root:hover": {
                        backgroundColor: "#354540 !important", // Set the hover background color to match the normal state
                        borderRadius: "4px"
                    }
                }}
                icon={userImage ? (
                    <img
                        src={userImage}
                        alt="User"
                        style={{
                            width: 28,
                            height: 28,
                            borderRadius: "50%",
                            objectFit: "cover"
                        }}
                    />
                ) : (
                    <IconUserCircle width={22} height={22} color="#ffffff" />
                )}
                variant="outlined"
                label={drawerOpen ? userName : ""}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                chipcolor="secondary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                sx={{margin: "17px !important", zIndex: 1210}}
                transition
                disablePortal
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({TransitionProps}) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper sx={{borderRadius: "4px"}}>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{p: 0, pt: 0}}>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: "80%",
                                                    maxWidth: 194,
                                                    minWidth: 194,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: "10px",
                                                    [theme.breakpoints.down("md")]: {
                                                        minWidth: "220px"
                                                    },
                                                    "& .MuiListItemButton-root": {
                                                        mt: 0.5
                                                    },

                                                    padding: 0
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${borderRadius}px`,
                                                        marginTop: "0px !important"
                                                    }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, "/settings?tab=account")}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="20px" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        sx={{color: "#26272A !important", marginLeft: "-10px"}}
                                                        primary={
                                                            <Typography
                                                                variant="body23"
                                                                sx={{
                                                                    "&:hover": {
                                                                        color: "#00ab3a"
                                                                    }
                                                                }}
                                                            >
                                                                <FormattedMessage id="account-settings" />
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{borderRadius: `${borderRadius}px`, marginTop: "0px !important"}}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="20px" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        sx={{color: "#26272A !important", marginLeft: "-10px"}}
                                                        primary={
                                                            <Typography
                                                                variant="body23"
                                                                sx={{
                                                                    "&:hover": {
                                                                        color: "#00ab3a"
                                                                    }
                                                                }}
                                                            >
                                                                <FormattedMessage id="logout" />
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
