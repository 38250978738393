import PropTypes from "prop-types";
import {forwardRef, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";

// material-ui
import {useTheme} from "@mui/material/styles";
import {Avatar, Box, ButtonBase, Chip, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery} from "@mui/material";

// project imports
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import {useDispatch, useSelector} from "store";
import {activeID, activeItem, openDrawer} from "store/slices/menu";
import {generateUrl} from "utils/helperFunctions/helpers";
// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {NetworkManager, API} from "network/core";
import {resetState} from "store/slices/paginationSlice";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({item, level, parentId}) => {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const {layout, borderRadius} = useConfig();
    const [isHoveredItemId, setIsHoveredItemId] = useState(null);
    const [total, setTotal] = useState(0);

    const {selectedItem, drawerOpen} = useSelector((state) => state.menu);

    const isSelected = selectedItem.findIndex((id) => id === item.id) > -1;

    const getStatusScheduleOrderList = async () => {
        const instance = NetworkManager(API.ORDER.COUNT);
        const params = {
            offset: 0,
            status: "New,Needs Driver,Active",
            limit: 5000
        };
        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            setTotal(response.data.orderCount);
        }
    };

    const Icon = item?.icon;
    const activeIcon = item?.active;
    // Check if the browser is Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const itemIcon = item?.icon ? (
        !isSafari ? (
            <div
                size={drawerOpen ? "20px" : "24px"}
                style={{
                    // color: isSelected ? theme.palette.secondary.main : theme.palette.text.primary,
                    // transition: "opacity 0.3s ease-in-out",
                    // opacity:isSelected ? 1 : 0.6,
                    backgroundColor: isSelected ? "#00AB3A" : "#ffffff",
                    width: drawerOpen ? "20px" : "24px",
                    height: "25px",
                    "-webkitMask": `url(${Icon}) no-repeat center`,
                    mask: `url(${Icon}) no-repeat center`
                }}
            />
        ) : (
            <img
                src={isSelected ? activeIcon : Icon}
                alt="img-icon"
                style={{
                    color: "#000000",
                    transition: "opacity 0.3s ease-in-out",
                    filter: isHoveredItemId === item?.id ? "brightness(0.8)" : "brightness(1)"
                }}
            />
        )
    ) : (
        <FiberManualRecordIcon
            sx={{
                color: "transparent",
                width: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? "inherit" : "medium"}
        />
    );

    let itemTarget = "_self";
    if (item.target) {
        itemTarget = "_blank";
    }

    let listItemProps = {component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)};
    if (item?.external) {
        listItemProps = {component: "a", href: item.url, target: itemTarget};
    }

    const itemHandler = (id) => {
        dispatch(activeItem([id]));
        if (matchesSM) dispatch(openDrawer(false));
        dispatch(activeID(parentId));
        dispatch(resetState());
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split("/")
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem([item.id]));
            getStatusScheduleOrderList();
        }
        // eslint-disable-next-line
    }, [pathname]);

    const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
    const iconSelectedColor = theme.palette.mode === "dark" && drawerOpen ? "text.primary" : "secondary.main";

    return (
        <>
            {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    disableTouchRipple
                    disableRipple
                    className="transition"
                    // disableRipple={!drawerOpen}
                    sx={{
                        // zIndex: 1201,
                        // borderLeft: isSelected ? "4px solid #00ab3a" : "",
                        // borderRadius: `${borderRadius}px`,
                        // borderLeft: `4px solid ${isSelected ? "#00ab3a" : "#eee"}`,
                        mb: 1.5,
                        zIndex: 110,
                        pr: 0,
                        pt: 0.5,
                        pb: 0.5,
                        pl: drawerOpen ? `12px` : 1.25,
                        ...(drawerOpen &&
                            level === 1 &&
                            theme.palette.mode !== "dark" && {
                                "&:hover": {
                                    background: "#354540",
                                    borderRadius: "4px"
                                },
                                "&.Mui-selected": {
                                    background: "#354540",
                                    borderRadius: "4px",
                                    "&:hover": {
                                        background: "#354540",
                                        borderRadius: "4px"
                                    }
                                }
                            }),
                        ...((!drawerOpen || level !== 1) && {
                            py: level === 1 ? 0 : 1,
                            "&:hover": {
                                bgcolor: "transparent"
                            },
                            "&.Mui-selected": {
                                "&:hover": {
                                    bgcolor: "transparent"
                                },
                                bgcolor: "transparent"
                            }
                        })
                    }}
                    selected={isSelected}
                    onClick={() => itemHandler(item.id)}
                >
                    <ButtonBase sx={{borderRadius: `${borderRadius}px`}} disableRipple disableTouchRipple>
                        <ListItemIcon
                            sx={{
                                minWidth: level === 1 ? 30 : 18,
                                color: isSelected ? iconSelectedColor : textColor,

                                ...(!drawerOpen &&
                                    level === 1 && {
                                        borderRadius: `${borderRadius}px`,
                                        width: 46,
                                        height: 46,
                                        // border:"2px  solid red",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        "&:hover": {
                                            background: "#354540",
                                            borderRadius: "4px"
                                        },
                                        ...(isSelected && {
                                            background: "#354540",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                background: "#354540",
                                                borderRadius: "4px"
                                            }
                                        })
                                    })
                            }}
                        >
                            {itemIcon}
                        </ListItemIcon>
                    </ButtonBase>

                    {(drawerOpen || (!drawerOpen && level !== 1)) && (
                        <ListItemText
                            primary={
                                <Stack direction="row" alignItems="center">
                                    {" "}
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontWeight: isSelected ? 700 : 400,
                                            color: isSelected ? "#00AB3A" : "#ffffff",
                                            padding: "4px 0px",
                                            fontSize: "0.875rem",
                                            "&:hover": {
                                                color: isSelected ? "#00AB3A" : "#ffffff"
                                            }
                                        }}
                                        onMouseEnter={() => setIsHoveredItemId(item?.id)}
                                        onMouseLeave={() => setIsHoveredItemId(null)}
                                    >
                                        {item.title}
                                    </Typography>
                                    {item.counter && total > 0 && (
                                        <Box
                                            // mr={0.5}
                                            sx={{
                                                marginLeft: "auto",
                                                background: "#00ab3a",
                                                width: 22,
                                                color: "#E3F2FD",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "0.70rem",
                                                height: 22,
                                                borderRadius: "50%",
                                                marginRight: "15px"
                                            }}
                                        >
                                            {total}
                                        </Box>
                                    )}
                                </Stack>
                            }
                            secondary={
                                item.caption && (
                                    <Typography variant="caption" sx={{...theme.typography.subMenuCaption}} display="block" gutterBottom>
                                        {item.caption}
                                    </Typography>
                                )
                            }
                        />
                    )}
                    {drawerOpen && item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            ) : (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    sx={{
                        borderRadius: 0,
                        mb: 0.5,
                        alignItems: "flex-start",
                        backgroundColor: level > 1 ? "transparent !important" : "inherit",
                        py: 1,
                        pl: 2,
                        "&:hover img": {
                            // Change color on hover
                            color: "red"
                        }
                    }}
                    selected={isSelected}
                    onClick={() => itemHandler(item.id)}
                >
                    <ListItemIcon
                        sx={{
                            my: "auto",
                            minWidth: !item?.icon ? 18 : 36
                        }}
                    >
                        {itemIcon}
                    </ListItemIcon>

                    <ListItemText
                        primary={
                            <Typography variant={isSelected ? "h5" : "body1"} sx={{fontWeight: isSelected ? 600 : 400}}>
                                {item.title}
                            </Typography>
                        }
                        secondary={
                            item.caption && (
                                <Typography variant="caption" sx={{...theme.typography.subMenuCaption}} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )
                        }
                    />

                    {item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )}
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
    parentId: PropTypes.string
};

export default NavItem;
