import React from "react";
import {Box, Typography} from "@mui/material";
import {useStyles} from "./chipStyles";

const ChipComponent = ({title}) => {
    const styles = useStyles();
    const Background = title === "Default" ? styles?.defaultBg : styles?.homeMealsBg;
    const Text = title === "Default" ? styles?.defaultText : styles?.homeMealsBg;
    return (
        <Box sx={[styles?.chip, Background]}>
            <Typography sx={[Text, styles?.textMain]}>{title}</Typography>
        </Box>
    );
};

export default ChipComponent;
