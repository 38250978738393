import {useTheme} from "@mui/material";

export const useStyles = () => {
    const theme = useTheme();
    return {
        subHeader: {
            fontSize: "18px",
            fontWeight: 600,
            color: theme.palette.other.darkBlack
        }
    };
};
