/* eslint-disable no-restricted-globals */
import React, {useState} from "react";
import useCreateLocationModal from "./CreateLocation.modal";
import {convertIsoToCST, convertToLocal, convertToUTC, getHHMMTime, getTzDateTime, getformateDate} from "utils/app-dates/dates";
import {useParams, useSearchParams} from "react-router-dom";
import {formatFetchedPhoneNumber} from "utils/helperFunctions/helpers";
import {API, NetworkManager} from "network/core";
import moment from "moment";
import "moment-timezone";

const fedHolidays = require("@18f/us-federal-holidays");

const formSchema = {
    locationDetails: {
        name: "",
        address: "",
        address_details: {},
        phone: "",
        market: "",
        locationType: "",
        revenueShare: "",

        services: [],
        orderType: []
    },
    brandsmarket: [],
    hoursOfOperation: {
        standardHours: [
            {
                isChecked: false,
                day: "Sunday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Monday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Tuesday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Wednesday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Thursday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Friday",
                startTime: "",
                endTime: ""
            },
            {
                isChecked: false,
                day: "Saturday",
                startTime: "",
                endTime: ""
            }
        ],
        holidayClosures: []
    },
    temporaryClosures: {
        isActive: false,
        cloures: [
            {
                from: "",
                startingAt: "",
                through: "",
                endingAt: ""
            }
        ]
    },
    leadTime: {
        knowAboutOrders: "",
        cuttOffTime: ""
    },
    delivery: [{deliveryFee: "", minimumOrder: "", radius: ""}],
    homeMealDelivery: [{area: "", zipCode: [], days: []}],
    setTime: "15",
    fees: {
        salesTaxRate: "",
        serviceFee: "",
        minimumServiceFee: ""
    }
};

const useCreateLocationController = () => {
    const prm = useParams();
    const {id, type} = prm;
    const [searchParam] = useSearchParams();
    const marketId = searchParam.get("marketId");

    const [initialVlaues, setInitialValue] = React.useState(formSchema);
    const [marketList, setMarketList] = React.useState([]);
    const [brandList, setBrandList] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState();
    const [holidays, setHolidays] = React.useState([]);
    const [isEdit, setIsEdit] = React.useState(false);
    const [readOnly, setReadyOnly] = useState(false);
    const [loading, setLoading] = React.useState(id || false);
    const [locationName, setLocationName] = React.useState("Edit location");
    const [timezone, setTimezone] = useState(null);
    const CLM = useCreateLocationModal({setLoading});

    // holidayList
    const BASE_CALENDAR_URL = "https://www.googleapis.com/calendar/v3/calendars";
    const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY = "holiday@group.v.calendar.google.com";
    const API_KEY = process.env.REACT_APP_GOOGLE_MAP_AUTOCOMPLETE;
    const CALENDAR_REGION = "en.usa";
    const currentYear = new Date().getFullYear();
    const timeMin = new Date(`${currentYear}-01-01`).toISOString();
    const timeMax = new Date(`${currentYear}-12-31`).toISOString();

    const getMarketListData = async () => {
        const data = await CLM.getAllMarkets(pageNumber);
        setMarketList([...marketList, ...data.marketList]);
        setPageNumber(data.pagination.currentPage + 1);
        setTotalPage(data.pagination.totalPages);
    };

    const loadMoremarkets = (event) => {
        if (event.target.scrollTop + 200 >= event.target.scrollHeight) {
            if (totalPage >= pageNumber) {
                getMarketListData();
            }
        }
    };

    const getBrandListData = async () => {
        const data = await CLM.getAllBrands(pageNumber);
        setBrandList([...brandList, ...data.brandList]);
        setPageNumber(data.pagination.currentPage + 1);
        setTotalPage(data.pagination.totalPages);
    };

    const getHolidayList = () => {
        const options = {shiftSaturdayHolidays: true, shiftSundayHolidays: true};
        const holidays = fedHolidays.allForYear(new Date().getFullYear(), options);
        const formattedResponse = holidays?.map((list) => {
            return {summary: list.name, start: {date: list.date}, end: {date: list.date}};
        });
        setHolidays(formattedResponse);
    };
    const convertTimeToTZ = (time, timezone) => {
        const formattedDate = moment.tz(time, timezone?.timeZoneId);
        const formatted = formattedDate.format("ddd, MM/DD/YYYY, h:mm A");
        return formatted;
    };
    const getStandardHours = (arr1, arr2, timezone) => {
        const scheduleMap = new Map(arr2.map((item) => [item.day, item]));

        arr1.forEach((item) => {
            if (scheduleMap.has(item.day)) {
                const schedule = scheduleMap.get(item.day);
                const formattedStartDate = moment.tz(item.start_time, timezone?.timeZoneId);
                const formattedEndDate = moment.tz(item.end_time, timezone?.timeZoneId);
                const startingAtTime = formattedStartDate.format("ddd, MM/DD/YYYY, h:mm A");
                const endingAtTime = formattedEndDate.format("ddd, MM/DD/YYYY, h:mm A");
                schedule.startTime = startingAtTime;
                schedule.endTime = endingAtTime;
                schedule.isChecked = true;
                scheduleMap.set(item?.day, schedule);
            }
        });
        return Array.from(scheduleMap.values());
    };

    const getHoldidayClosuers = (arr1, arr2) => {
        const finalArr = arr2.map((obj) => {
            const indexx = arr1.findIndex((obj2) => getformateDate(obj2.start_date) === getformateDate(obj.start.date));

            if (indexx === -1) {
                return {
                    isChecked: false,
                    ...obj
                };
            }
            return {
                isChecked: true,
                summary: obj?.summary,
                start: {date: convertToLocal(arr1[indexx].start_date)},
                end: {date: convertToLocal(arr1[indexx].end_date)}
            };
        });

        return finalArr;
    };
    const segregateTemporaryandHolidayClosures = (closures, objectTz) => {
        const temporaryClosures = [];
        const holidayClosures = [];
        let isTempCloActive = false;
    
        closures.forEach((obj) => {
            if (obj.type === "Fixed") {
                holidayClosures.push(obj);
            } else {
                if (obj.is_active) {
                    isTempCloActive = true;
                }

                const formattedStartDate = moment.tz(obj.start_date, objectTz?.timeZoneId);
                const formattedEndDate = moment.tz(obj.end_date, objectTz?.timeZoneId);

                // Format: Fri, 12/27/2024, 8:00 PM
                const startingAtTime = formattedStartDate.format("ddd, MM/DD/YYYY, h:mm A");
                const endingAtTime = formattedEndDate.format("ddd, MM/DD/YYYY, h:mm A");

                temporaryClosures.push({
                    from: formattedStartDate.format("YYYY-MM-DD"),
                    startingAt: startingAtTime,
                    through: formattedEndDate.format("YYYY-MM-DD"),
                    endingAt: endingAtTime
                });
            }
        });
        console.log(temporaryClosures);
        return {
            temporaryClosures,
            holidayClosures,
            isTempCloActive
        };
    };
    
    
    const handleServiceAndOrderType = (data, type) => {
        const arr = [null, null];
        data.forEach((el) => {
            if (type === "service") {
                if (el?.service === "Catering") {
                    arr[0] = el.service;
                } else {
                    arr[1] = el.service;
                }
            } else if (el?.order_type === "Delivery") {
                arr[0] = el.order_type;
            } else {
                arr[1] = el.order_type;
            }
        });

        return arr;
    };

    const getTimeZone = async (lat, lon) => {
        const instance = NetworkManager(API.ORDER.GETTIMEZONE);
        const response = await instance.request({}, [`?latitude=${lat}&longitude=${lon}`]);
        let tz = "";
        if (response.success) {
            tz = response.data.timeZoneId;
        } else {
            tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        setTimezone(tz);
    };

    const fetchExistingLocationData = async (id) => {
        const data = await CLM.getLocationDataByID(id);
        const KL = data.kitchenLocationList;
        setLocationName(KL.name);
        console.log(KL, "datata");
        const standardHoursSortedData = getStandardHours(KL.standardHours, formSchema.hoursOfOperation.standardHours, KL?.timezone);
        const {temporaryClosures, holidayClosures, isTempCloActive} = segregateTemporaryandHolidayClosures(KL.closureHours, KL?.timezone);
        getTimeZone(KL.latitude, KL.longitude);

        const createFormDataSchema = {
            locationDetails: {
                name: KL.name || "",
                address: KL.address,
                address_details: KL.address_details,
                phone: formatFetchedPhoneNumber(KL.phone) || "",
                market: KL.market_id || "",
                locationType: KL.location_type || "",
                revenueShare: KL.revenue_share ? Number(KL.revenue_share).toFixed(1) : "",
                services: handleServiceAndOrderType(KL.kitchenLocationService, "service"),
                orderType: handleServiceAndOrderType(KL.kitchenLocationOrderType, "orderType")
            },
            brandsmarket: KL.brandKitchenLocation?.length ? KL.brandKitchenLocation?.map((el) => el.brand_id) : [],
            hoursOfOperation: {
                standardHours: standardHoursSortedData,
                holidayClosures: getHoldidayClosuers(holidayClosures, holidays)
            },
            temporaryClosures: {
                isActive: KL.is_active,
                cloures: temporaryClosures?.length
                    ? temporaryClosures
                    : [
                          {
                              from: "",
                              startingAt: "",
                              through: "",
                              endingAt: ""
                          }
                      ]
            },
            leadTime: {
                knowAboutOrders: KL.lead_cut_off_day || "",
                cuttOffTime: convertTimeToTZ(KL.lead_cut_off_time, KL?.timezone) || null
            },
            delivery: KL.deliveryFee?.length
                ? KL.deliveryFee
                      ?.map((el) => ({
                          ...el,
                          deliveryFee: el?.delivery_fee,
                          minimumOrder: el?.order_minimum,
                          radius: Math.round(Number(el?.radius))
                      }))
                      ?.sort((a, b) => a.radius - b.radius)
                : [],
            homeMealDelivery: KL.homeMealArea?.length
                ? KL.homeMealArea?.map((el) => ({
                      ...el,
                      area: el?.area_name,
                      zipCode: Array.isArray(el?.homeMealAreaPinCode) ? el.homeMealAreaPinCode.map((pinObj) => pinObj?.pincode) : [],
                      days: Array.isArray(el?.homeMealAreaDay) ? el.homeMealAreaDay.map((dayObj) => dayObj?.day) : []
                  }))
                : [
                      {
                          area: "",
                          zipCode: [],
                          days: []
                      }
                  ],
            setTime: KL.setup_time || 15,
            fees: {
                salesTaxRate: KL.sales_tax_rate || "",
                serviceFee: Math.round(Number(KL.service_fee)) || "",
                minimumServiceFee: KL.minimum_service_fee || ""
            }
        };

        setInitialValue(createFormDataSchema);
    };

    React.useEffect(() => {
        getBrandListData();
        getMarketListData();
        getHolidayList();
    }, []);

    React.useEffect(() => {
        setInitialValue({
            ...initialVlaues,
            locationDetails: {
                ...initialVlaues.locationDetails,
                ...(marketId && {market: `${marketId}`})
            },
            hoursOfOperation: {
                ...initialVlaues.hoursOfOperation,
                holidayClosures: [...holidays.map((obj) => ({isChecked: false, ...obj}))]
            }
        });
        if (id && holidays.length && brandList.length) {
            fetchExistingLocationData(id);
            setIsEdit(true);
            setReadyOnly(type === "locationDetail");
        }
    }, [holidays, brandList]);

    const prepareClosureHoursPayload = (temporaryClosures, holidayClosures) => {
        let finalArray = [];
        const data1 = holidayClosures
            .filter((obj) => obj.isChecked === true)
            .map((obj) => ({
                holiday_date: getTzDateTime(timezone, new Date(obj.start.date)),
                description: "",
                type: "Fixed",
                start_date: getTzDateTime(timezone, new Date(obj.start.date)),
                end_date: getTzDateTime(timezone, new Date(obj.end.date))
            }));
        console.log(temporaryClosures, "temporaryClosures");

        const data2 = temporaryClosures.cloures.map((obj) => {
            const date1 = getformateDate(obj?.from, "mm/dd/yyyy");
            const time1 = getHHMMTime(obj?.startingAt);
            const date2 = getformateDate(obj?.through, "mm/dd/yyyy");
            const time2 = getHHMMTime(obj?.endingAt);

            const date001 = new Date(`${date1} ${time1}`);
            const date002 = new Date(`${date2} ${time2}`);
            console.log(date001, date002, 381381);
            return {
                holiday_date: !isNaN(date002.getTime()) ? getTzDateTime(timezone, date002) : "",
                description: "",
                type: "Temporary",
                start_date: !isNaN(date001.getTime()) ? getTzDateTime(timezone, date001) : "",
                end_date: !isNaN(date002.getTime()) ? getTzDateTime(timezone, date002) : ""
            };
        });
        finalArray = data2.filter((data) => data.holiday_date !== null);

        return [...data1, ...finalArray];
    };

    const handleSubmitForm = async (values, setSubmitting) => {
        // setSubmitting(true);

        const {locationDetails, brandsmarket, hoursOfOperation, temporaryClosures, leadTime, delivery, homeMealDelivery, fees} = values;
        console.log(hoursOfOperation, "hoursOfOperation");
        const payload = {
            LocationDetails: {
                setup_time: values?.setTime,
                name: locationDetails.name,
                address: locationDetails.address,
                latitude: locationDetails.latitude,
                longitude: locationDetails.longitude,
                address_details: locationDetails.address_details,
                phone: locationDetails.phone.replace(/[- )(]/g, ""),
                market_id: locationDetails.market,
                location_type: locationDetails.locationType,
                revenue_share: Number(locationDetails.revenueShare),
                lead_cut_off_day: leadTime.knowAboutOrders,
                lead_cut_off_time: getTzDateTime(timezone, leadTime.cuttOffTime),
                sales_tax_rate: Number(fees.salesTaxRate),
                service_fee: Number(fees.serviceFee),
                minimum_service_fee: Number(fees.minimumServiceFee)
            },
            service: locationDetails.services.filter((el) => el !== null),
            ordertype: locationDetails.orderType.filter((el) => el !== null),
            brand_kitchen_location_id: brandsmarket,
            standardHours: hoursOfOperation.standardHours
                .filter((obj) => obj.isChecked === true)
                .map((obj) => ({
                    day: obj.day,
                    start_time: getTzDateTime(timezone, obj.startTime),
                    end_time: getTzDateTime(timezone, obj.endTime)
                })),
            closureHours: prepareClosureHoursPayload(temporaryClosures, hoursOfOperation.holidayClosures),
            deliveryFee: delivery.map((obj) => ({
                order_minimum: Number(obj.minimumOrder),
                radius: Number(obj.radius),
                // need to remove
                delivery_fee: 0
            })),
            homeMealArea: homeMealDelivery.map((obj) => ({
                area_name: obj.area,
                pincode: obj.zipCode,
                day: obj.days
            }))
        };
        if (isEdit) {
            await CLM.handleEditFormSubmission(id, payload, setSubmitting);
        } else {
            await CLM.handleFormSubmission(payload, setSubmitting);
        }
    };

    return {
        initialVlaues,
        marketList,
        brandList,
        loadMoremarkets,
        handleSubmitForm,
        isEdit,
        id,
        loading,
        locationName,
        readOnly,
        setReadyOnly,
        getTimeZone,
        timezone
    };
};

export default useCreateLocationController;
