import {Box, Button, CircularProgress, Grid, InputAdornment, Typography} from "@mui/material";
import PlusIcon from "assets/images/icons/plus.png";
import ActionButton from "commonComponent/Apptable/ActionButton";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {ErrorMessage, FieldArray, Form, Formik} from "formik";
import {FormatCurrency} from "utils/helperFunctions/helpers";
import Loader from "ui-component/Loader";

const ServiceForm = ({
    loading,
    originalData,
    initialValues,
    validationSchema,
    handleSubmit,
    loadingSave,
    isChangable,
    isDeletable,
    isCreatable,
    isEditable,
    enforceFormat,
    getFieldDisabled,
    handleActionBtn
}) => {
    return (
        <>
            {loading && <Loader />}

            {(!!originalData || !loading) && (
                <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
                    {({values, handleChange, handleSubmit, handleBlur, errors, touched, resetForm}) => (
                        <Form
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                minHeight: "85vh"
                            }}
                        >
                            <Grid item xs={12} lg={9}>
                                <Box
                                    sx={{
                                        p: 2,
                                        mb: 3,
                                        borderRadius: "4px",
                                        border: "1px solid #DBDBDB"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#000",
                                            fontFamily: "Inter",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "normal",
                                            letterSpacing: "-0.7px",
                                            marginBottom: "27px"
                                        }}
                                        variant="h6"
                                    >
                                        Catering rates and fees
                                    </Typography>
                                    <Grid container item xs={12} md={6} columnGap={1} rowSpacing={1.5}>
                                        <Grid item xs>
                                            <CustomTextField
                                                label="Service fee %"
                                                fullWidth
                                                name="serviceFee"
                                                value={values.serviceFee} // Use Formik's values here directly
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="%"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="start" sx={{color: "#212121"}}>
                                                            %
                                                        </InputAdornment>
                                                    )
                                                }}
                                                inputProps={{maxLength: 3}}
                                                onKeyDown={enforceFormat}
                                                disabled={values?.isOld && !isEditable}
                                            />
                                            <ErrorMessage name="serviceFee" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs>
                                            <CustomTextField
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" sx={{color: "#212121"}}>
                                                            $
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onKeyDown={enforceFormat}
                                                label="Minimum service fee"
                                                name="minimumServiceFee"
                                                value={values.minimumServiceFee}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{maxLength: 10}}
                                                error={touched.minimumServiceFee && Boolean(errors.minimumServiceFee)}
                                                disabled={values?.isOld && !isEditable}
                                            />
                                            <ErrorMessage
                                                name="minimumServiceFee"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>
                                        <Grid item xs={1} />
                                    </Grid>
                                </Box>

                                <Box
                                    sx={{
                                        p: 2,
                                        mb: 3,
                                        borderRadius: "4px",
                                        border: "1px solid #DBDBDB"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#000",
                                            fontFamily: "Inter",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "normal",
                                            letterSpacing: "-0.7px",
                                            marginBottom: "27px"
                                        }}
                                        variant="h6"
                                    >
                                        Cancellation policy
                                    </Typography>
                                    <Grid container columnGap={1} rowSpacing={1.5}>
                                        <Grid item xs={10.75} sm={5.375} lg={2.62} xl={2.657} sx={{mb: 2}}>
                                            <CustomTextField
                                                label="Large order minimum"
                                                fullWidth
                                                name="largeOrderMinimum"
                                                value={values.largeOrderMinimum}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{maxLength: 10}}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" sx={{color: "#212121"}}>
                                                            $
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onKeyDown={enforceFormat}
                                                disabled={values?.isOld && !isEditable}
                                            />

                                            <ErrorMessage
                                                name="largeOrderMinimum"
                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                sx={{
                                                    color: "#000",
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: 600,
                                                    lineHeight: "normal",
                                                    letterSpacing: "-0.7px",
                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                Orders &ge; {FormatCurrency(values.largeOrderMinimum)}
                                            </Typography>
                                            <FieldArray
                                                name="largeOrderFees"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {values.largeOrderFees.map((fee, index) => (
                                                            <Grid
                                                                container
                                                                columnGap={1}
                                                                rowSpacing={1.5}
                                                                key={index}
                                                                sx={{mt: 1}}
                                                                alignItems="flex-start"
                                                            >
                                                                {console.log(fee, 377)}
                                                                <Grid item xs>
                                                                    <CustomTextField
                                                                        label="Hours before delivery"
                                                                        fullWidth
                                                                        name={`largeOrderFees.${index}.hours`}
                                                                        value={fee.hours}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        onKeyDown={enforceFormat}
                                                                        inputProps={{maxLength: 3}}
                                                                        disabled={getFieldDisabled(fee)}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`largeOrderFees.${index}.hours`}
                                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <CustomTextField
                                                                        fullWidth
                                                                        name={`largeOrderFees.${index}.fee`}
                                                                        placeholder="%"
                                                                        label="Cancellation fee"
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="start" sx={{color: "#212121"}}>
                                                                                    %
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        inputProps={{maxLength: 3}}
                                                                        onKeyDown={enforceFormat}
                                                                        value={fee.fee}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        disabled={getFieldDisabled(fee)}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`largeOrderFees.${index}.fee`}
                                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1} style={{paddingTop: "1.6rem"}}>
                                                                    {isDeletable && (
                                                                        <ActionButton
                                                                            row={fee}
                                                                            ActionArray={["Delete"]}
                                                                            handleActionBtn={() => handleActionBtn(index, arrayHelpers)}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                        {isCreatable && (
                                                            <Button
                                                                sx={{marginTop: 2}}
                                                                startIcon={
                                                                    <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                                                }
                                                                onClick={() => arrayHelpers.push({hours: "", fee: ""})}
                                                            >
                                                                Add new fee
                                                            </Button>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                sx={{
                                                    color: "#000",
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: 600,
                                                    lineHeight: "normal",
                                                    letterSpacing: "-0.7px",
                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                Orders &lt; {FormatCurrency(values.largeOrderMinimum)}
                                            </Typography>
                                            <FieldArray
                                                name="smallOrderFees"
                                                render={(arrayHelpers, remove) => (
                                                    <>
                                                        {values.smallOrderFees.map((fee, index) => (
                                                            <Grid
                                                                container
                                                                columnGap={1}
                                                                rowSpacing={1.5}
                                                                key={index}
                                                                sx={{mt: 1}}
                                                                alignItems="flex-start"
                                                            >
                                                                <Grid item xs>
                                                                    <CustomTextField
                                                                        label="Hours before delivery"
                                                                        fullWidth
                                                                        name={`smallOrderFees.${index}.hours`}
                                                                        value={fee.hours}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            touched.smallOrderFees?.[index]?.hours &&
                                                                            Boolean(errors.smallOrderFees?.[index]?.hours)
                                                                        }
                                                                        onKeyDown={enforceFormat}
                                                                        inputProps={{maxLength: 3}}
                                                                        disabled={getFieldDisabled(fee)}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`smallOrderFees.${index}.hours`}
                                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <CustomTextField
                                                                        fullWidth
                                                                        name={`smallOrderFees.${index}.fee`}
                                                                        placeholder="%"
                                                                        label="Cancellation fee"
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="start" sx={{color: "#212121"}}>
                                                                                    %
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                        error={
                                                                            touched.smallOrderFees?.[index]?.fee &&
                                                                            Boolean(errors.smallOrderFees?.[index]?.fee)
                                                                        }
                                                                        inputProps={{maxLength: 3}}
                                                                        onKeyDown={enforceFormat}
                                                                        value={fee.fee}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        disabled={getFieldDisabled(fee)}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`smallOrderFees.${index}.fee`}
                                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1} style={{paddingTop: "1.6rem"}}>
                                                                    {isDeletable && (
                                                                        <ActionButton
                                                                            row={fee}
                                                                            ActionArray={["Delete"]}
                                                                            handleActionBtn={() => handleActionBtn(index, arrayHelpers)}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                        {isCreatable && (
                                                            <Button
                                                                sx={{marginTop: 2}}
                                                                startIcon={
                                                                    <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                                                }
                                                                onClick={() => arrayHelpers.push({hours: "", fee: ""})}
                                                            >
                                                                Add new fee
                                                            </Button>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {(isChangable || isDeletable || isCreatable) && (
                                <Grid item xs={12}>
                                    <ButtonGrouping
                                        btnprops={[
                                            {
                                                btnTitle: "Cancel",
                                                sx: (t) => ({color: t.palette.error.dark}),
                                                onClick: () => {
                                                    // Reset the form to the original data
                                                    resetForm({values: originalData});
                                                }
                                            },
                                            {
                                                btnTitle: loadingSave ? <CircularProgress color="#ffffff" /> : "Save",
                                                variant: "contained",
                                                // disabled: formik.isSubmitting,
                                                sx: (t) => ({color: t.palette.background.paper}),
                                                onClick: handleSubmit
                                            }
                                        ]}
                                    />
                                </Grid>
                            )}
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default ServiceForm;
