import {Grid} from "@mui/material";
import React, {useEffect, useState, useContext} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CustomizedTabs from "commonComponent/NewTab";
import {useNavigate, useSearchParams} from "react-router-dom";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import useTempDrawerModifier from "hooks/useTempDrawerModifier";
import MenusMasterList from "./Tabs/MenuMasterList";
import ItemsMasterList from "./Tabs/ItemMasterList/ItemsMasterList";
import ModifierGroupMasterList from "./Tabs/ModifierGroupMasterList/ModifierGroupMasterList";
import {NetworkManager, API} from "network/core";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {CsvBuilder} from "filefy";
import {getFieldsForDownloadMenuItems, getFieldsForDownloadTemplate} from "commonComponent/Apptable/getFieldsForDownload";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {useMenuController} from "./menus.controller";
import Filter from "commonComponent/FilterAccoridan";
import FilterChips from "commonComponent/FilterChipsComponent";
import TemplatesList from "./Tabs/Templates";
import CategoryList from "./Tabs/Category";
import useToggleDilog from "hooks/useToggleDilog";
import BrandList from "../brands/index";
import {resetChildState, resetNestState, resetState, setServiceTypeFilter, setShowFilter} from "store/slices/paginationSlice";
import {FilterContext} from "contexts/FilterContext";

const Menus = () => {
    const {getFilterState, getColumnData, getBrandList, getCategoryList, getCuisine, filterChips, getTemplateList, getMenuList, type} =
        useMenuController();
    const [value, setValue] = React.useState("brand");
    const [btnlabel, setBtnLebel] = useState("Create brand");
    const {toggleDilog} = useToggleDilog();
    const [heading, setHeading] = useState("Brands");
    const [search, setSearch] = useState("");
    const showFilter = useSelector((state) => state?.pagination?.showFilter);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    const [urlSearchParams] = useSearchParams();
    const {toggleDrawer} = useTempDrawer();
    const {toggleDrawer: toggleDrawerModifier} = useTempDrawerModifier();
    const {openFilter, handleFilter} = useContext(FilterContext);
    const serviceType = useSelector((state) => state?.pagination?.serviceTypeFilter);
    const navigate = useNavigate();
    let tab = urlSearchParams.get("tab");
    const modifierId = urlSearchParams.get("id");
    const dispatch = useDispatch();
    const BrandCreatePermission = WithPermission({module: "Brands", permission: "create_permission"});
    const TemplateCreatePermission = WithPermission({module: "Templates", permission: "create_permission"});
    const CategoryCreatePermission = WithPermission({module: "Categories", permission: "create_permission"});
    const menuCreatePermission = WithPermission({module: "Menu", permission: "create_permission"});
    const itemCreatePermission = WithPermission({module: "Items", permission: "create_permission"});
    const modifierCreatePermission = WithPermission({module: "Modifiers", permission: "create_permission"});
    useEffect(() => {
        dispatch(resetChildState());
        dispatch(resetNestState());
    }, []);
    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            case "brand":
                setBtnLebel(BrandCreatePermission ? "Create brand" : null);
                setHeading("Brands");
                break;
            case "menuItems":
                setBtnLebel(itemCreatePermission ? "Create item" : null);
                setHeading("Menu items");
                break;
            case "modifierGroup":
                setBtnLebel(modifierCreatePermission ? "Create modifier group" : null);
                setHeading("Modifier groups");
                break;

            case "templates":
                setBtnLebel(TemplateCreatePermission ? "Create template" : null);
                setHeading("Templates");
                break;
            case "categories":
                setBtnLebel(CategoryCreatePermission ? "Create category" : null);
                setHeading("Categories");
                break;

            default:
                setBtnLebel(menuCreatePermission ? "Create menu" : null);
                setHeading("Menus");
                break;
        }
    };

    useEffect(() => {
        handleButtonLabelandFilter(tab);
        toggleDrawer("right", false)();
    }, [window.location.search]);

    const handleTabChange = (event, newValue) => {
        setSearch("");
        setValue(newValue);
        dispatch(resetState());
        navigate({
            pathname: window.location.pathname,
            search: `?tab=${newValue}`
        });
        setSelectedCheckbox([]);
        setUpdateListCount(1);
        dispatch(setServiceTypeFilter("Catering"));
    };

    const handleSearch = (val) => {
        setSearch(val);
    };

    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "Create brand": {
                dispatch(setShowFilter(false));
                toggleDrawer("right", "addNewBrand")(e);
                break;
            }
            case "Create modifier group": {
                dispatch(setShowFilter(false));
                toggleDrawerModifier("right", "addModifierGroup")(e);
                break;
            }
            case "Create item": {
                dispatch(setShowFilter(false));
                toggleDrawer("right", "itemEditSidebar")(e);
                break;
            }

            case "Create category": {
                dispatch(setShowFilter(false));
                toggleDrawer("right", "AddNewCategory")(e);
                break;
            }
            case "Create template": {
                toggleDilog("CreateTemplate")();
                dispatch(setShowFilter(false));
                break;
            }
            case "none": {
                dispatch(setShowFilter(!showFilter));
                handleFilter();
                break;
            }
            case "Create menu": {
                dispatch(setShowFilter(false));
                navigate(`/menus/menuDetail/?tab=menu&add=yes`);
                break;
            }
            default: {
                break;
            }
        }
    };

    const TabsData = [
        // WithPermission({module: "Brands", permission: "read_permission"}) && {
        //     value: "brand",
        //     label: "Brands",
        //     comp: () => (
        //         <BrandList
        //             search={search}
        //             filterChips={filterChips}
        //             setSelectedCheckbox={setSelectedCheckbox}
        //             selectedCheckbox={selectedCheckbox}
        //             updateListCount={updateListCount}
        //         />
        //     )
        // },
        WithPermission({module: "Menu", permission: "read_permission"}) && {
            value: "menu",
            label: "Menus",
            comp: () => <MenusMasterList search={search} filterChips={filterChips} serviceType={serviceType} />
        },
        WithPermission({module: "Items", permission: "read_permission"}) && {
            value: "menuItems",
            label: "Items",
            comp: () => (
                <ItemsMasterList
                    search={search}
                    filterChips={filterChips}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    serviceType={serviceType}
                />
            )
        },
        WithPermission({module: "Modifiers", permission: "read_permission"}) && {
            value: "modifierGroup",
            label: "Modifier groups",
            comp: () => (
                <ModifierGroupMasterList search={search} filterChips={filterChips} setShowFilter={setShowFilter} setHeading={setHeading} />
            )
        },
        WithPermission({module: "Categories", permission: "read_permission"}) && {
            value: "categories",
            label: "Categories",
            comp: () => (
                <CategoryList
                    handleHeaderActions={handleHeaderActions}
                    search={search}
                    // showFilter={showFilter}
                    filterChips={filterChips}
                    setShowFilter={setShowFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                />
            )
        },
        WithPermission({module: "Templates", permission: "read_permission"}) && {
            value: "templates",
            label: "Templates",
            comp: () => (
                <TemplatesList
                    handleHeaderActions={handleHeaderActions}
                    search={search}
                    // showFilter={showFilter}
                    filterChips={filterChips}
                    setShowFilter={setShowFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                />
            )
        }
    ];

    useEffect(() => {
        if (TabsData.length) {
            if (!TabsData[0]?.value) {
                for (let i = 0; i < TabsData.length; i += 1) {
                    if (TabsData[i] !== null) {
                        tab = TabsData[i].value;
                        console.log(TabsData.length, "mne tadatga");
                        break;
                    }
                }
            }
        }

        setValue(tab);
        navigate({
            pathname: window.location.pathname,
            search: `?tab=${tab}`
        });
        getBrandList();
        getTemplateList();
        getCategoryList();
        getMenuList();
        getCuisine();
    }, []);

    useEffect(() => {
        // is user edits the url query then we need to redirect it to default tab
        if (
            tab == null ||
            (tab !== "brand" &&
                tab !== "menu" &&
                tab !== "menuItems" &&
                tab !== "modifierGroup" &&
                tab !== "templates" &&
                tab !== "categories")
        ) {
            setValue("brand");
            setHeading("Brands");
            setBtnLebel("Create brand");
            const search = `?tab=${"brand"}`;

            navigate({
                pathname: window.location.pathname,
                search: search
            });
        }
    }, []);

    const handleDeleteBulk = async () => {
        const instance = NetworkManager(API.TEMPLATE.BULKDELETE);
        const payload = selectedCheckbox.map((row) => {
            const list = JSON.parse(row);
            return list.order_template_id;
        });

        const response = await instance.request({orderTemplateIds: payload});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setSelectedCheckbox([]);
            setUpdateListCount((pre) => pre + 1);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedCheckbox([]);
        // getUnplacedOrderList();
    };

    const csvBuilder = () => {
        let fileName;
        const columnData = getColumnData(value);

        if (value === "categories") {
            fileName = "Categories.csv";
        } else if (value === "templates") {
            fileName = "Templates.csv";
        }
        new CsvBuilder(fileName)
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                selectedCheckbox.map((row) =>
                    columnData.map((col) => {
                        const arr = JSON.parse(row);
                        return getFieldsForDownloadTemplate(col.key, arr);
                    })
                )
            )
            .exportFile();
        setSelectedCheckbox([]);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableTopFilterWrapper
                    {...(modifierId && {onlyHeading: true})}
                    lessmarginOnTop
                    pageheader={
                        <PageHeader
                            title={heading}
                            {...(modifierId && {
                                breadsCrumData: [
                                    {label: "Menu", href: "/menus?tab=menu"},
                                    {label: "Modifier group", href: "/menus?tab=modifierGroup"},
                                    {label: "Modifier group detail", isActive: true}
                                ]
                            })}
                        />
                    }
                    btnTitle={btnlabel}
                    handleClick={handleHeaderActions}
                    handleSearch={handleSearch}
                    filterType="none"
                    orderFilter="orderFilter"
                    onlySearch={heading === "Categories"}
                    selectedCheckbox={selectedCheckbox}
                    handleDeleteBulk={
                        WithPermission({module: "Menu", permission: "delete_permission"}) && heading !== "Categories" && handleDeleteBulk
                    }
                    deleteBulkTitle="items"
                    csvBuilder={csvBuilder}
                    showServiceDropdown={value === "menu" || value === "menuItems"}
                >
                    <Filter
                        filterState={getFilterState(value, serviceType)}
                        // setFilterState={setFilterChips}
                        filterChips={filterChips}
                        showFilter={showFilter}
                        isChild={type === "menuDetail"}
                    />

                    <FilterChips
                        filterChips={filterChips}
                        filterState={getFilterState(value, serviceType)}
                    />

                    <CustomizedTabs tabsInfo={TabsData} value={value} handleChange={handleTabChange} />
                </TableTopFilterWrapper>
            </Grid>
        </Grid>
    );
};

export default Menus;
