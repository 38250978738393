import {Box, Grid, Stack, Typography, FormControlLabel, Checkbox, Radio, RadioGroup, LinearProgress} from "@mui/material";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import DropDown from "commonComponent/Form/Fields/DropDown";
import {ErrorMessage, Form, Formik, FieldArray} from "formik";
import React, {useState} from "react";
import useMenuController from "./menuForm.controller";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import {useNavigate, useParams} from "react-router-dom";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import PlusIcon from "assets/images/icons/plus.png";
import ImageCropper from "commonComponent/ImageCropper/imageCropper";
import Dragger from "commonComponent/Dragger/index";
import * as yup from "yup";
import Divider from "@mui/material/Divider";
import CustomeDatePicker from "commonComponent/Form/Fields/CustomeDatePicker";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import AddCategory from "../category/addCategory";
import EditCategory from "../category/editCategoryForm";
import useTempDrawer from "hooks/useTempDrawer";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import UseSnackToast from "commonComponent/SnackToast";
import {Rowing} from "@mui/icons-material";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import MenuViewPage from "./menuViewPage";
import {convertToLocal, convertToUTC} from "utils/app-dates/dates";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import RadioIcon from "assets/images/icons/Bg-Radio.svg";
import CheckedIcon from "assets/images/icons/checkedIconRadio.svg";
import Loader from "ui-component/Loader";
import DynamicWeek from "./dynamicWeek";
import {generateUrl} from "utils/helperFunctions/helpers";
import SpecialMenuSection from "./specialMenuSection";
import moment from "moment";
import {MenuItemValidation} from "utils/validations/validations";
import {format, parseISO, startOfDay} from "date-fns";

const allowHomeMealsUI = process.env.REACT_APP_ALLOW_HOME;

const MenuForm = (props) => {
    const {setHeading = () => {}, editMenu} = props;
    const {toggleDrawer, state} = useTempDrawer();
    const PC = useMenuController();
    const prm = useParams();
    const {id, menuid} = prm;
    const {isEdit, setIsEdit} = PC;
    const navigation = useNavigate();
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [openFilter, setOpenFilter] = useState(false);
    const [editCategory, setEditCategory] = useState(false);
    const [itemDrawer, setItemDrawer] = React.useState(false);
    const [locationList, setLocationList] = React.useState([]);
    const [menuList, setMenuList] = React.useState([]);
    const [menuId, setMenuId] = React.useState(null);
    const [counter, setCounter] = React.useState(0);
    const [brandId, setBrandId] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const urlSearchParams = new URLSearchParams(window.location.search);

    const dispatch = useDispatch();
    const handleCancel = (formik) => {
        formik.resetForm();
        navigation("/menus?tab=menu");
    };

    const getLocation = async () => {
        // if (!id) {
        //     return;
        // }
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc",
            is_active: true
        };

        const url = generateUrl(params);
        const instance = NetworkManager(API.LOCATION.GET);

        const response = await instance.request({}, [url]);

        if (response.success) {
            const states = response.data?.kitchenLocationList?.map((state) => ({
                label: state.name,
                value: state.kitchen_location_id,
                id: state.kitchen_location_id
            }));
            setLocationList(states);
        }
    };
    const getBrandList = async () => {
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: 1,
            pageSize: 100,
            sortBy: "brand_name",
            orderBy: "asc",
            is_active: true,
            brand_id: id
        };

        const response = await instance.request({}, params);
        if (response.success) {
            const states = response.data?.brandList?.map((state) => ({
                label: state.brand_name,
                value: state.brand_id,
                id: state.brand_id
            }));
            setMenuList(states);
        }
    };

    React.useEffect(() => {
        getBrandList();
        getLocation();
    }, []);

    const handleAddCategory = (type, row, ind) => (e) => {
        setSelectedRow(row);
        setSelectedIndex(ind);

        switch (type) {
            case "add category": {
                setOpenFilter(false);
                setEditCategory(false);
                toggleDrawer("right", "AddNewCategory")(e);
                break;
            }
            case "edit category": {
                toggleDrawer("right", "EditNewCategory")(e);
                setOpenFilter(true);
                setEditCategory(true);

                break;
            }
            default:
                break;
        }
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };
    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const getMenuDataById = async (id) => {
        setLoading(true);
        const instance = NetworkManager(API.MENU.GETBYID(id));
        const response = await instance.request();
        const newSchema = {
            serviceType: "",
            homeMealMenuType: "",
            homeMealMenuNameInternal: "",
            homeMealMenuNameVisible: "",
            homeMealAvailabilityDate: null,
            homeMealCutoffDate: null,
            homeMealWeekDetails: [],
            homeMealDaysDetails: [],
            newMenuID: "",
            selectedMenu: "",
            menuName: "",
            cuisine: "",
            category: [],
            scheduleName: "",
            image: [],
            locations: [],
            frequency: [],
            startDate: new Date(),
            endDate: new Date(),
            noEndDate: false,
            repeatEvery: 1,
            recurring: "Day",
            recurringValues: [],
            ends: "never",
            start_value_on: new Date(),
            start_value_after: "",
            ends_value_on: null,
            ends_value_after: "",
            cut_off_hour: "",
            end_value_on: null,
            end_value_after: "",
            is_active: "false"
        };

        if (response.success) {
            const data = response.data;

            setHeading(data.menuList.menu_name);
            // getLocation(data.menuList.brand_id[0]);

            // get menu categories
            if (data?.menuList?.service_type === "Catering") {
                newSchema.cuisine = data.menuList.cusine;
                newSchema.cut_off_hour = data.menuList.cut_off_hour;
                newSchema.startDate = convertToLocal(data.menuList.start_date);
                newSchema.start_value_on = convertToLocal(data.menuList.start_on_date);
                newSchema.ends_value_on = convertToLocal(data.menuList.end_on_date);

                newSchema.endDate = convertToLocal(data.menuList.end_date);
                newSchema.noEndDate = data.menuList.no_end_date === null ? false : data.menuList.no_end_date;

                if (data.menuList.repeat_every) {
                    newSchema.repeatEvery = data.menuList.repeat_every;
                }
                if (data.menuList.schedule_name !== null) {
                    newSchema.scheduleName = data.menuList.schedule_name;
                }
                if (data.menuList.frequency !== null) {
                    newSchema.frequency = [data.menuList.frequency];
                }

                if (data.menuList?.menuScheduleRepeat) {
                    newSchema.recurringValues = data.menuList.menuScheduleRepeat.map((el) => el?.day);
                }
                if (data.menuList.repeat_duration !== null) {
                    newSchema.recurring = data.menuList.repeat_duration;
                }
                if (data.menuList.schedule_end_on === "Date") {
                    newSchema.ends = "on";
                    newSchema.end_value_on = new Date(data.menuList.end_on_date);
                } else if (data.menuList.schedule_end_on === "Occurance") {
                    newSchema.ends = "after";
                    newSchema.end_value_after = data.menuList.end_on_occurance;
                } else {
                    newSchema.ends = "never";
                }

                const instance2 = NetworkManager(API.MENU.GETBRANDBYID(id));
                const response2 = await instance2.request();
                if (response2.success) {
                    setLoading(false);
                    const data2 = response2.data.menuList[0];
                    newSchema.selectedMenu = [data2.brand_id];
                }
            }
            newSchema.serviceType = data?.menuList?.service_type;
            newSchema.newMenuID = id;
            newSchema.menuName = data.menuList.menu_name;
            newSchema.is_active = data.menuList.is_active;
            newSchema.image = data.menuList.menuImage?.map((row) => row.image);
            newSchema.locations = data.menuList.menuScheduleKitchenLocation?.map((el) => el?.kitchen_location_id);

            if (data?.menuList?.menu_type && data?.menuList?.menu_type !== null) {
                newSchema.homeMealMenuType = data?.menuList?.menu_type;
            }
            if (data?.menuList?.service_type === "Home Meals" && data?.menuList?.menu_name && data?.menuList?.menu_name !== null) {
                newSchema.homeMealMenuNameInternal = data?.menuList?.menu_name;
            }
            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Special" &&
                data?.menuList?.menu_name !== null
            ) {
                newSchema.homeMealMenuNameVisible = data?.menuList?.menu_name_external;
            }
            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Special" &&
                data?.menuList?.cut_off_date_time !== null
            ) {
                newSchema.homeMealCutoffDate = convertToLocal(data?.menuList?.cut_off_date_time);
            }
            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Special" &&
                data?.menuList?.menuHomeMealsSchedule !== null
            ) {
                newSchema.homeMealAvailabilityDate = convertToLocal(data?.menuList?.availability_date);
            }

            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Regular" &&
                data?.menuList?.menuHomeMealsSchedule &&
                data?.menuList?.menuHomeMealsSchedule.length > 0
            ) {
                const formattedWeeks = data?.menuList?.menuHomeMealsSchedule
                    .map(({startDate, endDate}) => {
                        const weekStart = parseISO(startDate);
                        const weekEnd = parseISO(endDate);

                        return {
                            label: `Week of ${format(weekStart, "MMM d")} - ${format(weekEnd, "MMM d")}`,
                            id: `${format(weekStart, "MM-dd")}`,
                            startDate: format(weekStart, "yyyy-MM-dd"),
                            endDate: format(weekEnd, "yyyy-MM-dd")
                        };
                    })
                    .sort((a, b) => new Date(a?.startDate) - new Date(b?.startDate));
                newSchema.homeMealWeekDetails = formattedWeeks;
            }
            if (
                data?.menuList?.service_type === "Home Meals" &&
                data?.menuList?.menu_type === "Special" &&
                data?.menuList?.menuHomeMealsSchedule &&
                data?.menuList?.menuHomeMealsSchedule.length > 0
            ) {
                newSchema.homeMealDaysDetails = data?.menuList?.menuHomeMealsSchedule.map((item) => startOfDay(new Date(item.startDate)));
            }

            // get menu categories
            const instance1 = NetworkManager(API.MENU.GETCATEGORYBYID(id));
            const response1 = await instance1.request();

            if (response1.success) {
                const menuCategory = response1.data?.menuList;
                if (menuCategory.length > 0) {
                    const tempObj = [];
                    menuCategory.sort((a, b) => a.order_by - b.order_by);
                    menuCategory.forEach((row) => {
                        const obj = {};
                        obj.label = row.category.category_name;
                        obj.value = row.category.category_name;
                        obj.order_by = row.order_by;
                        obj.description = row.category.description;
                        obj.menu_type = row?.category?.type ? row?.category?.type : [];
                        obj.id = row.category_id;
                        obj.catId = row?.menu_category_id;
                        obj.item = [];
                        row.menuCategoryItem.sort((a, b) => a.order_by - b.order_by);

                        row.menuCategoryItem
                            .filter((row) => row.item)
                            .forEach((col) => {
                                const tempItem = {};
                                tempItem.item_id = col.item_id;
                                tempItem.order_by = col.order_by;
                                tempItem.price = col.item.price;
                                tempItem.item_name = col.item.item_name;
                                tempItem.description = col.item.description;
                                tempItem.item_dietary = col.item.itemDietary;
                                obj.item.push(tempItem);
                            });
                        tempObj.push(obj);
                    });
                    newSchema.category = tempObj;
                }
                PC.updateInitialValues(newSchema);
            }
        } else {
            // dispatch(
            //     openSnackbar({
            //         open: true,
            //         message: response.message,
            //         variant: "alert",
            //         alert: {
            //             color: "error"
            //         },
            //         transition: "Fade",
            //         close: true
            //     })
            // );
        }
        setLoading(false);
    };

    React.useEffect(() => {
        const brandIdFromParams = urlSearchParams.get("brandId");
        const comesFromMenuEdit = urlSearchParams.get("edit");

        if (comesFromMenuEdit === "yes") {
            setIsEdit(true);
        }
        if (menuid) {
            // if menuid comes from url need to prefill all fields
            setMenuId(menuid);
            getMenuDataById(menuid);
        }
        if (brandIdFromParams !== null) {
            // if brandIdFromParams is present in url means user routed from brand details page to here
            // so need to update brand id to create new menu
            PC.initialValues.selectedMenu = [brandIdFromParams];
            setBrandId(brandIdFromParams);
            // getLocation(brandIdFromParams);
        } else if (brandIdFromParams === null) {
            PC.initialValues.selectedMenu = [];
            setBrandId(null);
        }
        if (menuid === undefined) {
            setIsEdit(true);
        }
    }, []);

    React.useEffect(() => {
        const comesFromMenuEdit = urlSearchParams.get("edit");
        if (comesFromMenuEdit === "yes") {
            setIsEdit(true);
        } else if (comesFromMenuEdit === undefined) {
            setIsEdit(false);
        }
    }, [urlSearchParams.get("edit")]);

    const handleCreateNewMenuOption = () => {
        PC.updateInitialValues(PC.profileFormSchema);
    };

    const handleAddMenu = async (values) => {
        const instance = NetworkManager(menuId !== null || values.newMenuID ? API.MENU.UPDATE(menuId || values.newMenuID) : API.MENU.ADD);

        const payload = {
            menuDetails: {
                service_type: values.serviceType,
                menu_name: values.serviceType === "Home Meals" ? values.homeMealMenuNameInternal : values.menuName,
                ...(values.newMenuID ? {is_active: values.is_active} : {is_active: "false"}),
                ...(values.serviceType === "Catering" && {cusine: values.cuisine}),
                ...(values.serviceType === "Catering" && {cut_off_hour: values.cut_off_hour}),
                ...(values.serviceType === "Catering" && {no_end_date: false}),
                ...(values.serviceType === "Home Meals" && {menu_type: values.homeMealMenuType}),
                ...(values.serviceType === "Home Meals" &&
                    values.homeMealMenuType === "Special" && {menu_name_external: values.homeMealMenuNameVisible}),
                ...(values.serviceType === "Home Meals" &&
                    values.homeMealMenuType === "Special" &&
                    values.newMenuID && {availability_date: convertToUTC(values.homeMealAvailabilityDate)}),
                ...(values.serviceType === "Home Meals" &&
                    values.homeMealMenuType === "Special" &&
                    values.newMenuID && {cut_off_date_time: convertToUTC(values.homeMealCutoffDate)})
            },
            image_url: values.image || [],
            kitchen_location_id: values.locations
        };

        if (values.serviceType === "Catering") {
            payload.brand_id = values.selectedMenu;
        }
        if (values.newMenuID && values.serviceType === "Catering") {
            if (!values.noEndDate && values.ends_value_on) {
                payload.menuDetails.end_on_date = !values.noEndDate ? convertToUTC(values.ends_value_on) : null;
            }
            if (values.start_value_on) {
                payload.menuDetails.start_on_date = convertToUTC(values.start_value_on);
                if (!values.noEndDate) {
                    payload.menuDetails.schedule_end_on = "Date";
                } else {
                    payload.menuDetails.schedule_end_on = "Date";
                }
            }
            if (values.recurringValues.length > 0) {
                payload.menuDetails.repeat_duration = "Day";
                payload.menuDetails.repeat_every = 1;
                payload.menuDetails.repeat_on = values.recurringValues;
            }
            payload.menuDetails.no_end_date = values.noEndDate;
            payload.menuDetails.frequency = values.recurringValues.length > 0 ? "Custom" : "Daily";
        }
        if (values.serviceType === "Home Meals" && values.newMenuID && values.homeMealMenuType === "Regular") {
            payload.homeMealScheduleDetails = values.homeMealWeekDetails.map((week) => {
                const startDate = moment(week?.startDate).startOf("day").toISOString();
                const endDate = moment(week?.endDate).endOf("day").toISOString();
                return {
                    start_date: startDate,
                    end_date: endDate
                };
            });
        }
        if (values.serviceType === "Home Meals" && values.newMenuID && values.homeMealMenuType === "Special") {
            payload.homeMealScheduleDetails = values.homeMealDaysDetails.map((date) => ({
                start_date: moment(date).format("YYYY-MM-DD 00:00:00"),
                end_date: moment(date).format("YYYY-MM-DD 23:59:59")
            }));
        }
        const categoryDetails = [];
        values.category.forEach((row, index) => {
            const tempObj = {};
            tempObj.category_id = row.id;
            tempObj.order_by = index;
            tempObj.items = [];
            row.item.forEach((item, ind) => {
                const tempItem = {};
                tempItem.order_by = ind;
                tempItem.item_id = item.item_id;
                tempObj.items.push(tempItem);
            });
            categoryDetails.push(tempObj);
        });
        payload.categoryItemDetails = categoryDetails;
        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: menuId !== null ? "Menu updated successfully" : "Menu created successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            if (values.newMenuID) {
                // navigation(`/menus/menuDetail/${values.newMenuID}/?tab=menu&edit=yes&menuName=${values.menuName}`);
                navigation(`/menus?tab=menu`);
            } else {
                const data = response.data.menu;
                PC.setInitialValues({...values, newMenuID: data.menu_id});
                navigation(`/menus/menuDetail/${data.menu_id}/?tab=menu&add=yes`);
            }
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    return (
        <>
            <Box sx={{height: "5px"}}>{loading && <LinearProgress />}</Box>
            {menuId === null || isEdit ? (
                <Formik
                    enableReinitialize
                    initialValues={PC.initialValues}
                    validationSchema={MenuItemValidation}
                    onSubmit={(val) => {
                        handleAddMenu(val);
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                {" "}
                                {PC.isLoading && <Loader />}
                                {!isEdit && (
                                    <Grid container sx={{mb: 1}}>
                                        <Grid item xs={12}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <CustomSwitch
                                                    checked={isEdit}
                                                    onChange={(e) => {
                                                        setIsEdit((pre) => !pre);
                                                    }}
                                                />
                                                <Typography>Enable Editing</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                )}
                                <Stack direction="row" flexWrap="wrap-reverse" spacing={3} mt={2}>
                                    <Box flex="1">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} container spacing={2}>
                                                <Grid item xs={4}>
                                                    <CustomSelect
                                                        noNone
                                                        label="Service type"
                                                        menuItems={[
                                                            ...(allowHomeMealsUI === "true"
                                                                ? [{label: "Home meals", id: "Home Meals"}]
                                                                : []),

                                                            {label: "Catering", id: "Catering"}
                                                        ]}
                                                        {...formik.getFieldProps("serviceType")}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("serviceType", e.target.value);
                                                        }}
                                                        InputLabelProps={{
                                                            style: {color: "#9E9E9E"}
                                                        }}
                                                        disabled={urlSearchParams.get("edit") === "yes"}
                                                    />

                                                    <ErrorMessage
                                                        name="serviceType"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Grid>
                                                {formik.values.serviceType === "Home Meals" && (
                                                    <Grid item xs={4}>
                                                        <CustomSelect
                                                            noNone
                                                            label="Menu type"
                                                            menuItems={[
                                                                {label: "Regular", id: "Regular"},
                                                                {label: "Special", id: "Special"}
                                                            ]}
                                                            {...formik.getFieldProps("homeMealMenuType")}
                                                            onChange={(e) => {
                                                                formik.setFieldValue("homeMealMenuType", e.target.value);
                                                            }}
                                                            InputLabelProps={{
                                                                style: {color: "#9E9E9E"}
                                                            }}
                                                            disabled={urlSearchParams.get("edit") === "yes"}
                                                        />

                                                        <ErrorMessage
                                                            name="homeMealMenuType"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {formik.values.serviceType === "Catering" && (
                                                <Grid item xs={12} container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <DropDown
                                                            disabled={brandId || !isEdit}
                                                            placeHolder="Brand"
                                                            options={menuList}
                                                            selectedItem={formik.values.selectedMenu}
                                                            handleChange={(val) => {
                                                                if (val.length > 0) {
                                                                    formik.setFieldValue("selectedMenu", [...val]);
                                                                    // getLocation(val[0]);
                                                                    // if (val[0] === "createNewMenu") {
                                                                    //     handleCreateNewMenuOption()
                                                                    // }
                                                                    // else {
                                                                    //     // setMenuId(val[0])
                                                                    //     getMenuDataById(val[0])
                                                                    // }
                                                                }
                                                            }}
                                                            MenuPaperProps={{sx: {width: 360, boxShadow: "0px 0px 3px rgba(0,0,0,0.2)"}}}
                                                            FormControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            optionsBoxProps={{sx: {overflowY: "hidden"}}}
                                                        />
                                                        <ErrorMessage
                                                            name="selectedMenu"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <CustomTextField
                                                            disabled={!isEdit}
                                                            fullWidth
                                                            placeholder="Menu"
                                                            label="Menu"
                                                            inputProps={{maxLength: 250}}
                                                            {...formik.getFieldProps("menuName")}
                                                        />
                                                        <ErrorMessage
                                                            name="menuName"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {formik.values.serviceType === "Catering" && (
                                                <Grid item xs={12} container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <CustomTextField
                                                            disabled={!isEdit}
                                                            fullWidth
                                                            placeholder="Cuisine"
                                                            label="Cuisine"
                                                            inputProps={{maxLength: 50}}
                                                            {...formik.getFieldProps("cuisine")}
                                                        />
                                                        <ErrorMessage
                                                            name="cuisine"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <CustomTextField
                                                            disabled={!isEdit}
                                                            fullWidth
                                                            type="number"
                                                            label="Cut-off time"
                                                            placeholder="Cut-off time"
                                                            {...formik.getFieldProps("cut_off_hour")}
                                                            onKeyDown={(evt) =>
                                                                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="cut_off_hour"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {formik.values.serviceType === "Home Meals" && (
                                                <Grid item xs={12} container spacing={2}>
                                                    {formik.values.homeMealMenuType === "Special" && (
                                                        <Grid item xs={4}>
                                                            <CustomTextField
                                                                disabled={!isEdit}
                                                                fullWidth
                                                                placeholder="Menu name (visible)"
                                                                label="Menu name (visible)"
                                                                inputProps={{maxLength: 50}}
                                                                {...formik.getFieldProps("homeMealMenuNameVisible")}
                                                            />
                                                            <ErrorMessage
                                                                name="homeMealMenuNameVisible"
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={4}>
                                                        <CustomTextField
                                                            disabled={!isEdit}
                                                            fullWidth
                                                            label="Menu name (internal)"
                                                            placeholder="Menu name (internal)"
                                                            {...formik.getFieldProps("homeMealMenuNameInternal")}
                                                        />
                                                        <ErrorMessage
                                                            name="homeMealMenuNameInternal"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {(formik.values.serviceType === "Catering" || formik.values.homeMealMenuType === "Special") && (
                                                <Grid item xs={12} container spacing={2}>
                                                    <Grid item xs={8}>
                                                        <Box
                                                            sx={{
                                                                borderRadius: "4px",
                                                                border: "1px solid #bdbdbd",
                                                                p: 2,
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <ImageCropper
                                                                image={formik.values.image}
                                                                isEdit={isEdit}
                                                                handleChange={(list) => {
                                                                    formik.setFieldValue(`image`, list);
                                                                }}
                                                                handleError={(msg = "Minimum 155KB file size is required") => {
                                                                    formik.setTouched({image: true});
                                                                    setTimeout(() => formik.setErrors({image: msg}), 100);
                                                                }}
                                                            />
                                                        </Box>
                                                        <ErrorMessage
                                                            name="image"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {formik.values?.newMenuID && (
                                                <>
                                                    <Grid item xs={12} container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={10}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <Box
                                                                onClick={(e) => {
                                                                    if (isEdit) {
                                                                        handleAddCategory("add category")(e);
                                                                    }
                                                                }}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                    alignItems: "center",
                                                                    cursor: isEdit ? "pointer" : "normal",
                                                                    maxWidth: "120px"
                                                                }}
                                                            >
                                                                <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                                                <Typography
                                                                    sx={{
                                                                        marginLeft: "5px",
                                                                        fontWeight: 500,
                                                                        color: (theme) => theme.palette.primary.main
                                                                    }}
                                                                >
                                                                    Add category
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            {formik.values.category.length === 0 && (
                                                                <Box sx={{p: 4, border: "1px solid #E0E2E7", borderStyle: "dashed"}}>
                                                                    <Typography sx={{color: (theme) => theme.palette.secondary[200]}}>
                                                                        Add a category to get started
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                            {!editCategory && (
                                                                <AddCategory
                                                                    handleIsEdited={handleIsEdited}
                                                                    selectedRow={selectedRow}
                                                                    handleSelectedRow={handleSelectedRow}
                                                                    updateCategory={(cat) => {
                                                                        formik.setFieldValue("category", [...formik.values.category, cat]);
                                                                        setTimeout(() => formik.setTouched({category: true}), 100);
                                                                    }}
                                                                />
                                                            )}
                                                            {editCategory && (
                                                                <EditCategory
                                                                    handleIsEdited={handleIsEdited}
                                                                    selectedRow={selectedRow}
                                                                    handleSelectedRow={handleSelectedRow}
                                                                    handleRefreshDataAfterAction={(response) => {
                                                                        const tempCat = JSON.parse(JSON.stringify(formik.values.category));
                                                                        tempCat[selectedIndex].label = response.categoryName;
                                                                        tempCat[selectedIndex].value = response.categoryName;

                                                                        tempCat[selectedIndex].description = response.description;

                                                                        formik.setFieldValue("category", tempCat);

                                                                        setSelectedIndex(null);
                                                                        setSelectedRow(null);
                                                                    }}
                                                                    currentCategory={formik.values.category}
                                                                    updateCategory={(cat) => {
                                                                        formik.setFieldValue("category", [...formik.values.category, cat]);
                                                                        setTimeout(() => formik.setTouched({category: true}), 100);
                                                                    }}
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={10} key={counter + formik.values.category.length}>
                                                            {formik.values.category.length > 0 ? (
                                                                <Dragger
                                                                    menuid={menuid || formik.values.newMenuID}
                                                                    isEdit={isEdit}
                                                                    counter={counter}
                                                                    serviceType={formik.values.serviceType}
                                                                    categoryList={formik.values.category}
                                                                    handleAddCategory={handleAddCategory}
                                                                    existingItemList={PC.existingItemList}
                                                                    handleCateGoryDuplication={
                                                                        (row, ind) => {
                                                                            formik.setFieldValue("category", [
                                                                                ...formik.values.category,
                                                                                row
                                                                            ]);
                                                                        }

                                                                        // handleCateGoryDuplication
                                                                    }
                                                                    handleCateGoryDelete={(row, ind) => {
                                                                        const tempDel = JSON.parse(JSON.stringify(formik.values.category));
                                                                        tempDel.splice(ind, 1);
                                                                        formik.setFieldValue("category", tempDel);
                                                                    }}
                                                                    handleCategoryItemDuplication={(row, catInd, itemInd) => {
                                                                        const tempDup = JSON.parse(JSON.stringify(formik.values.category));
                                                                        tempDup[catInd].item.push(row);
                                                                        formik.setFieldValue("category", tempDup);
                                                                    }}
                                                                    handleCategoryItemDelete={(catInd, itemInd) => {
                                                                        const tempDel = JSON.parse(JSON.stringify(formik.values.category));
                                                                        tempDel[catInd].item.splice(itemInd, 1);
                                                                        formik.setFieldValue("category", tempDel);
                                                                    }}
                                                                    itemDrawer={itemDrawer}
                                                                    setItemDrawer={setItemDrawer}
                                                                    handleRearrange={(list) => {
                                                                        formik.setFieldValue("category", list);
                                                                    }}
                                                                    handleAddItem={(item, index) => {
                                                                        const tempCategory = JSON.parse(
                                                                            JSON.stringify(formik.values.category)
                                                                        );
                                                                        let tempItem = tempCategory[index].item;
                                                                        item.item_dietary =
                                                                            item.dietary.map((row) => {
                                                                                return {dietary: row.dietary};
                                                                            }) ?? [];
                                                                        tempItem = [...tempItem, item];
                                                                        tempCategory[index].item = tempItem;
                                                                        formik.setFieldValue("category", tempCategory);
                                                                    }}
                                                                    handleExistingItem={(item, index) => {
                                                                        const tempCategory = JSON.parse(
                                                                            JSON.stringify(formik.values.category)
                                                                        );
                                                                        let tempItem = tempCategory[index].item;
                                                                        item.item_dietary =
                                                                            item.itemDietary.map((row) => {
                                                                                return {dietary: row.dietary};
                                                                            }) ?? [];
                                                                        tempItem = [...tempItem, item];
                                                                        tempCategory[index].item = tempItem;
                                                                        formik.setFieldValue("category", tempCategory);
                                                                    }}
                                                                    handleUpdateItem={(item, index, itemIndex) => {
                                                                        const tempCategory = JSON.parse(
                                                                            JSON.stringify(formik.values.category)
                                                                        );

                                                                        item.item_dietary =
                                                                            item.itemDietary?.map((row) => {
                                                                                return {dietary: row.dietary};
                                                                            }) ?? [];
                                                                        tempCategory[index].item[itemIndex] = item;
                                                                        formik.setFieldValue("category", tempCategory);
                                                                    }}
                                                                    getItemListUnassigned={PC.getItemListUnassigned}
                                                                    categorlyItemlist={PC.categorlyItemlist}
                                                                    setCategoryItemList={PC.setCategoryItemList}
                                                                />
                                                            ) : null}
                                                            <ErrorMessage
                                                                name="category"
                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={10} container spacing={2}>
                                                        <Box sx={{width: "100%", ml: 2, mt: 2}}>
                                                            <Divider />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Typography
                                                                variant="h3"
                                                                sx={{fontSize: 22, fontWeight: 700, marginBottom: 2}}
                                                                color="#000A12"
                                                            >
                                                                Schedule
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {formik.values.serviceType === "Catering" && (
                                                        <Grid item xs={12} container spacing={2}>
                                                            <Grid item xs={4}>
                                                                <Grid xs={12}>
                                                                    <CustomNewDateTimePicker
                                                                        // differentAnchor={anchorEl}
                                                                        dateTitle="Schedule date"
                                                                        timeTitle="Schedule time"
                                                                        disablePast
                                                                        clearButton
                                                                        handleClear={() => {
                                                                            formik.setFieldValue(`start_value_on`, null);
                                                                            formik.setFieldValue("start_value_after", "");
                                                                        }}
                                                                        // setDifferentAnchor={setAnchorEl}
                                                                        value={formik.values.start_value_on || null}
                                                                        placeholder="Start date"
                                                                        {...formik.getFieldProps("start_value_on")}
                                                                        onChange={(val) => {
                                                                            formik.setFieldValue(`start_value_on`, val);
                                                                            formik.setFieldValue("start_value_after", "");
                                                                        }}
                                                                    />
                                                                    <ErrorMessage
                                                                        name="start_value_on"
                                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                {/* <DropDown
                                                disabled={!isEdit}
                                                placeHolder="Locations"
                                                multiSelect
                                                withCheckBox
                                                options={locationList}
                                                selectedItem={formik.values.locations}
                                                handleChange={(val) => formik.setFieldValue("locations", [...val])}
                                                MenuPaperProps={{ sx: { width: 360, boxShadow: "0px 0px 3px rgba(0,0,0,0.2)" } }}
                                                FormControlProps={{
                                                    fullWidth: true
                                                }}
                                                optionsBoxProps={{ sx: { maxHeight: 1500, overflowY: "auto" } }}
                                            /> */}
                                                                <Grid xs={12}>
                                                                    <CustomNewDateTimePicker
                                                                        // differentAnchor={anchorEl}
                                                                        minValue={formik.values.start_value_on}
                                                                        disabled={formik.values.noEndDate}
                                                                        dateTitle="Schedule date"
                                                                        timeTitle="Schedule time"
                                                                        // setDifferentAnchor={setAnchorEl}
                                                                        clearButton
                                                                        handleClear={() => {
                                                                            formik.setFieldValue(`ends_value_on`, null);
                                                                            formik.setFieldValue("ends_value_after", "");
                                                                        }}
                                                                        value={formik.values.ends_value_on || null}
                                                                        placeholder="End date"
                                                                        {...formik.getFieldProps("ends_value_on")}
                                                                        onChange={(val) => {
                                                                            formik.setFieldValue(`ends_value_on`, val);
                                                                            formik.setFieldValue("ends_value_after", "");
                                                                        }}
                                                                    />
                                                                    <ErrorMessage
                                                                        name="ends_value_on"
                                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {formik.values.serviceType === "Catering" && (
                                                        <Grid item xs={12} container spacing={2} justifyContent="flex-end">
                                                            <Grid item xs={6}>
                                                                {isEdit && (
                                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                                        <CustomSwitch
                                                                            checked={formik.values.noEndDate === true}
                                                                            onChange={(e) => {
                                                                                formik.setFieldValue(`noEndDate`, !formik.values.noEndDate);
                                                                                if (formik.values.noEndDate === true) {
                                                                                    formik.setFieldValue(`ends_value_on`, null);
                                                                                    // formik.setTouched({ endDate: true });
                                                                                }
                                                                            }}
                                                                        />{" "}
                                                                        <Typography>No end date</Typography>
                                                                    </Stack>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {formik.values.serviceType === "Catering" && (
                                                        <Grid item xs={12} container spacing={2}>
                                                            <Grid item xs={4}>
                                                                <Typography
                                                                    sx={{
                                                                        color: "#000000",
                                                                        fontWeight: 700,
                                                                        fontSize: 13,
                                                                        mb: 2,
                                                                        ml: "-2px"
                                                                    }}
                                                                >
                                                                    Available on
                                                                </Typography>
                                                                <FieldArray
                                                                    name="Repeat On"
                                                                    render={() => (
                                                                        <>
                                                                            {PC.weekList.map((week, index) => (
                                                                                <Grid key={index} item xs={2}>
                                                                                    <FormControlLabel
                                                                                        disabled={!isEdit}
                                                                                        checked={formik.values.recurringValues.includes(
                                                                                            week.value
                                                                                        )}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                disableRipple
                                                                                                icon={
                                                                                                    <img
                                                                                                        src={Unchecked}
                                                                                                        style={{
                                                                                                            width: "18px",
                                                                                                            height: "18px"
                                                                                                        }}
                                                                                                        alt="checked"
                                                                                                    />
                                                                                                }
                                                                                                checkedIcon={
                                                                                                    <img
                                                                                                        src={Checked}
                                                                                                        alt="checkbox"
                                                                                                        style={{
                                                                                                            width: "18px",
                                                                                                            height: "18px"
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                        value={week.value}
                                                                                        label={
                                                                                            <Typography
                                                                                                variant={
                                                                                                    formik.values.recurringValues.includes(
                                                                                                        week.value
                                                                                                    )
                                                                                                        ? "checkBoxSelected"
                                                                                                        : "checkBoxUnSelected"
                                                                                                }
                                                                                            >
                                                                                                {week.label}
                                                                                            </Typography>
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const list = formik.values.recurringValues;
                                                                                            if (list.includes(e.target.value)) {
                                                                                                const targetIndex = list.indexOf(
                                                                                                    e.target.value
                                                                                                );
                                                                                                if (targetIndex > -1) {
                                                                                                    list.splice(targetIndex, 1);
                                                                                                }
                                                                                            } else {
                                                                                                list.push(e.target.value);
                                                                                            }
                                                                                            formik.setFieldValue(`recurringValues`, list);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    name="recurringValues"
                                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                />
                                                            </Grid>

                                                            {formik.values.frequency[0] === "Customs" && (
                                                                <Grid item xs={4}>
                                                                    <Typography sx={{color: "#000000", fontWeight: 700, fontSize: 13}}>
                                                                        Ends On
                                                                    </Typography>
                                                                    <Box sx={{display: "flex"}}>
                                                                        <Grid item xs={4}>
                                                                            <RadioGroup
                                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                                defaultValue="never"
                                                                                name="radio-buttons-group"
                                                                                onChange={(e, value) => formik.setFieldValue(`ends`, value)}
                                                                            >
                                                                                <FormControlLabel
                                                                                    disabled={!isEdit}
                                                                                    sx={{marginBottom: "10px"}}
                                                                                    checked={formik.values.ends === "never"}
                                                                                    value="never"
                                                                                    control={
                                                                                        <Radio
                                                                                            disableRipple
                                                                                            icon={
                                                                                                <img
                                                                                                    src={RadioIcon}
                                                                                                    alt="radioIcon"
                                                                                                    style={{width: "21px", height: "21px"}}
                                                                                                />
                                                                                            }
                                                                                            checkedIcon={
                                                                                                <img
                                                                                                    src={CheckedIcon}
                                                                                                    alt="checkedRadioIcon"
                                                                                                    style={{width: "21px", height: "21px"}}
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    label="Never"
                                                                                />
                                                                                {/* <FormControlLabel
                                                                                disabled={!isEdit}
                                                                                sx={{marginBottom: "10px"}}
                                                                                checked={formik.values.ends === "on"}
                                                                                value="on"
                                                                                control={
                                                                                    <Radio
                                                                                        icon={
                                                                                            <img
                                                                                                src={RadioIcon}
                                                                                                alt="radioIcon"
                                                                                                style={{width: "21px", height: "21px"}}
                                                                                            />
                                                                                        }
                                                                                        checkedIcon={
                                                                                            <img
                                                                                                src={CheckedIcon}
                                                                                                alt="checkedRadioIcon"
                                                                                                style={{width: "21px", height: "21px"}}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label="On"
                                                                            />
                                                                            <FormControlLabel
                                                                                disabled={!isEdit}
                                                                                value="after"
                                                                                checked={formik.values.ends === "after"}
                                                                                control={
                                                                                    <Radio
                                                                                        icon={
                                                                                            <img
                                                                                                src={RadioIcon}
                                                                                                alt="radioIcon"
                                                                                                style={{width: "21px", height: "21px"}}
                                                                                            />
                                                                                        }
                                                                                        checkedIcon={
                                                                                            <img
                                                                                                src={CheckedIcon}
                                                                                                alt="checkedRadioIcon"
                                                                                                style={{width: "21px", height: "21px"}}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label="After"
                                                                            /> */}
                                                                            </RadioGroup>
                                                                        </Grid>
                                                                        <Grid item xs={8}>
                                                                            <Grid item xs={12} sx={{height: 45}}>
                                                                                &nbsp;
                                                                            </Grid>
                                                                            <Grid item xs={12} sx={{marginBottom: "10px"}}>
                                                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DateTimePicker
                                                                            placeholder="mm/dd/yyyy hh:mm"
                                                                            label="On"
                                                                            disabled={formik.values.ends !== "on" || !isEdit}
                                                                            format="MM/dd/yyyy, hh:mm A"
                                                                            //  defaultValue={dayjs('2022-04-17T15:30')}
                                                                            value={
                                                                                formik.values.end_value_on
                                                                                    ? dayjs(formik.values.end_value_on)
                                                                                    : null
                                                                            }
                                                                            onChange={(val) => {
                                                                                formik.setFieldValue(`end_value_on`, val);
                                                                                formik.setFieldValue("end_value_after", "");
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <CustomTextField
                                                                                    placeholder="mm/dd/yyyy hh:mm"
                                                                                    sx={{width: "100%"}}
                                                                                    {...params}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider> */}
                                                                                {/* <CustomNewDateTimePicker
                                                                                disabled={formik.values.ends !== "on" || !isEdit}
                                                                                placeholder="mm/dd/yyyy hh:mm"
                                                                                value={
                                                                                    formik.values.end_value_on
                                                                                        ? dayjs(formik.values.end_value_on)
                                                                                        : null
                                                                                }
                                                                                onChange={(val) => {
                                                                                    formik.setFieldValue(`end_value_on`, val);
                                                                                    formik.setFieldValue("end_value_after", "");
                                                                                }}
                                                                            />
                                                                            <ErrorMessage
                                                                                name="end_value_on"
                                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                            /> */}
                                                                            </Grid>
                                                                            {/* <Grid item xs={12}>
                                                                            <CustomTextField
                                                                                fullWidth
                                                                                disabled={formik.values.ends !== "after" || !isEdit}
                                                                                type="number"
                                                                                placeholder="Occurances"
                                                                                label="Occurances"
                                                                                inputProps={{
                                                                                    maxLength: 250,
                                                                                    inputmode: "numeric",
                                                                                    pattern: "[0-9]*"
                                                                                }}
                                                                                {...formik.getFieldProps("end_value_after")}
                                                                                onKeyDown={(e) => {
                                                                                    if (
                                                                                        e.key === "e" ||
                                                                                        e.key === "E" ||
                                                                                        e.key === "-" ||
                                                                                        e.key === "+"
                                                                                    ) {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    const val = e.target.value;
                                                                                    formik.setFieldValue("end_value_after", val);
                                                                                    formik.setFieldValue(`end_value_on`, null);
                                                                                }}
                                                                            />
                                                                            <ErrorMessage
                                                                                name="end_value_after"
                                                                                render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                            />
                                                                        </Grid> */}
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    )}
                                                    {formik.values.serviceType === "Home Meals" && (
                                                        <Grid item xs={4}>
                                                            <DropDown
                                                                disabled={!isEdit}
                                                                placeHolder="All locations"
                                                                multiSelect
                                                                withCheckBox
                                                                options={locationList}
                                                                selectedItem={formik.values.locations}
                                                                handleChange={(val) => formik.setFieldValue("locations", [...val])}
                                                                MenuPaperProps={{
                                                                    sx: {boxShadow: "0px 0px 3px rgba(0,0,0,0.2)"}
                                                                }}
                                                                FormControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                optionsBoxProps={{sx: {maxHeight: 1500, overflowY: "auto"}}}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {formik.values.serviceType === "Home Meals" &&
                                                        formik.values.homeMealMenuType === "Regular" && (
                                                            <Grid item xs={12} container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <DynamicWeek formik={formik} />
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    {formik.values.serviceType === "Home Meals" &&
                                                        formik.values.homeMealMenuType === "Special" && (
                                                            <SpecialMenuSection formik={formik} />
                                                        )}
                                                </>
                                            )}
                                        </Grid>
                                    </Box>
                                </Stack>
                                {isEdit && (
                                    <Grid container>
                                        <Grid item xs={12} sx={{mt: 3}}>
                                            <ButtonGrouping
                                                btnprops={[
                                                    {
                                                        btnTitle: "Cancel",
                                                        sx: (t) => ({color: t.palette.error.dark}),
                                                        onClick: () => handleCancel(formik)
                                                    },
                                                    {
                                                        btnTitle: "Save",
                                                        variant: "contained",
                                                        // disabled: formik.isSubmitting,
                                                        sx: (t) => ({color: t.palette.background.paper}),
                                                        onClick: formik.handleSubmit
                                                    }
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                <MenuViewPage />
            )}
        </>
    );
};

export default React.memo(MenuForm);
